import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { differenceInCalendarDays, format } from "date-fns";
import dayjs from "dayjs";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormMultipleSelect from "../../../components/forms/form-multiple-select.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableSort from "../../../components/table/table-sort.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  eventCategorySelector,
  getEventCategoryList,
} from "../../../services/event/category/category.slice.services";
import {
  createEventSessionCategory,
  deleteEventSessionCategory,
  eventSessionCategorySelector,
  updateEventSessionCategory,
} from "../../../services/event/session/category/category.slice.services";
import {
  addEventSession,
  eventSessionSelector,
  getEventSessionList,
  stopSalesEventSession,
  toggleEventSessionStatus,
} from "../../../services/event/session/session.slice.services";
import BusinessListAutoComplete from "../components/business-list-autocomplete.component";
import EventListAutoComplete from "../components/event-list-autocomplete.component";
import SessionCategoryModal from "../components/session-category-modal.component";
import SessionCreateModal from "../components/session-create-modal.component";
import SessionDuplicateModal from "../components/session-duplicate-modal.component";
import SessionTableRow from "../components/session-table-row.component";
import SessionTableRowLoader from "../loader/session-table-row-loader.component";

const validationSchema = Yup.object().shape({
  businessIds: Yup.array().label("Businesses").nullable(),
  categoryIds: Yup.array().label("Categories").nullable(),
  eventIds: Yup.array().label("Events").nullable(),
  statuses: Yup.array().label("Status").of(Yup.string()).nullable(),
  startAt: Yup.date().label("Start date").nullable(),
  endAt: Yup.date()
    .min(Yup.ref("startAt"), "End date can't be before start date")
    .label("End date")
    .nullable()
    .when("startAt", {
      is: (startAt) => startAt,
      then: Yup.date()
        .min(Yup.ref("startAt"), "End date can't be before start date")
        .label("End date")
        .typeError("End date is required")
        .required(),
    }),
});

const validationSchemaAddSession = Yup.object().shape({
  eventId: Yup.object()
    .shape({ id: Yup.number().required(), option: Yup.string().required() })
    .nullable()
    .required()
    .label("Event"),
  title: Yup.string().nullable().label("Title"),
  startDate: Yup.date().required().label("Start date"),
  endDate: Yup.date().when("createType", {
    is: (createType) => createType !== "one-off",
    then: Yup.date()
      .min(Yup.ref("startDate"), "End date can't be before start date")
      .label("End date")
      .nullable()
      .required("End date is required"),
  }),
  startTime: Yup.string()
    .nullable()
    .required()
    .label("Start time")
    .test("time-range", "Start time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  endTime: Yup.string()
    .nullable()
    .required()
    .label("End time")
    .test("time-range", "End time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  maxCapacity: Yup.number().nullable().required().label("Capacity"),
  price: Yup.number().max(10000).nullable().label("Price"),
  membershipPrice: Yup.number().lessThan(Yup.ref("price")).nullable().label("Membership Price"),
  dates: Yup.array()
    .of(Yup.string().nullable())
    .nullable()
    .when("createType", {
      is: (createType) => createType === "custom",
      then: Yup.array().of(Yup.string().nullable()).min(1, "Must select at least 1 date"),
    })
    .label("Dates"),
});

const validationSchemaAddSessionCategory = Yup.object().shape({
  label: Yup.string().required().nullable().label("Label"),
  startTime: Yup.string()
    .nullable()
    .required()
    .label("Start time")
    .test("time-range", "Start time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  endTime: Yup.string()
    .nullable()
    .required()
    .label("End time")
    .test("time-range", "End time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  maxCapacity: Yup.number().nullable().required().label("Capacity"),
  price: Yup.number().max(10000).nullable().required().label("Price"),
  membershipPrice: Yup.number().lessThan(Yup.ref("price")).nullable().label("Membership Price"),
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const STATUSLIST = [
  { id: "1", label: "Publish", value: "publish" },
  { id: "2", label: "Cancelled", value: "cancelled" },
  { id: "3", label: "Draft", value: "draft" },
];

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
}));

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

export default function EventSessionScreen() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const createSnackBar = useContext(SnackbarContext);
  const [showAddSessionModal, setShowAddSessionModal] = useState(false);
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const { getEventSessionListObj, toggleEventSessionStatusObj, stopSalesEventSessionObj } =
    useSelector(eventSessionSelector);
  const { getEventCategoryListObj } = useSelector(eventCategorySelector);
  const { deleteEventSessionCategoryObj } = useSelector(eventSessionCategorySelector);
  const [selectedSessionId, setSelectedSessionId] = useState("");
  const [selectedSessionCategoryId, setSelectedSessionCategoryId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStopSaleModal, setShowStopSaleModal] = useState(false);
  const [showDeleteSessionCategoryModal, setShowDeleteSessionCategoryModal] = useState(false);
  const [status, setStatus] = useState(["publish", "draft"]);
  const [business, setBusiness] = useState(null);
  const [categories, setCategories] = useState(null);
  const [events, setEvents] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [sessionInfo, setSessionInfo] = useState(null);
  const [showDuplicateSessionModal, setShowDuplicateSessionModal] = useState(false);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);

  const columnMapping = {
    "Session Title": "title",
    Class: "event.title",
    "Start At": "startAt",
    "End At": "endAt",
    "Total People": "currentCapacity",
    Status: "status",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const getProcessedCategoryList = () => {
    const itemList = [];
    getEventCategoryListObj.data.map((item) =>
      itemList.push({
        label: item.label,
        id: item.id,
        value: item.id,
      }),
    );
    return itemList;
  };

  const onRefreshSessionList = (
    statuses,
    businessIds,
    categoryIds,
    eventIds,
    startAt,
    endAt,
    newPage,
  ) => {
    setStatus(statuses);
    setBusiness(businessIds);
    setCategories(categoryIds);
    setEvents(eventIds);
    setStartDate(startAt);
    setEndDate(endAt);
    setPage(newPage);
    dispatch(
      getEventSessionList({
        statuses,
        businessIds,
        categoryIds,
        eventIds,
        startAt,
        endAt,
        page: newPage,
      }),
    ).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    onRefreshSessionList(status, business, categories, events, startDate, endDate, newPage);
  };

  const submitFilter = (values) => {
    const businessIds = values.businessIds.map((item) => item.id);
    const eventIds = values.eventIds.map((item) => item.id);
    const startAt = values.startAt && moment(values.startAt).format("Y-MM-DD 00:00:00.000");
    const endAt = values.endAt && moment(values.endAt).format("Y-MM-DD 23:59:59.999");
    onRefreshSessionList(
      values.statuses,
      businessIds,
      values.categoryIds,
      eventIds,
      startAt,
      endAt,
      1,
    );
  };

  const clearFilter = () => {
    formRef.current.setFieldValue("statuses", status);
    formRef.current.setFieldValue("businessIds", []);
    formRef.current.setFieldValue("categoryIds", []);
    formRef.current.setFieldValue("eventIds", []);
    formRef.current.setFieldValue("startAt", null);
    formRef.current.setFieldValue("endAt", null);
  };

  const onAddSessions = (values, { resetForm }) => {
    let payloadParams = {};
    const amountCents = values.multipleCategories === "true" ? 0 : values.price * 100;
    let membershipAmountCents = values.membershipPrice ? values.membershipPrice * 100 : null;

    if (values.multipleCategories === "true") {
      membershipAmountCents = null;
    }

    const startTimeFormatted = dayjs(values.startTime).format("HH:mm:00");
    const endTimeFormatted = dayjs(values.endTime).format("HH:mm:00");
    const startDateFormatted = format(values.startDate, "yyyy-MM-dd");
    const endDateFormatted = format(values.endDate, "yyyy-MM-dd");

    if (values.createType === "one-off" || values.sessionId) {
      if (
        new Date(values.startDate) < new Date() &&
        new Date(values.startTime).getTime() < new Date().getTime() &&
        !values.sessionId
      ) {
        formRef.current.setFieldError(
          "startDate",
          "Start Date Time cannot earlier than current date time",
        );
        formRef.current.setFieldError(
          "startTime",
          "Start Date Time cannot earlier than current date time",
        );
        return;
      }
      if (new Date(values.startTime) > new Date(values.endTime)) {
        formRef.current.setFieldError(
          "endTime",
          "End date time cannot be earlier than start date time",
        );
        return;
      }
      payloadParams = {
        eventId: values.eventId.id,
        startDate: startDateFormatted,
        endDate: startDateFormatted,
        startTime: startTimeFormatted,
        endTime: endTimeFormatted,
        weeklyRepeat: null,
        occurenceType: "oneOff",
        occurenceNumber: null,
        amountCents,
        membershipAmountCents,
        maxCapacity: values.multipleCategories === "true" ? 0 : values.maxCapacity,
        status: values.multipleCategories === "true" ? "draft" : values.status,
        title: values.title,
        multipleCategories: values.multipleCategories === "true",
      };
    } else if (values.createType === "recurrent") {
      const startAt = `${format(values.startDate, "yyyy-MM-dd")} ${startTimeFormatted}`;

      if (format(new Date(), "yyyy-MM-dd") > startDateFormatted) {
        formRef.current.setFieldError("startDate", "Start date needs to be today or later");
        return;
      }

      if (endDateFormatted < startDateFormatted && values.endType === "endDate") {
        formRef.current.setFieldError(
          "endDate",
          "End date needs to be later or same day as start date",
        );
        return;
      }

      if (format(new Date(), "yyyy-MM-dd HH:mm:00") > startAt && values.endType === "endDate") {
        formRef.current.setFieldError("startTime", "Start time has passed");
        return;
      }

      if (startTimeFormatted > endTimeFormatted && values.endType === "endDate") {
        formRef.current.setFieldError("endTime", "End time cannot be before start time");
        return;
      }

      if (values.endType === "occurenceNumber" && values.occurenceNumber === null) {
        formRef.current.setFieldError("occurenceNumber", "Missing occurence number");
        return;
      }

      if (values.occurenceType === "weekly" && values.weeklyRepeat.length === 0) {
        formRef.current.setFieldError("weeklyRepeat", "Please select at least one");
      }

      if (values.occurenceType === "weekly" && values.weeklyRepeat.length > 0) {
        switch (values.weeklyRepeat.length) {
          case 1:
            if (values.occurenceNumber > 12) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 12");
              return;
            }
            break;
          case 2:
            if (values.occurenceNumber > 24) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 24");
              return;
            }
            break;
          case 3:
            if (values.occurenceNumber > 36) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 36");
              return;
            }
            break;
          case 4:
            if (values.occurenceNumber > 48) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 48");
              return;
            }
            break;
          case 5:
            if (values.occurenceNumber > 60) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 60");
              return;
            }
            break;
          case 6:
            if (values.occurenceNumber > 72) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 72");
              return;
            }
            break;
          case 7:
            if (values.occurenceNumber > 84) {
              formRef.current.setFieldError("occurenceNumber", "Maximum occurences allowed is 84");
              return;
            }
            break;
          default:
            break;
        }
      }

      if (
        values.occurenceType === "weekly" &&
        values.occurenceNumber === null &&
        differenceInCalendarDays(new Date(values.endDate), new Date(values.startDate)) < 7
      ) {
        formRef.current.setFieldError(
          "endDate",
          "Please select end date at least 7 days from start date",
        );
        return;
      }

      payloadParams = {
        eventId: values.eventId.id,
        startDate: startDateFormatted,
        startTime: startTimeFormatted,
        endTime: endTimeFormatted,
        endDate: values.endType === "endDate" ? endDateFormatted : null,
        weeklyRepeat: values.occurenceType === "weekly" ? values.weeklyRepeat : null,
        occurenceType: values.occurenceType,
        occurenceNumber:
          values.endType === "occurenceNumber" ? Number(values.occurenceNumber) : null,
        amountCents,
        membershipAmountCents,
        maxCapacity: values.multipleCategories === "true" ? 0 : values.maxCapacity,
        status: values.multipleCategories === "true" ? "draft" : values.status,
        title: values.title,
        multipleCategories: values.multipleCategories === "true",
      };
    } else if (values.createType === "custom") {
      const datesFormatted = values.dates.map((item) => format(item, "yyyy-MM-dd"));

      if (new Date(values.startTime) > new Date(values.endTime)) {
        formRef.current.setFieldError(
          "endTime",
          "End date time cannot be earlier than start date time",
        );
        return;
      }
      payloadParams = {
        eventId: values.eventId.id,
        occurenceType: "custom",
        dates: datesFormatted,
        startTime: startTimeFormatted,
        endTime: endTimeFormatted,
        amountCents,
        membershipAmountCents,
        maxCapacity: values.multipleCategories === "true" ? 0 : values.maxCapacity,
        status: values.multipleCategories === "true" ? "draft" : values.status,
        title: values.title,
        weeklyRepeat: null,
        occurenceNumber: null,
        multipleCategories: values.multipleCategories === "true",
      };
    }

    dispatch(addEventSession(payloadParams)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowAddSessionModal(false);
        setShowDuplicateSessionModal(false);
        setSessionInfo(null);

        resetForm();
        onRefreshSessionList(status, business, categories, events, startDate, endDate, 1);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onAddSessionCategory = (values, { resetForm }) => {
    let payloadParams = {};
    const amountCents = values.price * 100;
    const membershipAmountCents = values.membershipPrice ? values.membershipPrice * 100 : null;

    const startTimeFormatted = dayjs(values.startTime).format("HH:mm:00");
    const endTimeFormatted = dayjs(values.endTime).format("HH:mm:00");
    const startDateFormatted = sessionInfo?.startAt.split(" ")[0];
    const endDateFormatted = sessionInfo?.endAt.split(" ")[0];

    if (new Date(values.startTime) > new Date(values.endTime)) {
      formRef.current.setFieldError(
        "endTime",
        "End date time cannot be earlier than start date time",
      );
      return;
    }
    payloadParams = {
      sessionId: values.sessionId,
      label: values.label,
      startAt: `${startDateFormatted} ${startTimeFormatted}`,
      endAt: `${endDateFormatted} ${endTimeFormatted}`,
      amountCents,
      membershipAmountCents,
      maxCapacity: values.maxCapacity,
    };

    dispatch(createEventSessionCategory(payloadParams)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowCreateCategoryModal(false);
        setSessionInfo(null);
        resetForm();
        onRefreshSessionList(status, business, categories, events, startDate, endDate, 1);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onEditSessionCategory = (values) => {
    let payloadParams = {};
    const amountCents = values.price * 100;
    const membershipAmountCents = values.membershipPrice ? values.membershipPrice * 100 : null;

    const startTimeFormatted = dayjs(values.startTime).format("HH:mm:00");
    const endTimeFormatted = dayjs(values.endTime).format("HH:mm:00");
    const startDateFormatted = sessionInfo?.startAt.split(" ")[0];
    const endDateFormatted = sessionInfo?.endAt.split(" ")[0];

    if (new Date(values.startTime) > new Date(values.endTime)) {
      formRef.current.setFieldError(
        "endTime",
        "End date time cannot be earlier than start date time",
      );
      return;
    }
    payloadParams = {
      sessionCategoryId: values.sessionCategoryId,
      label: values.label,
      startAt: `${startDateFormatted} ${startTimeFormatted}`,
      endAt: `${endDateFormatted} ${endTimeFormatted}`,
      amountCents,
      membershipAmountCents,
      maxCapacity: values.maxCapacity,
    };

    dispatch(updateEventSessionCategory(payloadParams)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowEditCategoryModal(false);
        onRefreshSessionList(status, business, categories, events, startDate, endDate, 1);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onHandleCancel = (sessionId) => {
    setSelectedSessionId(sessionId);
    setShowDeleteModal(true);
  };

  const onHandleStopSale = (sessionId) => {
    setSelectedSessionId(sessionId);
    setShowStopSaleModal(true);
  };

  const onHandleDeleteCategory = (sessionCategoryId) => {
    setSelectedSessionCategoryId(sessionCategoryId);
    setShowDeleteSessionCategoryModal(true);
  };

  const onConfirmCancel = () => {
    setIsLoading(true);
    dispatch(
      toggleEventSessionStatus({
        sessionIds: [selectedSessionId],
        status: "cancelled",
      }),
    ).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        onRefreshSessionList(status, business, categories, events, startDate, endDate, page);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onConfirmStopSale = () => {
    setIsLoading(true);
    dispatch(stopSalesEventSession({ sessionId: selectedSessionId })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setShowStopSaleModal(false);
          onRefreshSessionList(status, business, categories, events, startDate, endDate, page);
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const onConfirmDeleteSessionCategory = () => {
    setIsLoading(true);
    dispatch(deleteEventSessionCategory({ sessionCategoryId: selectedSessionCategoryId })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setShowDeleteSessionCategoryModal(false);
          onRefreshSessionList(status, business, categories, events, startDate, endDate, page);
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const getProcessedRecord = () => {
    let records = null;

    if (getEventSessionListObj.data && getEventSessionListObj.status === "succeeded") {
      records = { ...getEventSessionListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = getColumnValue(a, sortColumn);
          const columnB = getColumnValue(b, sortColumn);

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (getEventSessionListObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (getEventSessionListObj.data?.items?.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={6}>
              <TableEmptyBox>
                <Text type="TableText">No items</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records.items.map((item) => (
        <SessionTableRow
          onPageChange={onPageChange}
          session={item}
          key={item.id}
          onHandleCancel={onHandleCancel}
          onHandleStopSale={onHandleStopSale}
          onHandleDeleteCategory={onHandleDeleteCategory}
          setSessionInfo={setSessionInfo}
          setShowDuplicateSessionModal={setShowDuplicateSessionModal}
          setShowCreateCategoryModal={setShowCreateCategoryModal}
          setShowEditCategoryModal={setShowEditCategoryModal}
        />
      ));
    }
    return <SessionTableRowLoader />;
  };

  useEffect(() => {
    dispatch(getEventCategoryList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    onRefreshSessionList(status, business, categories, events, startDate, endDate, 1);
  }, []);

  if (getEventCategoryListObj.status === "succeeded")
    return (
      <>
        <DeleteConfirmationModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          title="Cancel"
          label="Are you sure you wish to cancel this session?"
          isLoading={toggleEventSessionStatusObj.status === "pending"}
          onConfirmClicked={onConfirmCancel}
        />

        <DeleteConfirmationModal
          showModal={showStopSaleModal}
          setShowModal={setShowStopSaleModal}
          title="Stop Sales"
          label="Are you sure you wish to stop sales for this session?"
          isLoading={stopSalesEventSessionObj.status === "pending"}
          onConfirmClicked={onConfirmStopSale}
        />

        <DeleteConfirmationModal
          showModal={showDeleteSessionCategoryModal}
          setShowModal={setShowDeleteSessionCategoryModal}
          title="Delete"
          label="Are you sure you wish to delete this session category?"
          isLoading={deleteEventSessionCategoryObj.status === "pending"}
          onConfirmClicked={onConfirmDeleteSessionCategory}
        />
        <BackdropLoading isLoading={isLoading} />

        <Formik
          innerRef={formRef}
          initialValues={{
            statuses: status,
            businessIds: [],
            categoryIds: [],
            eventIds: [],
            startAt: null,
            endAt: null,
          }}
          onSubmit={submitFilter}
          validationSchema={validationSchema}
        >
          <Grid
            container
            spacing={3}
            sx={{
              paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
              paddingY: theme.dimensions.ScreenPaddingY,
            }}
          >
            <Grid item container sx={{ justifyContent: "space-between" }}>
              <Grid item xs={2}>
                <Text variant="screenLabel">Event Sessions</Text>
              </Grid>
              <Grid item xs={2}>
                <CtaButton onClickButton={() => setShowAddSessionModal(true)}>
                  <Text type="WhiteColor">Create</Text>
                </CtaButton>
              </Grid>
            </Grid>

            <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
              <WhiteBgCardContainer>
                <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={isSmallScreen ? 12 : 6}>
                          <FormContainer>
                            <Grid item xs={3}>
                              <Text>Events</Text>
                            </Grid>

                            <Grid item xs={9}>
                              <EventListAutoComplete name="eventIds" placeholder="Events" />
                            </Grid>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={isSmallScreen ? 12 : 6}>
                          <FormContainer>
                            <Grid item xs={3}>
                              <Text>Businesses</Text>
                            </Grid>

                            <Grid item xs={9}>
                              <BusinessListAutoComplete
                                name="businessIds"
                                placeholder="Businesses"
                              />
                            </Grid>
                          </FormContainer>
                        </Grid>
                        <Grid item xs={isSmallScreen ? 12 : 6}>
                          <FormContainer>
                            <Grid item xs={3}>
                              <Text>Categories</Text>
                            </Grid>
                            <Grid item xs={9}>
                              <FormMultipleSelect
                                itemList={getProcessedCategoryList()}
                                name="categoryIds"
                                placeholder="Categories"
                                isWhiteBg
                              />
                            </Grid>
                          </FormContainer>
                        </Grid>
                        <Grid item xs={isSmallScreen ? 12 : 6}>
                          <FormContainer>
                            <Grid item xs={3}>
                              <Text>Status</Text>
                            </Grid>
                            <Grid item xs={9}>
                              <FormMultipleSelect
                                itemList={STATUSLIST}
                                name="statuses"
                                placeholder="Status"
                                isWhiteBg
                              />
                            </Grid>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={isSmallScreen ? 12 : 6}>
                          <FormContainer>
                            <Grid item xs={3}>
                              <Text>Start date</Text>
                            </Grid>
                            <Grid item xs={9}>
                              <FormDatePicker
                                name="startAt"
                                width="100%"
                                isWhiteBg
                                maxDays={null}
                                minDays={false}
                                showClear={true}
                              />
                            </Grid>
                          </FormContainer>
                        </Grid>
                        <Grid item xs={isSmallScreen ? 12 : 6}>
                          <FormContainer>
                            <Grid item xs={3}>
                              <Text>End date</Text>
                            </Grid>

                            <Grid item xs={9}>
                              <FormDatePicker
                                name="endAt"
                                width="100%"
                                isWhiteBg
                                maxDays={null}
                                minDays={false}
                                showClear={true}
                              />
                            </Grid>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                        <Grid item xs={12} sm={2}>
                          <CtaButton whiteBg onClickButton={clearFilter}>
                            <Text type="BrandColor">Clear All</Text>
                          </CtaButton>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <FormSubmitButton>
                            <Text type="WhiteColor">Search</Text>
                          </FormSubmitButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </SearchContainer>

                <Grid container sx={{ paddingY: "20px" }} spacing={3}>
                  <Grid item xs={12}>
                    <TableWrapper>
                      <Table>
                        <TableHeader
                          headerCells={[
                            "Session Title",
                            "Class",
                            "Start At",
                            "End At",
                            "Total People",
                            "Status",
                            "Action",
                          ]}
                          sortColumn={sortColumn}
                          sortOrder={sortOrder}
                          onSortChange={onSortChange}
                          columnMapping={columnMapping}
                        />
                        <TableBody>{renderTableBody()}</TableBody>
                      </Table>
                    </TableWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomFooter>
                      <Pagination
                        sx={{
                          "&& .Mui-selected": {
                            backgroundColor: theme.palette.colors.brand.primary,
                            color: theme.palette.colors.text.white,
                          },
                        }}
                        page={page}
                        shape="rounded"
                        onChange={onPageChange}
                        count={getEventSessionListObj?.data?.pagination.totalPages || 1}
                        variant="outlined"
                      />
                    </CustomFooter>
                  </Grid>
                </Grid>
              </WhiteBgCardContainer>
            </Grid>
          </Grid>
        </Formik>
        <Formik
          innerRef={formRef}
          initialValues={{
            eventId: null,
            occurenceType: "daily",
            createType: "one-off",
            endType: "endDate",
            startDate: new Date(),
            endDate: new Date(),
            startTime: dayjs(),
            endTime: dayjs(),
            maxCapacity: 0,
            price: null,
            membershipPrice: null,
            occurenceNumber: null,
            weeklyRepeat: [],
            status: "draft",
            title: "",
            dates: [],
            multipleCategories: "false",
          }}
          validationSchema={validationSchemaAddSession}
          onSubmit={onAddSessions}
        >
          <SessionCreateModal
            showModal={showAddSessionModal}
            setShowModal={setShowAddSessionModal}
          />
        </Formik>

        {sessionInfo && (
          <Formik
            innerRef={formRef}
            initialValues={{
              eventId: {
                id: sessionInfo?.event?.id,
                option: sessionInfo?.event?.title,
              },
              occurenceType: "daily",
              createType: "one-off",
              endType: "endDate",
              startDate: new Date(),
              endDate: new Date(),
              startTime: dayjs(sessionInfo.startAt),
              endTime: dayjs(sessionInfo.endAt),
              maxCapacity: sessionInfo.multipleCategories === "true" ? 0 : sessionInfo.maxCapacity,
              price: parseFloat(sessionInfo.price.replace(",", "")),
              membershipPrice:
                sessionInfo.membershipPrice !== null
                  ? parseFloat(sessionInfo.membershipPrice.replaceAll(",", ""))
                  : null,
              occurenceNumber: null,
              weeklyRepeat: [],
              status: sessionInfo.multipleCategories === "true" ? "draft" : sessionInfo.status,
              multipleCategories: sessionInfo.multipleCategories ? "true" : "false",
            }}
            validationSchema={validationSchemaAddSession}
            onSubmit={onAddSessions}
          >
            <SessionDuplicateModal
              showModal={showDuplicateSessionModal}
              setShowModal={setShowDuplicateSessionModal}
              setSessionInfo={setSessionInfo}
            />
          </Formik>
        )}

        {sessionInfo && (
          <Formik
            innerRef={formRef}
            initialValues={{
              sessionId: sessionInfo.id,
              startTime: dayjs(sessionInfo.startAt),
              endTime: dayjs(sessionInfo.endAt),
              maxCapacity: 0,
              price: null,
              membershipPrice: null,
              label: "",
            }}
            validationSchema={validationSchemaAddSessionCategory}
            onSubmit={onAddSessionCategory}
          >
            <SessionCategoryModal
              showModal={showCreateCategoryModal}
              setShowModal={setShowCreateCategoryModal}
              sessionInfo={sessionInfo}
              setSessionInfo={setSessionInfo}
            />
          </Formik>
        )}

        {sessionInfo && (
          <Formik
            innerRef={formRef}
            initialValues={{
              sessionCategoryId: sessionInfo.id,
              startTime: dayjs(sessionInfo.startAt),
              endTime: dayjs(sessionInfo.endAt),
              maxCapacity: sessionInfo.maxCapacity,
              price: sessionInfo.price,
              membershipPrice: sessionInfo.membershipPrice,
              label: sessionInfo.label,
            }}
            validationSchema={validationSchemaAddSessionCategory}
            onSubmit={onEditSessionCategory}
          >
            <SessionCategoryModal
              showModal={showEditCategoryModal}
              setShowModal={setShowEditCategoryModal}
              sessionInfo={sessionInfo}
              setSessionInfo={setSessionInfo}
            />
          </Formik>
        )}
      </>
    );
}
