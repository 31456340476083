import {
  Box,
  Grid,
  Modal,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import * as Yup from "yup";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import TableHeader from "../../../../components/table/table-header.component";
import TableLoader from "../../../../components/table/table-loader.component";
import TableWrapper from "../../../../components/table/table-wrapper.component";
import {
  adjustTransaction,
  getStaffStatementTransactionList,
  staffStatementSelector,
} from "../../../../services/staff/statement/statement-slice.service";
import TransactionRow from "./transaction-row.component";
import TableSort from "../../../../components/table/table-sort.component";
import Form from "../../../../components/forms/form.component";
import Spacer from "../../../../components/spacer.component";
import Text from "../../../../components/text.component";
import FormPrice from "../../../../components/forms/form-price.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
}));

const adjustmentValidationSchema = Yup.object().shape({
  adjustmentAmount: Yup.mixed().nullable().label("Adjustment Amount"),
});

export default function TransactionTable() {
  const location = useLocation();
  const statementId = new URLSearchParams(location.search).get("statementId");
  const dispatch = useDispatch();
  const { getStaffStatementTransactionListObj, adjustTransactionObj } =
    useSelector(staffStatementSelector);
  const createSnackBar = useContext(SnackbarContext);
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const [showAdjustModal, setShowAdjustModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const columnMapping = {
    "Transaction Id": "id",
    "Date Created": "createdAt",
    Amount: "amount",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  useEffect(() => {
    dispatch(getStaffStatementTransactionList({ statementId, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const onPageChange = (e, newPage) => {
    dispatch(getStaffStatementTransactionList({ statementId, page: newPage })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
    setPage(newPage);
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Check if the original value starts with a "-"
      if (value.startsWith("-")) {
        // Remove non-digit characters and parse the string as a negative number
        return -parseFloat(value.replace(/\D/g, "")) || value;
      }

      // Remove non-digit characters and parse the string as a number
      return parseFloat(value.replace(/\D/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = [];

    if (
      getStaffStatementTransactionListObj.data &&
      getStaffStatementTransactionListObj.status === "succeeded"
    ) {
      records = { ...getStaffStatementTransactionListObj.data };

      if (adjustTransactionObj.data && adjustTransactionObj.status === "succeeded") {
        const adjustedIndex = records.items.findIndex((i) => i.id === adjustTransactionObj.data.id);
        const newItems = [...records.items];
        newItems[adjustedIndex] = adjustTransactionObj.data;

        records.items = newItems;
      }

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderContent = () => {
    if (getStaffStatementTransactionListObj.status === "succeeded") {
      const records = getProcessedRecord();

      return (
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Transactions List</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Table>
            <TableHeader
              headerCells={[
                "Transaction Id",
                "Description / Commission",
                "Date Created",
                "Amount",
                "Action",
              ]}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSortChange={onSortChange}
              columnMapping={columnMapping}
              nonSortableColumns={["Description / Commission"]}
            />
            <TableBody>
              {records.items.map((item) => (
                <TransactionRow
                  record={item}
                  key={item.id}
                  setShowAdjustModal={setShowAdjustModal}
                  setSelectedTransaction={setSelectedTransaction}
                />
              ))}
            </TableBody>
          </Table>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              onChange={onPageChange}
              count={getStaffStatementTransactionListObj.data.pagination.totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </TableWrapper>
      );
    }
    return <TableLoader />;
  };

  const onAdjustTransaction = (values) => {
    const apiPayload = { ...values };
    apiPayload.adjustmentAmountCents = parseFloat(values.adjustmentAmount) * 100;
    dispatch(adjustTransaction(apiPayload)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        // onPageChange(null, page);
        renderContent();
        setShowAdjustModal(false);
        setSelectedTransaction(null);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <>
      {selectedTransaction && (
        <Form
          onSubmit={onAdjustTransaction}
          validationSchema={adjustmentValidationSchema}
          initialValues={{
            statementId,
            transactionId: selectedTransaction.id,
            adjustmentAmount: parseFloat(selectedTransaction.adjustmentAmount.replaceAll(",", "")),
            adjustmentRemark: selectedTransaction.adjustmentRemark,
          }}
        >
          <Modal
            open={showAdjustModal}
            onClose={() => {
              setShowAdjustModal(false);
              setSelectedTransaction(null);
            }}
          >
            <ModalBox sx={{ padding: "20px", width: "90%", maxWidth: "400px" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Adjustment
              </Typography>
              <Spacer size="m" />
              <Grid container columnSpacing={3} rowSpacing={1}>
                <Grid item xs={12}>
                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                    Adjustment Amount (RM)
                  </Text>
                  <Spacer />
                  <FormPrice placeholder="00.00" name="adjustmentAmount" />
                  <Spacer size="m" />
                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Adjustment Remark</Text>
                  <Spacer />
                  <FormFieldText placeholder="remark" name="adjustmentRemark" />
                  <Spacer size="m" />
                  <Grid item sx={{ textAlign: "center" }}>
                    <FormSubmitButton
                      width="200px"
                      isLoading={adjustTransactionObj.status === "pending"}
                    >
                      <Text type="WhiteColor">Submit</Text>
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </ModalBox>
          </Modal>{" "}
        </Form>
      )}

      {renderContent()}
    </>
  );
}
