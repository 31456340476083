import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CtaBorderButton from "../../../../components/button/cta-border-button.component";
import FormFieldDebounceText from "../../../../components/forms/form-field-debounce-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/padded-view.component";
import { getStaffs, staffSelector } from "../../../../services/staff/staff-slice.service";
import StaffAutoComplete from "./staff-autocomplete.component";
import FormDatePicker from "../../../../components/forms/form-date-picker.component";

function FilterOptions() {
  const theme = useTheme();
  const { resetForm, handleSubmit } = useFormikContext();
  const { getStaffsObj } = useSelector(staffSelector);
  const [staffKeyword, setStaffKeyword] = useState("");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);

  const onChangeSearchStaff = (value) => {
    setStaffKeyword(value);
    dispatch(getStaffs({ q: value, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const handleClearForm = () => {
    resetForm();
    handleSubmit();
  };

  useEffect(() => {
    dispatch(getStaffs({ q: staffKeyword, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  return (
    <>
      <PaddedView>
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.colors.bg.sessionCardBg,
            borderRadius: `${theme.shape.borderRadius[1]}px`,
          }}
        >
          <Grid item xs={6}>
            <PaddedView>
              <FormFieldDebounceText name="statementId" placeholder="Statement ID" label="Search" />
            </PaddedView>
          </Grid>
          <Grid item xs={6}>
            <PaddedView>
              <StaffAutoComplete
                placeholder="Search Staff"
                name="staffs"
                keyword={staffKeyword}
                setKeyword={onChangeSearchStaff}
                optionList={getStaffsObj.data?.items || []}
              />{" "}
            </PaddedView>
          </Grid>

          <Grid item xs={6}>
            <PaddedView>
              <FormDatePicker
                name="paidStartDate"
                disablePast={false}
                width="100%"
                placeholder="Paid Start Date"
              />
            </PaddedView>
          </Grid>
          <Grid item xs={6}>
            <PaddedView>
              <FormDatePicker
                name="paidEndDate"
                disablePast={false}
                width="100%"
                placeholder="Paid End Date"
              />
            </PaddedView>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <FormSubmitButton type="submit">
                    <Typography>Search</Typography>
                  </FormSubmitButton>
                </PaddedView>
              </Grid>
              <Grid item xs={12} sm={3}>
                <PaddedView>
                  <CtaBorderButton onClickButton={handleClearForm}>
                    <Typography sx={{ color: theme.palette.colors.brand.primary }}>
                      Clear
                    </Typography>
                  </CtaBorderButton>
                </PaddedView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
      <></>
    </>
  );
}

export default FilterOptions;
