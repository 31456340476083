import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useHistory } from "react-router-dom";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export default function HourlyTimeslotTableRow({ timeslot, onHandleDelete }) {
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(null);
  const history = useHistory();

  const handleOpenMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const onToggleDelete = () => {
    onHandleDelete(timeslot.id);
    handleCloseMenu();
  };

  const onHandleEdit = () => {
    history.push({
      pathname: routes.HOURLY_TIMESLOT_EDIT,
      search: `timeslotId=${timeslot.id}`,
    });
    handleCloseMenu();
  };

  return (
    <TableRow
      key={timeslot.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          {timeslot.startTime}
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          {timeslot.endTime}
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          {timeslot.capacity}
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          {`RM ${timeslot.price}`}
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          {timeslot.membershipPrice ? `RM ${timeslot.membershipPrice}` : "-"}
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
          {`RM ${timeslot.exclusivePrice}`}
        </Text>
      </TableCell>
      <TableCell sx={{ width: "200px" }}>
        {timeslot.unlimited ? (
          <Text variant="body2" type="GreenColor" sx={{ textAlign: "center" }}>
            Unlimited
          </Text>
        ) : (
          <Text variant="body2" type="YellowColor" sx={{ textAlign: "center" }}>
            Limited
          </Text>
        )}
      </TableCell>

      <TableCell sx={{ width: "100px" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton onClick={handleOpenMenu}>
            <MoreHorizIcon sx={{ color: theme.palette.colors.text.table }} />
          </IconButton>
          <StyledMenu
            sx={{ mt: "20px" }}
            id="menu-appbar"
            anchorEl={showMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(showMenu)}
            onClose={handleCloseMenu}
          >
            <MenuItem key={1} onClick={onHandleEdit}>
              <Text textAlign="center">Edit</Text>
            </MenuItem>

            <MenuItem key={2} onClick={onToggleDelete}>
              <Text textAlign="center">Remove</Text>
            </MenuItem>
          </StyledMenu>
        </Box>
      </TableCell>
    </TableRow>
  );
}

HourlyTimeslotTableRow.propTypes = {
  onHandleDelete: PropTypes.func.isRequired,
  timeslot: PropTypes.shape({
    id: PropTypes.number,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    capacity: PropTypes.number,
    price: PropTypes.string,
    membershipPrice: PropTypes.string,
    exclusivePrice: PropTypes.string,
    unlimited: PropTypes.bool,
  }).isRequired,
};
