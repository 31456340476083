import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CtaButton from "../../components/button/cta-button.component";
import Spacer from "../../components/spacer.component";
import CircleTick from "../../images/circle_tick";
import routes from "../../navigation/routes";

const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "50px",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  textAlign: "center",
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  textAlign: "center",
}));

export default function BillPaymentSuccessful() {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const remoteBillId = new URLSearchParams(location.search).get("remoteBillId");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderMessage = () => {
    switch (location.pathname) {
      case routes.TOPUPBILLPAYMENTSUCCESS:
        return "Your top-up is successful.";
      default:
        return false;
    }
  };

  return (
    <StyledContainer>
      <Spacer size="xxl" />
      <CircleTick />
      <Spacer size="xl" />
      <StyledTitle variant={isMobile ? "h4" : "h3"}>Payment Successful!</StyledTitle>
      <Spacer size="m" />
      <GreyText sx={{ maxWidth: "70%" }}>{renderMessage()}</GreyText>
      <Spacer size="l" />
      {remoteBillId && <GreyText>{`REF ID: ${remoteBillId}`}</GreyText>}
      <Spacer size="xl" />
      <Grid container justifyContent="center" columnSpacing={2}>
        <Grid item>
          <CtaButton onClickButton={() => history.push(routes.HOME)} width="220px">
            Back to Home
          </CtaButton>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}
