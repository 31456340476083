/* eslint-disable no-useless-escape */
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../../components/container/white-bg-card-container.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormImageUpload from "../../../../components/forms/form-image-upload.component";
import FormPrice from "../../../../components/forms/form-price.component";
import FormSelect from "../../../../components/forms/form-select.component";
import BackdropLoading from "../../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import Text from "../../../../components/text.component";
import routes from "../../../../navigation/routes";
import {
  getMembershipPlan,
  membershipPlanSelector,
  updateMembershipPlan,
  updateMembershipPlanImage,
  updateMembershipPlanStatus,
} from "../../../../services/membership/plan/membership-plan-slice.service";
import BusinessListAutoCompleteSingleSelect from "../../../home/components/business-list-autocomplete-single-select.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

export default function MembershipPlanEditScreen() {
  const theme = useTheme();
  const location = useLocation();
  const selectedPlanId = location.state.id;
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const { getMembershipPlanObj } = useSelector(membershipPlanSelector);
  const [allowEditPlan, setAllowEditPlan] = useState(false);
  const [allowEditStatus, setAllowEditStatus] = useState(false);
  const [allowEditImage, setAllowEditImage] = useState(false);

  const validationSchema = Yup.object().shape({
    identifier: Yup.string()
      .required()
      .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allowed")
      .label("Identifier"),
    business: Yup.mixed().required().label("Business"),
    priceRM: Yup.number().max(10000).required().label("Price"),
  });

  const validationSchemaStatus = Yup.object().shape({
    status: Yup.string().required().label("Status"),
  });

  const validationSchemaImage = Yup.object().shape({
    image: Yup.string().required().label("Image"),
  });

  useEffect(() => {
    setIsLoading(true);
    dispatch(getMembershipPlan({ planId: selectedPlanId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setFilePreview(payload.data.imagePath);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const onUpdateMembershipPlan = (values) => {
    const priceCents = values.priceRM * 100;
    setIsLoading(true);
    dispatch(
      updateMembershipPlan({
        ...values,
        planId: selectedPlanId,
        priceCents,
        businessId: values.business.id,
      }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      setIsLoading(false);
    });
    setAllowEditPlan(false);
  };

  const onUpdateMembershipPlanStatus = (values) => {
    setIsLoading(true);
    dispatch(
      updateMembershipPlanStatus({ planId: selectedPlanId, status: values.status.toLowerCase() }),
    ).then(({ meta, payload, error }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
      setIsLoading(false);
    });
    setAllowEditStatus(false);
  };

  const onUpdateMembershipPlanImage = (values) => {
    setIsLoading(true);
    dispatch(updateMembershipPlanImage({ planId: selectedPlanId, ...values })).then(
      ({ meta, payload, error }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
        setIsLoading(false);
      },
    );
    setAllowEditImage(false);
  };

  return (
    <Grid>
      <BackdropLoading isLoading={isLoading} />
      {getMembershipPlanObj.status === "succeeded" && (
        <>
          <Formik
            validationSchema={validationSchemaImage}
            onSubmit={onUpdateMembershipPlanImage}
            initialValues={{
              image: getMembershipPlanObj.data.imagePath,
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                    paddingTop: theme.dimensions.ScreenPaddingY,
                  }}
                >
                  <Grid item xs={12}>
                    <SpaceBetweenBox>
                      <Text variant="screenLabel">Edit Membership Plan</Text>
                      <Button
                        onClick={() => history.push(routes.MEMBERSHIP_PLAN_LIST)}
                        sx={{
                          textTransform: "none",
                          padding: "0px",
                          ":hover": { backgroundColor: "transparent" },
                        }}
                      >
                        <Text>Back</Text>
                      </Button>
                    </SpaceBetweenBox>
                  </Grid>
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Image</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditImage ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditImage(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditImage(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12} rowSpacing={1}>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Image</Text>
                          <FormImageUpload
                            name="image"
                            disabled={!allowEditImage}
                            filePreview={filePreview}
                            setFilePreview={setFilePreview}
                          />
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <Formik
            validationSchema={validationSchemaStatus}
            onSubmit={onUpdateMembershipPlanStatus}
            initialValues={{
              status:
                getMembershipPlanObj.data.status.charAt(0).toUpperCase() +
                getMembershipPlanObj.data.status.slice(1),
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Status</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditStatus ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditStatus(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditStatus(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid item container xs={12}>
                          <Grid item xs={12} md={2}>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Status</Text>
                          </Grid>
                          <Grid item xs={12} md={10}>
                            <FormSelect
                              itemList={[
                                { id: 1, label: "Active" },
                                { id: 2, label: "Inactive" },
                              ]}
                              name="status"
                              disabled={!allowEditStatus}
                              placeholder="Select status"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

          <Formik
            validationSchema={validationSchema}
            onSubmit={onUpdateMembershipPlan}
            initialValues={{
              business: getMembershipPlanObj.data.business,
              identifier: getMembershipPlanObj.data.identifier,
              priceRM: Number(getMembershipPlanObj.data.price.replace(/[^0-9\.]+/g, "")).toFixed(2),
              renewalDays: getMembershipPlanObj.data.renewalDays,
            }}
          >
            {({ handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                  }}
                >
                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={2}>
                          <Text variant="screenLabel">Plan</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditPlan ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditPlan(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditPlan(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={12} md={2}>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Business</Text>
                          </Grid>
                          <Grid item xs={12} md={10}>
                            <BusinessListAutoCompleteSingleSelect
                              name="business"
                              placeholder="Business"
                              disabled={!allowEditPlan}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={12} md={2}>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Membership Plan Identifier
                            </Text>
                          </Grid>
                          <Grid item xs={12} md={10}>
                            <FormFieldText
                              name="identifier"
                              placeholder="Membership Plan Identifier"
                              disabled={!allowEditPlan}
                            />
                          </Grid>
                        </Grid>

                        <Grid item container xs={12}>
                          <Grid item xs={12} md={2}>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                          </Grid>
                          <Grid item xs={12} md={10}>
                            <FormPrice
                              placeholder="00.00"
                              name="priceRM"
                              disabled={!allowEditPlan}
                            />
                          </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                          <Grid item xs={12} md={2}>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Renewal Days
                            </Text>
                          </Grid>
                          <Grid item xs={12} md={10}>
                            <FormFieldText
                              name="renewalDays"
                              label="Renewal Days"
                              type="number"
                              disabled={!allowEditPlan}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Grid>
  );
}
