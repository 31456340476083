import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

export default function UserHourlyBookingDetailTableRow({ booking }) {
  return (
    <>
      <TableRow
        key={booking.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell>
          <Text
            variant="body2"
            type="TableText"
            noWrap={true}
            sx={{ textTransform: "capitalize", textAlign: "center" }}
          >
            {booking.type.replaceAll("_", " ")}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {booking.location.name}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {booking.date}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {booking.time}
          </Text>
        </TableCell>
        <TableCell>
          <Text variant="body2" type="TableText" noWrap={true} sx={{ textAlign: "center" }}>
            {booking.promoCode === null || booking.promoCode === "" ? "-" : booking.promoCode}
          </Text>
        </TableCell>
        <TableCell>
          <Text
            variant="body2"
            type="TableText"
            noWrap={true}
            sx={{ textTransform: "capitalize", textAlign: "center" }}
          >
            {booking.status}
          </Text>
        </TableCell>
      </TableRow>
    </>
  );
}

UserHourlyBookingDetailTableRow.propTypes = {
  booking: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    date: PropTypes.string,
    status: PropTypes.string,
    time: PropTypes.string,
    promoCode: PropTypes.string,
    location: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
    }),
    session: PropTypes.shape({
      trainer: PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        imagePath: PropTypes.string,
      }),

      class: PropTypes.shape({
        name: PropTypes.string,
        category: PropTypes.shape({
          label: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};
