import { useState } from "react";

function TableSort(columnMapping) {
  const [sortConfig, setSortConfig] = useState({ column: null, order: "asc" });

  const onSortChange = (column) => {
    const dataKey = columnMapping[column];
    if (dataKey) {
      setSortConfig((prevSortConfig) => {
        if (prevSortConfig.column === dataKey) {
          return { ...prevSortConfig, order: prevSortConfig.order === "asc" ? "desc" : "asc" };
        }
        return { column: dataKey, order: "asc" };
      });
    }
  };

  const parseValue = (value) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/,/g, "")) || value;
    }
    return value;
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const getProcessedRecord = (data = null) => {
    let records = null;

    if (data !== null) {
      records = { ...data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortConfig.column) {
          const columnA = parseValue(getColumnValue(a, sortConfig.column));
          const columnB = parseValue(getColumnValue(b, sortConfig.column));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortConfig.order === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortConfig.order === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortConfig.order === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  return {
    sortColumn: sortConfig.column,
    sortOrder: sortConfig.order,
    onSortChange,
    getProcessedRecord,
  };
}

export default TableSort;
