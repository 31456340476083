import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq, putReq } from "../../api-services";

export const addEventSession = createAsyncThunk("merchants/events/session/add", async (payload) => {
  const {
    eventId,
    startDate,
    startTime,
    endTime,
    endDate,
    weeklyRepeat,
    occurenceType,
    occurenceNumber,
    maxCapacity,
    status,
    amountCents,
    membershipAmountCents,
    title,
    dates,
    multipleCategories,
  } = payload;
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/sessions`,
    {
      eventId,
      startDate,
      startTime,
      endTime,
      endDate,
      weeklyRepeat,
      occurenceType,
      occurenceNumber,
      maxCapacity,
      status,
      amountCents,
      membershipAmountCents,
      title,
      dates,
      multipleCategories,
    },
  );
  return response;
});

export const getEventSessionList = createAsyncThunk(
  "merchants/events/session/list",
  async (payload) => {
    const { statuses, businessIds, categoryIds, eventIds, startAt, endAt, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/sessions?${
        page ? "page=".concat(page) : "page=1"
      }${businessIds ? "&businessIds=".concat(businessIds) : ""}${
        categoryIds ? "&categoryIds=".concat(categoryIds) : ""
      }${eventIds ? "&eventIds=".concat(eventIds) : ""}${
        startAt ? "&startAt=".concat(startAt) : ""
      }${endAt ? "&endAt=".concat(endAt) : ""}${statuses ? "&statuses=".concat(statuses) : ""}`,
    );
    return response;
  },
);

export const getEventSession = createAsyncThunk(
  "merchants/events/session/get/{session_id}",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/sessions/${payload.sessionId}`,
    );
    return response;
  },
);

export const getEventSessionAttendanceList = createAsyncThunk(
  "merchants/events/session/get/{session_id}/csv_bookings",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/sessions/${payload.sessionId}/csv_bookings`,
    );
    return response;
  },
);

export const toggleEventSessionStatus = createAsyncThunk(
  "merchants/events/session/status",
  async (payload) => {
    const { status, sessionIds } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/sessions/status`,
      { status, sessionIds },
    );
    return response;
  },
);

export const updateEventSession = createAsyncThunk(
  "merchants/events/session/update",
  async (payload) => {
    const {
      sessionId,
      startTime,
      startDate,
      endTime,
      endDate,
      amountCents,
      membershipAmountCents,
      maxCapacity,
      title,
    } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/sessions/${sessionId}`,
      {
        startTime,
        startDate,
        endTime,
        endDate,
        amountCents,
        membershipAmountCents,
        maxCapacity,
        title,
      },
    );
    return response;
  },
);

export const stopSalesEventSession = createAsyncThunk(
  "merchants/events/session/stop_sales",
  async (payload) => {
    const { sessionId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/sessions/${sessionId}/stop_sales`,
    );
    return response;
  },
);

const eventSessionSlice = createSlice({
  name: "eventSession",
  initialState: {
    addEventSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEventSessionListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEventSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEventSessionAttendanceListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    toggleEventSessionStatusObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateEventSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    stopSalesEventSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [addEventSession.pending]: (state) => {
      state.addEventSessionObj.status = "pending";
    },
    [addEventSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.addEventSessionObj.status = "succeeded";
      state.addEventSessionObj.data = data;
      state.addEventSessionObj.successMessage = message;
    },
    [addEventSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.addEventSessionObj.status = "failed";
      state.addEventSessionObj.errorMessage = message;
    },
    [getEventSessionList.pending]: (state) => {
      state.getEventSessionListObj.status = "pending";
    },
    [getEventSessionList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventSessionListObj.status = "succeeded";
      state.getEventSessionListObj.data = data;
      state.getEventSessionListObj.successMessage = message;
    },
    [getEventSessionList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventSessionListObj.status = "failed";
      state.getEventSessionListObj.errorMessage = message;
    },
    [getEventSession.pending]: (state) => {
      state.getEventSessionObj.status = "pending";
    },
    [getEventSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventSessionObj.status = "succeeded";
      state.getEventSessionObj.data = data;
      state.getEventSessionObj.successMessage = message;
    },
    [getEventSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventSessionObj.status = "failed";
      state.getEventSessionObj.errorMessage = message;
    },
    [getEventSessionAttendanceList.pending]: (state) => {
      state.getEventSessionAttendanceListObj.status = "pending";
    },
    [getEventSessionAttendanceList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventSessionAttendanceListObj.status = "succeeded";
      state.getEventSessionAttendanceListObj.data = data;
      state.getEventSessionAttendanceListObj.successMessage = message;
    },
    [getEventSessionAttendanceList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventSessionAttendanceListObj.status = "failed";
      state.getEventSessionAttendanceListObj.errorMessage = message;
    },
    [toggleEventSessionStatus.pending]: (state) => {
      state.toggleEventSessionStatusObj.status = "pending";
    },
    [toggleEventSessionStatus.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.toggleEventSessionStatusObj.status = "succeeded";
      state.toggleEventSessionStatusObj.data = data;
      state.toggleEventSessionStatusObj.successMessage = message;
    },
    [toggleEventSessionStatus.rejected]: (state, action) => {
      const { message } = action.error;

      state.toggleEventSessionStatusObj.status = "failed";
      state.toggleEventSessionStatusObj.errorMessage = message;
    },
    [updateEventSession.pending]: (state) => {
      state.updateEventSessionObj.status = "pending";
    },
    [updateEventSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateEventSessionObj.status = "succeeded";
      state.updateEventSessionObj.data = data;
      state.updateEventSessionObj.successMessage = message;
    },
    [updateEventSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateEventSessionObj.status = "failed";
      state.updateEventSessionObj.errorMessage = message;
    },
    [stopSalesEventSession.pending]: (state) => {
      state.stopSalesEventSessionObj.status = "pending";
    },
    [stopSalesEventSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.stopSalesEventSessionObj.status = "succeeded";
      state.stopSalesEventSessionObj.data = data;
      state.stopSalesEventSessionObj.successMessage = message;
    },
    [stopSalesEventSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.stopSalesEventSessionObj.status = "failed";
      state.stopSalesEventSessionObj.errorMessage = message;
    },
  },
});

export default eventSessionSlice.reducer;

// state
export const eventSessionSelector = (state) => state.eventSession;
