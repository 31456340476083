import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getStaffs = createAsyncThunk("merchants/staffs/index", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs?q=${payload.q}&page=${payload.page}${
      payload.statuses ? "&statuses=".concat(payload.statuses) : ""
    }`,
  );
  return response;
});

export const getStaff = createAsyncThunk("merchants/staff/{staff_id}", async (payload) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/${payload.staffId}`,
  );
  return response;
});

export const createStaff = createAsyncThunk("merchants/staffs/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs`,
    payload,
  );
  return response;
});

export const deleteStaff = createAsyncThunk("merchants/staffs/{staff_id}", async (payload) => {
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/${payload.staffId}`,
  );
  return response;
});

export const updateStaff = createAsyncThunk(
  "merchants/staffs/{staff_id}/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/${payload.staffId}`,
      payload,
    );
    return response;
  },
);

export const uploadStaffImage = createAsyncThunk(
  "merchants/staffs/{staff_id}/upload_photo",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/${payload.staffId}/upload_photo`,
      payload,
    );
    return response;
  },
);

const staffSlice = createSlice({
  name: "staff",
  initialState: {
    getStaffsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getStaffObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createStaffObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteStaffObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateStaffObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    uploadStaffImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getStaffs.pending]: (state) => {
      state.getStaffsObj.status = "pending";
    },
    [getStaffs.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStaffsObj.status = "succeeded";
      state.getStaffsObj.data = data;
      state.getStaffsObj.successMessage = message;
    },
    [getStaffs.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStaffsObj.status = "failed";
      state.getStaffsObj.errorMessage = message;
    },
    [getStaff.pending]: (state) => {
      state.getStaffObj.status = "pending";
    },
    [getStaff.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStaffObj.status = "succeeded";
      state.getStaffObj.data = data;
      state.getStaffObj.successMessage = message;
    },
    [getStaff.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStaffObj.status = "failed";
      state.getStaffObj.errorMessage = message;
    },
    [createStaff.pending]: (state) => {
      state.createStaffObj.status = "pending";
    },
    [createStaff.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createStaffObj.status = "succeeded";
      state.createStaffObj.data = data;
      state.createStaffObj.successMessage = message;
    },
    [createStaff.rejected]: (state, action) => {
      const { message } = action.error;

      state.createStaffObj.status = "failed";
      state.createStaffObj.errorMessage = message;
    },
    [deleteStaff.pending]: (state) => {
      state.deleteStaffObj.status = "pending";
    },
    [deleteStaff.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteStaffObj.status = "succeeded";
      state.deleteStaffObj.data = data;
      state.deleteStaffObj.successMessage = message;
    },
    [deleteStaff.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteStaffObj.status = "failed";
      state.deleteStaffObj.errorMessage = message;
    },
    [updateStaff.pending]: (state) => {
      state.updateStaffObj.status = "pending";
    },
    [updateStaff.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateStaffObj.status = "succeeded";
      state.updateStaffObj.data = data;
      state.updateStaffObj.successMessage = message;
    },
    [updateStaff.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateStaffObj.status = "failed";
      state.updateStaffObj.errorMessage = message;
    },
    [uploadStaffImage.pending]: (state) => {
      state.uploadStaffImageObj.status = "pending";
    },
    [uploadStaffImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadStaffImageObj.status = "succeeded";
      state.uploadStaffImageObj.data = data;
      state.getStaffObj.data = data;
      state.uploadStaffImageObj.successMessage = message;
    },
    [uploadStaffImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadStaffImageObj.status = "failed";
      state.uploadStaffImageObj.errorMessage = message;
    },
  },
});

export default staffSlice.reducer;

// state
export const staffSelector = (state) => state.staff;
