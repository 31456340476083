import React from "react";
import { Autocomplete, Box, TextField, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { DebounceInput } from "react-debounce-input";

function StaffAutoComplete({ optionList, name, placeholder, keyword, setKeyword, disabled }) {
  const theme = useTheme();
  const { setFieldValue, values, touched, errors, setFieldTouched } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <Autocomplete
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: theme.palette.colors.bg.primary,
            padding: "0px",
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
        },
        flex: 1,
      }}
      options={optionList}
      getOptionLabel={(option) => option.name}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterSelectedOptions
      value={values[name]}
      onChange={(event, value) => {
        setFieldValue(name, value);
      }}
      renderOption={(props, option) => (
        <Box {...props} key={option.id}>
          {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <DebounceInput
          {...params}
          error={showError}
          helperText={showError && errors[name]}
          onBlur={() => setFieldTouched(name)}
          debounceTimeout={500}
          onChange={(e) => setKeyword(e.target.value)}
          element={TextField}
          value={keyword}
          placeholder={placeholder}
        />
      )}
    />
  );
}

StaffAutoComplete.defaultProps = {
  disabled: false,
};

StaffAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  optionList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  keyword: PropTypes.string.isRequired,
  setKeyword: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default StaffAutoComplete;
