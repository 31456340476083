import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import { setAxiosToken } from "../../../services/api-services";
import { getStaffProfileDetail } from "../../../services/staff/profile/profile.slice.services";
import routes from "../../../navigation/routes";

function StaffShadowLoginScreen() {
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const searchParams = new URLSearchParams(window.location.search);
  const authToken = searchParams.get("authToken");
  const isStaff = searchParams.get("isStaff");
  const history = useHistory();

  useEffect(() => {
    if (authToken) {
      setAxiosToken(authToken);
      localStorage.setItem("engageMerchantAuthToken", authToken);
      if (isStaff) {
        dispatch(getStaffProfileDetail()).then(({ meta, payload, error }) => {
          if (meta.requestStatus === "fulfilled") {
            localStorage.setItem("userDetail", JSON.stringify(payload.data));
            localStorage.setItem("loginType", "staff");
            history.push(routes.HOME);
          }

          if (meta.requestStatus === "rejected") {
            createSnackBar({
              message: error.message,
              type: "error",
            });
          }
        });
      }
    }
  }, []);

  return <Box>Checking...</Box>;
}

export default StaffShadowLoginScreen;
