import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CtaButton from "../../../../components/button/cta-button.component";
import WhiteBgCardContainer from "../../../../components/container/white-bg-card-container.component";
import BackdropLoading from "../../../../components/notification/backdrop-loading.component";
import DeleteConfirmationModal from "../../../../components/notification/delete-confirmation-modal.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import SearchBar from "../../../../components/table/search-bar.component";
import TableHeader from "../../../../components/table/table-header.component";
import TableWrapper from "../../../../components/table/table-wrapper.component";
import Text from "../../../../components/text.component";
import routes from "../../../../navigation/routes";
import {
  deleteMembershipPlan,
  getMembershipPlanList,
  membershipPlanSelector,
} from "../../../../services/membership/plan/membership-plan-slice.service";
import MembershipPlanTableRow from "../components/membership-plan-table-row.component";
import MembershipPlanTableRowLoader from "../loader/membership-plan-table-row-loader.component";
import TableSort from "../../../../components/table/table-sort.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
  padding: "20px",
}));

export default function MembershipPlanListScreen() {
  const theme = useTheme();
  const history = useHistory();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [page, setPage] = useState(1);
  const { getMembershipPlanListObj, deleteMembershipPlanObj } = useSelector(membershipPlanSelector);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const columnMapping = {
    Identifier: "identifier",
    Business: "business.name",
    Price: "price",
    Status: "status",
  };
  const { sortColumn, sortOrder, onSortChange, getProcessedRecord } = TableSort(columnMapping);

  const onRefreshMembershipPlanList = (newPage, keyword) => {
    setPage(newPage);
    setQ(keyword);
    dispatch(getMembershipPlanList({ q: keyword, page: newPage })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setTotalPages(payload.data.pagination.totalPages);
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const onChangeSearch = (keyword) => {
    onRefreshMembershipPlanList(1, keyword);
  };

  const onPageChange = (e, newPage) => {
    onRefreshMembershipPlanList(newPage, q);
  };

  const onHandleDelete = (planId) => {
    setSelectedPlanId(planId);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteMembershipPlan({ planId: selectedPlanId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        onRefreshMembershipPlanList(page, q);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    onRefreshMembershipPlanList(page, q);
  }, []);

  const renderTableBody = () => {
    if (getMembershipPlanListObj.status === "succeeded") {
      const records = getProcessedRecord(
        getMembershipPlanListObj.status === "succeeded" ? getMembershipPlanListObj.data : null,
      );
      if (getMembershipPlanListObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={7}>
              <TableEmptyBox>
                <Text type="TableText">No Membership Plans</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records.items.map((item) => (
        <MembershipPlanTableRow
          membershipPlan={item}
          key={item.id}
          onHandleDelete={onHandleDelete}
        />
      ));
    }
    return <MembershipPlanTableRowLoader />;
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Remove Membership Plan"
        label="Are you sure you wish to remove this membership?"
        isLoading={deleteMembershipPlanObj.status === "pending"}
        onConfirmClicked={onConfirmDelete}
      />
      <Grid item xs={12}>
        <Text variant="screenLabel">Membership Plans</Text>
      </Grid>
      <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
        <WhiteBgCardContainer>
          <BackdropLoading isLoading={isLoading} />

          <SearchContainer>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <SearchBar
                    searchKeyword={q}
                    setSearchKeyword={onChangeSearch}
                    placeholder="Search here"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CtaButton onClickButton={() => history.push(routes.MEMBERSHIP_PLAN_CREATE)}>
                    <Text type="WhiteColor">Create Membership Plan</Text>
                  </CtaButton>
                </Grid>
              </Grid>
            </Grid>
          </SearchContainer>

          <Grid container sx={{ paddingY: "20px" }} spacing={3}>
            <Grid item xs={12}>
              <TableWrapper>
                <Table>
                  <TableHeader
                    headerCells={["Identifier", "Business", "Price", "Image", "Status", "Action"]}
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={onSortChange}
                    columnMapping={columnMapping}
                    nonSortableColumns={["Image"]}
                  />
                  <TableBody>{renderTableBody()}</TableBody>
                </Table>
              </TableWrapper>
            </Grid>
            <Grid item xs={12}>
              <CustomFooter>
                <Pagination
                  sx={{
                    "&& .Mui-selected": {
                      backgroundColor: theme.palette.colors.brand.primary,
                      color: theme.palette.colors.text.white,
                    },
                  }}
                  page={page}
                  shape="rounded"
                  onChange={onPageChange}
                  count={getMembershipPlanListObj?.data?.pagination.totalPages || totalPages}
                  variant="outlined"
                />
              </CustomFooter>
            </Grid>
          </Grid>
        </WhiteBgCardContainer>
      </Grid>
    </Grid>
  );
}
