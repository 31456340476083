import {
  Box,
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormPrice from "../../../components/forms/form-price.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  createFitnessBooking,
  createFitnessBookingPackage,
} from "../../../services/fitness/booking/booking.slice.service";
import { getStaffs, staffSelector } from "../../../services/staff/staff-slice.service";
import ClassListAutoCompleteSingleSelect from "../components/class-list-autocomplete-single-select.component";
import PackageListUserAvailabilitySingleSelect from "../components/package-list-user-availability-single-select.component";
import StaffAutoComplete from "../components/staff-autocomplete.component";
import UserListCreatePhoneNumberSingleSelect from "../components/user-list-create-phone-number-single-select.component";
import UserListCreatePhoneNumber from "../components/user-list-create-phone-number.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const validationSchema = Yup.object().shape({
  classId: Yup.object().label("Class").nullable().required(),
  staffObj: Yup.object()
    .shape({ id: Yup.number().required(), name: Yup.string().required() })
    .label("Staff"),
  userObj: Yup.array().min(1).nullable().required().label("User Phone Number"),
  startDate: Yup.date().required().label("Start date"),
  endDate: Yup.date()
    .label("End Date")
    .min(Yup.ref("startDate"), "End date can't be earlier than start date")
    .required("End date is required")
    .nullable(),
  startTime: Yup.string()
    .nullable()
    .required()
    .label("Start time")
    .test("time-range", "Start time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  endTime: Yup.string()
    .nullable()
    .required()
    .label("End time")
    .test("time-range", "End time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  price: Yup.number().min(0).max(10000).typeError("Price is required").label("Price"),
  membershipPrice: Yup.number().lessThan(Yup.ref("price")).nullable().label("Membership Price"),
});

const validationSchemaPackage = Yup.object().shape({
  classId: Yup.object().label("Class").nullable().required(),
  packageId: Yup.number().label("Package").nullable().required(),
  staffObj: Yup.object()
    .shape({ id: Yup.number().required(), name: Yup.string().required() })
    .label("Staff"),
  userPhoneNumber: Yup.object().nullable().required().label("User Phone Number"),
  startDate: Yup.date().required().label("Start date"),
  endDate: Yup.date()
    .label("End Date")
    .min(Yup.ref("startDate"), "End date can't be earlier than start date")
    .required("End date is required")
    .nullable(),
  startTime: Yup.string()
    .nullable()
    .required()
    .label("Start time")
    .test("time-range", "Start time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
  endTime: Yup.string()
    .nullable()
    .required()
    .label("End time")
    .test("time-range", "End time should be between 4am and 11:30pm", (value) => {
      if (value) {
        const selectedTimeFormatted = moment
          .utc(value, "ddd, DD MMM YYYY HH:mm:ss [GMT]")
          .utcOffset(8 * 60);
        const selectedTimeWithoutDate = moment(
          selectedTimeFormatted.format("HH:mm:ss"),
          "HH:mm:ss",
        );
        const minTime = moment().set({ hour: 4, minute: 0, second: 0, millisecond: 0 });
        const maxTime = moment().set({ hour: 23, minute: 31, second: 0, millisecond: 0 });
        return selectedTimeWithoutDate.isBetween(minTime, maxTime, null, "[]");
      }
      return true;
    }),
});

export default function BookingCreateScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [staffKeyword, setStaffKeyword] = useState("");
  const [createType, setCreateType] = useState("standard");
  const { getStaffsObj } = useSelector(staffSelector);

  const onChangeSearchStaff = (value) => {
    setStaffKeyword(value);
    dispatch(getStaffs({ q: value, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onCreateBooking = (values, { resetForm }) => {
    const userPhoneNumbers = [];
    const membershipAmountCents = values.membershipPrice ? values.membershipPrice * 100 : null;

    values.userObj.map((item) => userPhoneNumbers.push(item.phone));
    const startAt = `${format(values.startDate, "yyyy-MM-dd")} ${dayjs(values.startTime).format(
      "HH:mm:00",
    )}`;
    const endAt = `${format(values.endDate, "yyyy-MM-dd")} ${dayjs(values.endTime).format(
      "HH:mm:00",
    )}`;
    const payloadParams = {
      classId: values.classId.id,
      userPhoneNumbers,
      startAt,
      endAt,
      amountCents: values.price * 100,
      membershipAmountCents,
      staffId: values.staffObj.id,
    };

    const payloadPackageParams = {
      classId: values.classId.id,
      userPhoneNumber: values.userPhoneNumber?.phone,
      startAt,
      endAt,
      staffId: values.staffObj.id,
      packageId: values.packageId,
    };

    setIsLoading(true);

    if (createType === "standard")
      dispatch(createFitnessBooking(payloadParams)).then(({ meta, payload, error }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          resetForm();
          createSnackBar({
            message: payload.message,
            type: "success",
          });
          history.push(routes.FITNESS_CLASS_BOOKING);
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      });

    if (createType === "package")
      dispatch(createFitnessBookingPackage(payloadPackageParams)).then(
        ({ meta, payload, error }) => {
          setIsLoading(false);
          if (meta.requestStatus === "fulfilled") {
            resetForm();
            createSnackBar({
              message: payload.message,
              type: "success",
            });
            history.push(routes.FITNESS_CLASS_BOOKING);
          }
          if (meta.requestStatus === "rejected") {
            createSnackBar({
              message: error.message,
              type: "error",
            });
          }
        },
      );
  };

  useEffect(() => {
    dispatch(getStaffs({ q: staffKeyword, page: 1 })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
        paddingY: theme.dimensions.ScreenPaddingY,
      }}
    >
      <BackdropLoading isLoading={isLoading} />
      <Formik
        validationSchema={createType === "standard" ? validationSchema : validationSchemaPackage}
        onSubmit={onCreateBooking}
        initialValues={{
          classId: null,
          userObj: [],
          userPhoneNumber: null,
          startDate: new Date(),
          endDate: new Date(),
          startTime: dayjs(),
          endTime: dayjs().add(1, "hour"),
          price: null,
          membershipPrice: null,
          staffObj: null,
          packageId: null,
        }}
      >
        {({ values }) => (
          <>
            <Grid item xs={12}>
              <SpaceBetweenBox>
                <Text variant="screenLabel">Create Class Booking</Text>
                <Button
                  onClick={() => history.goBack()}
                  sx={{
                    textTransform: "none",
                    padding: "0px",
                    ":hover": { backgroundColor: "transparent" },
                  }}
                >
                  <Text>Back</Text>
                </Button>
              </SpaceBetweenBox>
            </Grid>
            <Grid item xs={12}>
              <WhiteBgCardContainer>
                <Grid item xs={isMobile ? 12 : 4}>
                  <ToggleButtonGroup
                    value={createType}
                    exclusive
                    onChange={(e) => {
                      setCreateType(e.target.value);
                    }}
                  >
                    <ToggleButton value="standard" sx={{ whiteSpace: "nowrap" }}>
                      Standard
                    </ToggleButton>
                    <ToggleButton value="package" sx={{ whiteSpace: "nowrap" }}>
                      Package
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Spacer />
                </Grid>
                <Grid container spacing={isMobile ? 1 : 3}>
                  <Grid item xs={12}>
                    <Text variant="screenLabel">Booking Details</Text>
                  </Grid>
                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Classes</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Classes</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <ClassListAutoCompleteSingleSelect
                            name="classId"
                            placeholder="Add class"
                          />
                          {values?.classId?.requireVerification && (
                            <Text
                              type="YellowColor"
                              sx={{ fontSize: "0.75rem", margin: "3px 14px 0px" }}
                            >
                              Note that this class requires user verification
                            </Text>
                          )}
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Staff</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Staff</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <StaffAutoComplete
                            placeholder="Type to add staff"
                            name="staffObj"
                            keyword={staffKeyword}
                            setKeyword={onChangeSearchStaff}
                            optionList={getStaffsObj.data?.items || []}
                          />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={12}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>User</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>User</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          {createType === "standard" && (
                            <UserListCreatePhoneNumber
                              name="userObj"
                              placeholder="Search user by phone number (e.g. 60123456789)"
                            />
                          )}
                          {createType === "package" && (
                            <UserListCreatePhoneNumberSingleSelect
                              name="userPhoneNumber"
                              placeholder="Search user by phone number (e.g. 60123456789)"
                            />
                          )}
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>

                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Date</Text>
                      </LabelContainer>
                    )}

                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Date</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormDatePicker name="startDate" width="100%" setEndDateAsSame />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Time</Text>
                      </LabelContainer>
                    )}

                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Time</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormTimePicker name="startTime" width="100%" setEndTimeAnHourLater />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Date</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Date</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormDatePicker name="endDate" width="100%" />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 6}>
                    {isMobile && (
                      <LabelContainer>
                        <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Time</Text>
                      </LabelContainer>
                    )}
                    <FormContainer>
                      {!isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Time</Text>
                        </LabelContainer>
                      )}
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <Box sx={{ width: "100%" }}>
                          <FormTimePicker name="endTime" width="100%" />
                        </Box>
                      </Box>
                    </FormContainer>
                  </Grid>
                  {createType === "standard" && (
                    <>
                      <Grid item xs={12}>
                        {isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                          </LabelContainer>
                        )}
                        <FormContainer>
                          {!isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                            </LabelContainer>
                          )}
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Box sx={{ width: "100%" }}>
                              <FormPrice placeholder="00.00" name="price" />
                            </Box>
                          </Box>
                        </FormContainer>
                      </Grid>
                      <Grid item xs={12}>
                        {isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                              Membership Price
                            </Text>
                          </LabelContainer>
                        )}
                        <FormContainer>
                          {!isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Membership Price
                              </Text>
                            </LabelContainer>
                          )}
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Box sx={{ width: "100%" }}>
                              <FormPrice placeholder="00.00" name="membershipPrice" />
                            </Box>
                          </Box>
                        </FormContainer>
                      </Grid>
                    </>
                  )}

                  {createType === "package" && (
                    <Grid item xs={12}>
                      {isMobile && (
                        <LabelContainer>
                          <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Package</Text>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isMobile && (
                          <LabelContainer>
                            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Package</Text>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <PackageListUserAvailabilitySingleSelect
                              placeholder="Select Class & Search user first"
                              name="packageId"
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                  )}
                </Grid>
              </WhiteBgCardContainer>
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
              <FormSubmitButton width="200px">
                <Text type="WhiteColor">Create</Text>
              </FormSubmitButton>
            </Grid>
          </>
        )}
      </Formik>
    </Grid>
  );
}
