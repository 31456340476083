import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Grid, IconButton, Pagination, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import Form from "../../../components/forms/form.component";
import SkeletonLoader from "../../../components/notification/skeleton-loader.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/padded-view.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import { profileSelector } from "../../../services/profile/profile.slice.services";
import {
  getStatementDetail,
  sendStatementEmail,
  statementSelector,
} from "../../../services/statement/statement-slice.service";
import DisputeLogModal from "../components/dispute-log-modal.component";
import EmailFieldModal from "../components/email-field-modal.component";
import BusinessListAutoCompleteSingleSelect from "../../home/components/business-list-autocomplete-single-select.component";

const StyledBackButton = styled(IconButton)(({ theme }) => ({
  padding: "0px",
  strokeWidth: 1,
  stroke: theme.palette.colors.text.primary,
  color: theme.palette.colors.text.primary,
  ":hover": {
    backgroundColor: "transparent",
  },
}));

const CenterRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const CardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "20px",
  boxShadow: `0px 1px 5px 1px ${theme.palette.colors.ui.disabled}`,
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
}));

const SpaceBetweenRowBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
});

const SeperateLine = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "0.5px",
  backgroundColor: theme.palette.colors.ui.border,
}));

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const emailValidation = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

function StatementDetailScreen() {
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const statementId = new URLSearchParams(location.search).get("statementId");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const { getStatementDetailObj } = useSelector(statementSelector);
  const { getProfileDetailObj } = useSelector(profileSelector);
  const [page, setPage] = useState(1);
  const [showEmailFieldModal, setShowEmailFieldModal] = useState(false);
  const [showDisputeLogModal, setShowDisputeLogModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getStatementDetail({ statementId, page })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
    dispatch(getStatementDetail({ statementId, page: value })).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const handleBusinessChange = (values) => {
    setPage(1);
    window.scrollTo(0, 0);
    dispatch(getStatementDetail({ statementId, businessId: values.business?.id, page: 1 })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  const renderStatus = (status) => {
    if (status === "dispute") {
      return (
        <Text variant="h6" type="RedColor">
          {status.toUpperCase()}
        </Text>
      );
    }
    if (status === "open") {
      return (
        <Text variant="h6" type="GreenColor">
          {status.toUpperCase()}
        </Text>
      );
    }
    if (status === "pending") {
      return (
        <Text variant="h6" type="BlueColor">
          {status.toUpperCase()}
        </Text>
      );
    }
    return (
      <Text variant="h6" type="BrandColor">
        {status.toUpperCase()}
      </Text>
    );
  };

  const renderStatementCard = () => {
    if (getStatementDetailObj.status === "succeeded") {
      const { statement } = getStatementDetailObj.data;
      return (
        <CardContainer>
          <SpaceBetweenRowBox>
            <Box>
              <Text type="GreyColor">{`Ref ID: ${statement.id}`}</Text>
              {renderStatus(statement.status)}
            </Box>

            <Text variant="h6">{`RM ${statement.totalAmount}`}</Text>
          </SpaceBetweenRowBox>
          {statement.status === "dispute" && (
            <>
              <Spacer position="top" size="m" />
              <Text>Reason :</Text>
              <Text>{statement.rejectedReason}</Text>
            </>
          )}
          {statement.businessStatements.length > 0 &&
            statement.businessStatements.map((item, index) => (
              <div key={item.id}>
                {index === 0 && <Spacer position="top" size="m" />}
                <SpaceBetweenRowBox>
                  <Text>{item.businessName}</Text>

                  <Text>{`RM ${item.totalAmount}`}</Text>
                </SpaceBetweenRowBox>
              </div>
            ))}
        </CardContainer>
      );
    }
    return (
      <CardContainer>
        <SpaceBetweenRowBox>
          <SkeletonLoader width="100px" height="30px" />
          <SkeletonLoader width="100px" height="30px" />
        </SpaceBetweenRowBox>
      </CardContainer>
    );
  };

  const renderStatementList = () => {
    if (getStatementDetailObj.status === "succeeded") {
      if (
        !getStatementDetailObj.data.items.transactions ||
        (getStatementDetailObj.data.items.transactions &&
          getStatementDetailObj.data.items.transactions.length === 0)
      ) {
        return (
          <Grid item xs={12}>
            <Text sx={{ textAlign: "center" }}>No Transactions.</Text>
          </Grid>
        );
      }
      return (
        <>
          {getStatementDetailObj.data.items.transactions &&
            getStatementDetailObj.data.items.transactions.map((item) => (
              <Grid item xs={12} key={item.id}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Box>
                      <SpaceBetweenRowBox>
                        <Text>{item.description}</Text>
                        <Spacer size="s" position="left" />
                        <Text
                          sx={{
                            color:
                              item.category === "platform_fee" || item.amount.includes("-")
                                ? theme.palette.colors.ui.redDot
                                : theme.palette.colors.brand.primary,
                          }}
                        >
                          {item.category === "platform_fee" || item.amount.includes("-")
                            ? "-"
                            : "+"}
                          RM
                          {item.amount.replace("-", "")}
                        </Text>
                      </SpaceBetweenRowBox>
                      <Text type="GreyColor">{item.dateTime}</Text>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <SeperateLine />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          {getStatementDetailObj.data.pagination.totalPages > 0 && (
            <Grid item xs={12}>
              <CustomFooter>
                <Pagination
                  sx={{
                    "&& .Mui-selected": {
                      backgroundColor: theme.palette.colors.brand.primary,
                      color: theme.palette.colors.text.white,
                    },
                  }}
                  page={page}
                  onChange={handlePageChange}
                  count={getStatementDetailObj.data.pagination.totalPages}
                  variant="outlined"
                />
              </CustomFooter>
            </Grid>
          )}
        </>
      );
    }
    return (
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Box>
              <SpaceBetweenRowBox>
                <SkeletonLoader width="100px" height="30px" />
                <Spacer size="s" position="left" />
                <SkeletonLoader width="100px" height="30px" />
              </SpaceBetweenRowBox>
              <SkeletonLoader width="100px" height="30px" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <SeperateLine />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleSendStatementEmail = (value) => {
    dispatch(sendStatementEmail({ ...value, statementId, businessId: value.business?.id })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setShowEmailFieldModal(false);
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  return (
    <Form
      initialValues={{
        email: getProfileDetailObj.status === "succeeded" ? getProfileDetailObj.data.email : "",
        businessId: null,
      }}
      validationSchema={emailValidation}
      onSubmit={handleSendStatementEmail}
    >
      <EmailFieldModal
        showModal={showEmailFieldModal}
        setShowModal={setShowEmailFieldModal}
        name="email"
      />
      <Box
        width="100%"
        sx={{
          paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
          paddingY: theme.dimensions.ScreenPaddingY,
        }}
      >
        <DisputeLogModal showModal={showDisputeLogModal} setShowModal={setShowDisputeLogModal} />

        <Grid container>
          <Grid item sx={{ display: "flex", flex: 1 }}>
            <PaddedView multiples={isMobile ? 3 : 0}>
              <Box sx={{ width: isMobile ? "100%" : "90%" }}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <SpaceBetweenRowBox>
                      <CenterRowBox>
                        {getStatementDetailObj.status === "succeeded" && (
                          <Link
                            to={{
                              pathname:
                                getStatementDetailObj.data.statement.status === "closed"
                                  ? routes.STATEMENT_CLOSED
                                  : routes.STATEMENT_CURRENT,
                            }}
                          >
                            <StyledBackButton>
                              <ArrowBackIosIcon />
                            </StyledBackButton>
                          </Link>
                        )}
                        <Text variant="screenLabel">Statement Details</Text>
                      </CenterRowBox>
                      <RowBox>
                        {getStatementDetailObj.status === "succeeded" && (
                          <>
                            {getStatementDetailObj.data.statement.status === "dispute" &&
                              getStatementDetailObj.data.statement.disputeLog.length > 0 && (
                                <>
                                  <CtaButton onClickButton={() => setShowDisputeLogModal(true)}>
                                    <Text type="WhiteColor">View Dispute Log</Text>
                                  </CtaButton>
                                  <Spacer size="m" position="left" />
                                </>
                              )}

                            <CtaButton onClickButton={() => setShowEmailFieldModal(true)}>
                              <Text type="WhiteColor">Send Email</Text>
                            </CtaButton>
                          </>
                        )}
                      </RowBox>
                    </SpaceBetweenRowBox>
                  </Grid>
                  <Grid item xs={12}>
                    <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Business</Text>
                    <BusinessListAutoCompleteSingleSelect
                      name="business"
                      placeholder="Business"
                      onFieldChange={handleBusinessChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {renderStatementCard()}
                    <Spacer size="m" />
                  </Grid>
                  {renderStatementList()}
                </Grid>
              </Box>
            </PaddedView>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
}

export default StatementDetailScreen;
