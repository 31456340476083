import CampaignIcon from "@mui/icons-material/Campaign";
import { Grid, Skeleton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import DialogModal from "../../../components/notification/dialog-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { getUserPermission, isMerchantStaff } from "../../../infrastructure/utils";
import routes from "../../../navigation/routes";
import {
  footfallSelector,
  getTotalSessions,
  getTotalVisitors,
} from "../../../services/footfall/footfall-slice.service";
import { resetUser, staffLogout } from "../../../services/staff/auth/auth.slice.services";
import CheckBusinessAccessModal from "../components/check-business-access-modal.component";
import EntryChart from "../components/entry-chart.component";
import EntryLogTable from "../components/entry-log-table.component";

const StyledGrid = styled(Grid)(({ theme }) => ({
  // border: "1px solid",
  height: "44.5px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  backgroundColor: theme.palette.colors.brand.linear,
  borderRadius: `${theme.shape.borderRadius[1]}px`,
  color: "#fff",
}));

export default function HomeScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getTotalVisitorsObj, getTotalSessionsObj } = useSelector(footfallSelector);
  const [showModal, setShowModal] = useState(false);
  const [showNoAccessModal, setShowNoAccessModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getTotalVisitors({
        startAt: format(new Date(), "yyyy-MM-dd 00:00:00"),
        endAt: format(new Date(), "yyyy-MM-dd 23:59:59"),
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    dispatch(
      getTotalSessions({
        startAt: format(new Date(), "yyyy-MM-dd 00:00:00"),
        endAt: format(new Date(), "yyyy-MM-dd 23:59:59"),
      }),
    ).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const handleLogout = () => {
    dispatch(staffLogout()).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({ message: payload.message, type: "success" });
        history.push(routes.LOGIN_STAFF);
      }
      if (meta.requestStatus === "rejected") {
        dispatch(resetUser());
        createSnackBar({ message: "Successfully logged out", type: "success" });
        history.push(routes.LOGIN_STAFF);
      }
    });
  };

  useEffect(() => {
    if (isMerchantStaff() && getUserPermission().length === 0) {
      setShowNoAccessModal(true);
    }
  }, []);

  return (
    <Grid container padding="10px" spacing={1}>
      <Grid item xs={12} display="flex" alignItems="center">
        <Grid
          container
          item
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: theme.palette.colors.brand.secondary,
            padding: "20px",
            borderRadius: `${theme.shape.borderRadius[4]}px`,
          }}
          columnSpacing={!isMobile ? 2 : undefined}
        >
          <Grid item display="flex" alignItems="center" xs={isMobile ? 2 : undefined}>
            <CampaignIcon sx={{ color: theme.palette.colors.brand.primary }} />
          </Grid>
          <Grid item display="flex" xs={isMobile ? 10 : undefined}>
            <Typography>Notice: Membership feature is currently in beta phase!</Typography>
          </Grid>
        </Grid>
      </Grid>

      <DialogModal
        showModal={showNoAccessModal}
        setShowModal={setShowNoAccessModal}
        title="You do not have any permissions to access the portal. Please contact the owner. Thank you."
        buttonText="OK"
        onConfirm={handleLogout}
      />
      <CheckBusinessAccessModal showModal={showModal} setShowModal={setShowModal} />
      <Grid item xs={isMobile ? 12 : 6}>
        <Grid item container spacing={1}>
          {getTotalVisitorsObj.status === "succeeded" ? (
            <Grid item xs={isMobile ? 12 : 4}>
              <StyledGrid item>
                <Typography>Total Visitors: {getTotalVisitorsObj.data.total}</Typography>
              </StyledGrid>
            </Grid>
          ) : (
            <Grid item xs={isMobile ? 12 : 4}>
              <Skeleton
                variant="rounded"
                width="100%"
                height={44.5}
                sx={{ borderRadius: `${theme.shape.borderRadius[1]}px` }}
              />
            </Grid>
          )}
          {getTotalSessionsObj.status === "succeeded" ? (
            <Grid item xs={isMobile ? 12 : 4}>
              <StyledGrid item>
                <Typography>Total Sessions: {getTotalSessionsObj.data.total}</Typography>
              </StyledGrid>
            </Grid>
          ) : (
            <Grid item xs={isMobile ? 12 : 4}>
              <Skeleton
                variant="rounded"
                width="100%"
                height={44.5}
                sx={{ borderRadius: `${theme.shape.borderRadius[1]}px` }}
              />
            </Grid>
          )}

          <Grid item xs={isMobile ? 12 : 4}>
            <CtaBorderButton
              onClickButton={() => {
                setShowModal(true);
              }}
            >
              <Text type="BrandColor">Check Access</Text>
            </CtaBorderButton>
          </Grid>
        </Grid>
        <Spacer />
        <Grid item xs={12}>
          <EntryChart />
        </Grid>
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <EntryLogTable />
      </Grid>
    </Grid>
  );
}
