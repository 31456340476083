import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CtaButton from "../../../../components/button/cta-button.component";
import BackdropLoading from "../../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/padded-view.component";
import Spacer from "../../../../components/spacer.component";
import TableLoader from "../../../../components/table/table-loader.component";
import {
  closeStaffStatement,
  getStaffStatementDetail,
  sendEmail,
  setReduxStoreStatementDetails,
  staffStatementSelector,
} from "../../../../services/staff/statement/statement-slice.service";
import ClosePendingConfirmationModal from "../components/close-pending-confirmation-modal.component";
import StatementDetails from "../components/statement-details.component";
import TransactionTable from "../components/transaction-table.component";
import Text from "../../../../components/text.component";
import EmailFieldModal from "../components/email-field-modal.component";
import Form from "../../../../components/forms/form.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const RowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const emailValidation = Yup.object().shape({
  email: Yup.string().required().email().label("Email"),
});

function StaffStatementDetailScreen() {
  const location = useLocation();
  const statementId = new URLSearchParams(location.search).get("statementId");
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [showClosePendingStatementModal, setShowClosePendingStatementModal] = useState(false);
  const [showEmailFieldModal, setShowEmailFieldModal] = useState(false);
  const { getStaffStatementDetailObj, closeStaffStatementObj, reduxStoreStatementDetails } =
    useSelector(staffStatementSelector);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [closedOrPendingModal, setClosedOrPendingModal] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setReduxStoreStatementDetails({}));
    dispatch(getStaffStatementDetail(statementId)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      } else if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        dispatch(setReduxStoreStatementDetails(payload.data));
      }
    });
  }, []);

  const onCloseStatement = () => {
    setIsLoading(true);
    dispatch(closeStaffStatement({ statementId })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setShowClosePendingStatementModal(false);
        dispatch(setReduxStoreStatementDetails(payload.data));
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderStatus = (status) => {
    if (status === "dispute") {
      return (
        <Typography
          variant="h6"
          sx={{ textTransform: "uppercase", color: theme.palette.colors.text.error }}
        >
          {status}
        </Typography>
      );
    }
    if (status === "open") {
      return (
        <Typography
          variant="h6"
          sx={{ textTransform: "uppercase", color: theme.palette.colors.text.success }}
        >
          {status}
        </Typography>
      );
    }
    if (status === "pending") {
      return (
        <Typography
          variant="h6"
          sx={{ textTransform: "uppercase", color: theme.palette.colors.text.link }}
        >
          {status}
        </Typography>
      );
    }
    return (
      <Typography
        variant="h6"
        sx={{ textTransform: "uppercase", color: theme.palette.colors.text.purple }}
      >
        {status}
      </Typography>
    );
  };

  const renderDisputeAndCloseButton = () => (
    <RowBox>
      <Spacer size="s" position="left" />
      <CtaButton onClickButton={() => setShowEmailFieldModal(true)}>
        <Text type="WhiteColor">Send Email</Text>
      </CtaButton>
      <Spacer size="s" position="left" />
      {reduxStoreStatementDetails.status === "pending" && (
        <CtaButton
          width="125px"
          onClickButton={() => {
            setShowClosePendingStatementModal(true);
            setClosedOrPendingModal("closed");
          }}
        >
          <Typography sx={{ color: theme.palette.colors.text.white }}>Close</Typography>
        </CtaButton>
      )}
    </RowBox>
  );

  const handleSendStatementEmail = (value) => {
    dispatch(sendEmail({ ...value, statementId })).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setShowEmailFieldModal(false);
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  const renderContent = () => {
    if (getStaffStatementDetailObj.status === "succeeded") {
      const statement = getStaffStatementDetailObj.data;
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Box>
                <Typography variant="h4">Staff Statement ID: {statement.id}</Typography>
                {renderStatus(reduxStoreStatementDetails.status)}
              </Box>
              {!isMobile && renderDisputeAndCloseButton()}
            </SpaceBetweenBox>
          </Grid>
          {isMobile && (
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                {renderDisputeAndCloseButton()}
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <StatementDetails />
          </Grid>

          <Grid item xs={12}>
            <TransactionTable />
          </Grid>
        </Grid>
      );
    }
    return <TableLoader />;
  };

  return (
    <PaddedView>
      <BackdropLoading isLoading={isLoading} />
      {getStaffStatementDetailObj.status === "succeeded" && (
        <Form
          initialValues={{
            email: getStaffStatementDetailObj.data.staff.email,
          }}
          validationSchema={emailValidation}
          onSubmit={handleSendStatementEmail}
        >
          <EmailFieldModal
            showModal={showEmailFieldModal}
            setShowModal={setShowEmailFieldModal}
            name="email"
          />
        </Form>
      )}

      <ClosePendingConfirmationModal
        showModal={showClosePendingStatementModal}
        setShowModal={setShowClosePendingStatementModal}
        isLoading={closeStaffStatementObj.status === "pending"}
        onConfirmClicked={() => onCloseStatement(closedOrPendingModal)}
        type={closedOrPendingModal}
      />

      <Spacer size="xl" position="top" />
      {renderContent()}
    </PaddedView>
  );
}

export default StaffStatementDetailScreen;
