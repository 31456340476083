import { Grid, Pagination, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import PaddedView from "../../../../components/padded-view.component";
import Spacer from "../../../../components/spacer.component";
import {
  getCreditTransactions,
  creditSelector,
} from "../../../../services/credit/credit-slice.service";
import TransactionRowLoader from "../../loader/credit/transaction-row-loader.component";
import FilterMenu from "./filter-menu.component";
import TransactionRow from "./transaction-row.component";

function TransactionHistory() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getCreditTransactionsObj } = useSelector(creditSelector);
  const [page, setPage] = useState(1);
  const [transactionFilter, setTransactionFilter] = useState("all");

  const onPageChange = (e, newPage) => {
    setPage(newPage);
    dispatch(getCreditTransactions({ type: transactionFilter, page: newPage })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  useEffect(() => {
    onPageChange(null, 1);
  }, []);

  const renderTransactions = () => {
    if (getCreditTransactionsObj.status === "succeeded") {
      if (getCreditTransactionsObj.data.items.length > 0) {
        return (
          <>
            {getCreditTransactionsObj.data.items.map((item) => (
              <Grid container justifyContent="space-between" key={item.id} marginBottom="20px">
                <TransactionRow itemDetails={item} />
              </Grid>
            ))}
            <Grid container justifyContent="center">
              <Pagination
                page={page}
                onChange={onPageChange}
                count={getCreditTransactionsObj.data.pagination.totalPages}
                variant="outlined"
              />
            </Grid>
          </>
        );
      }
      return (
        <Grid item xs={12} textAlign="center">
          <Typography>No transactions</Typography>
          <Spacer size="m" />
        </Grid>
      );
    }
    return (
      <Grid container justifyContent="space-between">
        <TransactionRowLoader />
      </Grid>
    );
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[1]}px`,
      }}
    >
      <PaddedView multiples={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography fontWeight="bold" fontSize={theme.fonts.fontSizes.size20}>
              Transactions History
            </Typography>
          </Grid>
          <Grid item alignSelf="center">
            <FilterMenu
              transactionFilter={transactionFilter}
              setTransactionFilter={setTransactionFilter}
            />
          </Grid>
        </Grid>
        <Spacer position="top" size="m" />
        {renderTransactions()}
      </PaddedView>
    </Grid>
  );
}

export default TransactionHistory;
