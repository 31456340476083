import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import CheckBusinessAccessModal from "../../../components/modal/check-business-access-modal.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableSort from "../../../components/table/table-sort.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  getMembershipCSV,
  getMemberships,
  membershipSelector,
} from "../../../services/membership/membership-slice.service";
import MembershipTableRow from "../components/membership-table-row.component";
import SpecificListAutoComplete from "../components/specific-list-autocomplete.component";
import MembershipTableRowLoader from "../loader/membership-table-row-loader.component";
import SearchBar from "../../../components/table/search-bar.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
}));

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable(),
  businesses: Yup.array().label("Businesses").nullable(),
});

export default function MembershipListScreen() {
  const theme = useTheme();
  const formRef = useRef();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [q, setQ] = useState("");
  const [businesses, setBusinesses] = useState(null);
  const [page, setPage] = useState(1);
  const { getMembershipsObj, getMembershipCSVObj } = useSelector(membershipSelector);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const columnMapping = {
    Name: "user.name",
    Phone: "user.phone",
    "Membership Identifier": "plan.identifier",
    Price: "price",
    Status: "status",
  };
  const { sortColumn, sortOrder, onSortChange, getProcessedRecord } = TableSort(columnMapping);

  const onRefreshMembershipList = (newPage, keyword, businessesId) => {
    setIsLoading(true);
    setPage(newPage);
    setQ(keyword);
    setBusinesses(businessesId);
    dispatch(
      getMemberships({
        page: newPage,
        q: keyword,
        businessesId,
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
    dispatch(
      getMembershipCSV({
        q: keyword,
        businessesId,
      }),
    );
    setIsLoading(false);
  };

  const submitFilter = (values) => {
    const businessesId = values.businesses.map((item) => item.id);
    onRefreshMembershipList(1, values.q, businessesId);
  };

  const onChangeSearch = (keyword) => {
    onRefreshMembershipList(1, keyword, businesses);
  };

  const onPageChange = (e, newPage) => {
    onRefreshMembershipList(newPage, q, businesses);
  };

  useEffect(() => {
    onRefreshMembershipList(page, q);
    dispatch(getMembershipCSV({ q }));
  }, []);

  const renderTableBody = () => {
    if (getMembershipsObj.status === "succeeded") {
      const records = getProcessedRecord(
        getMembershipsObj.status === "succeeded" ? getMembershipsObj.data : null,
      );
      if (getMembershipsObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={7}>
              <TableEmptyBox>
                <Text type="TableText">No Memberships</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => <MembershipTableRow membership={item} key={item.id} />);
    }
    return <MembershipTableRowLoader />;
  };

  return (
    <>
      <CheckBusinessAccessModal
        showModal={showModal}
        setShowModal={setShowModal}
        productType="subscription"
      />
      <Formik
        innerRef={formRef}
        initialValues={{
          q: q || "",
          businesses: [],
        }}
        onSubmit={submitFilter}
        validationSchema={validationSchema}
      >
        <Grid
          container
          spacing={3}
          sx={{
            paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
            paddingY: theme.dimensions.ScreenPaddingY,
          }}
        >
          <Grid item xs={12}>
            <Text variant="screenLabel">Memberships</Text>
          </Grid>
          <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
            <WhiteBgCardContainer>
              <BackdropLoading isLoading={isLoading} />

              <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Search</Text>
                          </Grid>
                          <Grid item xs={9}>
                            <SearchBar
                              placeholder="Search by user details"
                              name="q"
                              searchKeyword={q}
                              setSearchKeyword={onChangeSearch}
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Text>Businesses</Text>
                          </Grid>

                          <Grid item xs={9}>
                            <SpecificListAutoComplete
                              name="businesses"
                              placeholder="Businesses"
                              isWhiteBg
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                      <Grid item xs={12} sm={4}>
                        <FormSubmitButton>
                          <Text type="WhiteColor">Search</Text>
                        </FormSubmitButton>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        {getMembershipCSVObj.status === "succeeded" ? (
                          <CSVLink
                            data={getMembershipCSVObj.data}
                            filename={`merchant_memberships_${`${moment().format("Y-MM-DD")}`}.csv`}
                            style={{ textDecoration: "none" }}
                          >
                            <CtaBorderButton disabled={getMembershipCSVObj.status !== "succeeded"}>
                              <Text
                                sx={{
                                  color:
                                    getMembershipCSVObj.status === "succeeded"
                                      ? theme.palette.colors.brand.primary
                                      : theme.palette.colors.text.disabled,
                                }}
                              >
                                Download CSV
                              </Text>
                            </CtaBorderButton>
                          </CSVLink>
                        ) : (
                          <>
                            <CtaBorderButton disabled={true}>
                              <Text
                                sx={{
                                  color: theme.palette.colors.text.disabled,
                                }}
                              >
                                Download CSV
                              </Text>
                            </CtaBorderButton>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SearchContainer>

              <Grid container sx={{ paddingY: "20px" }} spacing={3}>
                <Grid item xs={12}>
                  <TableWrapper>
                    <Table>
                      <TableHeader
                        headerCells={["Name", "Phone", "Membership Identifier", "Price", "Status"]}
                        sortColumn={sortColumn}
                        sortOrder={sortOrder}
                        onSortChange={onSortChange}
                        columnMapping={columnMapping}
                      />
                      <TableBody>{renderTableBody()}</TableBody>
                    </Table>
                  </TableWrapper>
                </Grid>
                <Grid item xs={12}>
                  <CustomFooter>
                    <Pagination
                      sx={{
                        "&& .Mui-selected": {
                          backgroundColor: theme.palette.colors.brand.primary,
                          color: theme.palette.colors.text.white,
                        },
                      }}
                      page={page}
                      shape="rounded"
                      onChange={onPageChange}
                      count={getMembershipsObj?.data?.pagination.totalPages || totalPages}
                      variant="outlined"
                    />
                  </CustomFooter>
                </Grid>
              </Grid>
            </WhiteBgCardContainer>
          </Grid>
        </Grid>
      </Formik>
    </>
  );
}
