import { Box, IconButton, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { staffStatementSelector } from "../../../../services/staff/statement/statement-slice.service";

function TransactionRow({ record, setShowAdjustModal, setSelectedTransaction }) {
  const theme = useTheme();
  const { getStaffStatementDetailObj } = useSelector(staffStatementSelector);
  return (
    <TableRow
      key={record.id}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: "no-set",
        },
      }}
    >
      <TableCell sx={{ textAlign: "center" }}>
        <Typography variant="body2" noWrap={true}>
          {record.id}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap={true} sx={{ width: "350px" }}>
          Merchant Class Id: {record.itemId} #{record.itemTitle}
          <br />
          Sales: RM {record.salesAmount}
          {getStaffStatementDetailObj.status === "succeeded" &&
            getStaffStatementDetailObj.data.commissionSetting.deductPlatformFee && (
              <>
                <br />
                Platform: RM {record.platformFeeAmount}
              </>
            )}
          <br />
          Merchant membership: RM {record.membershipAmount}
          {record.adjustmentAmount !== "0.00" && (
            <>
              <br />
              Adjustment: {record.adjustmentAmount.includes("-") && "-"} RM{" "}
              {record.adjustmentAmount.replace("-", "")}
              <br />
              Adjustment Remark: {record.adjustmentRemark ?? "-"}
            </>
          )}
        </Typography>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <Typography variant="body2" noWrap={true}>
          {record.createdAt}
        </Typography>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <Typography
          variant="body2"
          noWrap={true}
          sx={{
            color: record.amount.includes("-")
              ? theme.palette.colors.text.error
              : theme.palette.colors.text.success,
          }}
        >
          {record.amount.includes("-") ? "-" : "+"}
          {record.amount.replace("-", "")}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: "100px" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            disabled={
              getStaffStatementDetailObj.status === "succeeded"
                ? getStaffStatementDetailObj.data.status !== "pending"
                : true
            }
            onClick={() => {
              setShowAdjustModal(true);
              setSelectedTransaction(record);
            }}
          >
            <EditIcon />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}

TransactionRow.defaultProps = {
  record: {
    id: null,
    itemTitle: "",
    itemId: null,
    amount: "",
    salesAmount: "",
    platformFeeAmount: "",
    membershipAmount: "",
    adjustmentAmount: "",
    adjustmentRemark: "",
    createdAt: "",
  },
};

TransactionRow.propTypes = {
  setShowAdjustModal: PropTypes.func.isRequired,
  setSelectedTransaction: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    itemTitle: PropTypes.string,
    itemId: PropTypes.number,
    amount: PropTypes.string,
    salesAmount: PropTypes.string,
    platformFeeAmount: PropTypes.string,
    membershipAmount: PropTypes.string,
    adjustmentAmount: PropTypes.string,
    adjustmentRemark: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};

export default TransactionRow;
