import { Box, Grid, Modal, styled, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import FormCheckBox from "../../../components/forms/form-check-box.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormLabel from "../../../components/forms/form-label.component";
import FormSelect from "../../../components/forms/form-select.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { commissionSettingSelector } from "../../../services/commission-setting/commission-setting-slice.service";
import StaffListAutoComplete from "./staff-list-autocomplete.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  maxHeight: "90%",
  overflowY: "scroll",
}));

export default function CommissionSettingFormModal({ showModal, setShowModal, title }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm, values } = useFormikContext();
  const { createCommissionSettingObj, updateCommissionSettingObj } =
    useSelector(commissionSettingSelector);
  const handleCancel = () => {
    resetForm();
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "50px",
          width: isMobile ? "350px" : "600px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Text variant="screenLabel">{title}</Text>
          </Grid>

          <Grid item xs={12}>
            <FormLabel bold>Staff</FormLabel>
            <StaffListAutoComplete
              name="staff"
              placeholder="Staff"
              multiple={false}
              disabled={title.includes("Update")}
              disabledOption={true}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel bold>Commission Type</FormLabel>
            <FormSelect
              itemList={[
                {
                  id: 1,
                  label: "Profit Share",
                  value: "profit_share",
                },
                {
                  id: 2,
                  label: "Fixed Rate",
                  value: "fixed_rate",
                },
              ]}
              name="type"
              placeholder="Select Commission Type"
              value="value"
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel bold>Staff Rate Per Session</FormLabel>
            <FormFieldText type="number" name="rate" placeholder="Rate" />
          </Grid>
          {values.type === "profit_share" && (
            <Grid item xs={12}>
              <FormLabel bold>Merchant Rate Per Session</FormLabel>
              <Box
                sx={{
                  border: "1px solid #bcbcbc",
                  backgroundColor: "#f4f4f4",
                  borderRadius: `${theme.shape.borderRadius[0]}px`,
                  height: "41.5px",
                  padding: "10px",
                  verticalAlign: "center",
                }}
              >
                {(100 - parseFloat(values.rate ? values.rate : 0)).toFixed(2)}
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormLabel bold>Deduct Platform Fee</FormLabel>
            <FormCheckBox name="deductPlatformFee" />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <CtaBorderButton
                onClickButton={() => setShowModal(false)}
                width="100px"
                isLoading={
                  createCommissionSettingObj.status === "pending" ||
                  updateCommissionSettingObj.status === "pending"
                }
              >
                <Text type="BrandColor">Cancel</Text>
              </CtaBorderButton>
              <Spacer size="m" position="left" />
              <FormSubmitButton
                width="100px"
                isLoading={
                  createCommissionSettingObj.status === "pending" ||
                  updateCommissionSettingObj.status === "pending"
                }
              >
                <Text type="WhiteColor">Save</Text>
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

CommissionSettingFormModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};
