import { Box, Button, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import WhiteBgCardContainer from "../../../../components/container/white-bg-card-container.component";
import FormFieldText from "../../../../components/forms/form-field-text.component";
import FormPrice from "../../../../components/forms/form-price.component";
import FormSelect from "../../../../components/forms/form-select.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import Text from "../../../../components/text.component";
import routes from "../../../../navigation/routes";
import {
  createMembershipPlan,
  membershipPlanSelector,
} from "../../../../services/membership/plan/membership-plan-slice.service";
import BusinessListAutoCompleteSingleSelect from "../../../home/components/business-list-autocomplete-single-select.component";
import FormImageUpload from "../../../../components/forms/form-image-upload.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

export default function MembershipPlanCreateScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const [filePreview, setFilePreview] = useState("");
  const { createMembershipPlanObj } = useSelector(membershipPlanSelector);

  const validationSchema = Yup.object().shape({
    identifier: Yup.string()
      .required()
      .matches(/^[^&<>]*$/, "Special Characters '&', '<' and '>' is not allowed")
      .label("Identifier"),
    status: Yup.string().required().label("Status"),
    business: Yup.mixed().required().label("Business"),
    priceRM: Yup.number().max(10000).required().label("Price"),
    image: Yup.string().required().label("Image"),
  });

  const onCreateMembershipPlan = (values) => {
    const priceCents = values.priceRM * 100;
    setIsLoading(true);
    dispatch(
      createMembershipPlan({
        ...values,
        priceCents,
        businessId: values.business.id,
        status: values.status.toLowerCase(),
      }),
    ).then(({ meta, payload, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
        history.push(routes.MEMBERSHIP_PLAN_LIST);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  return (
    <Grid>
      <BackdropLoading isLoading={isLoading} />
      <Formik
        validationSchema={validationSchema}
        onSubmit={onCreateMembershipPlan}
        initialValues={{
          identifier: "",
          business: null,
          status: "Inactive",
          renewalDays: "",
          priceRM: "",
          image: "",
        }}
      >
        <Form>
          <Grid
            container
            spacing={5}
            sx={{
              padding: isMobile ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
              paddingY: theme.dimensions.ScreenPaddingY,
            }}
          >
            <Grid item xs={12}>
              <SpaceBetweenBox>
                <Text variant="screenLabel">Create Membership Plan</Text>
                <Button
                  onClick={() => history.push(routes.MEMBERSHIP_PLAN_LIST)}
                  sx={{
                    textTransform: "none",
                    padding: "0px",
                    ":hover": { backgroundColor: "transparent" },
                  }}
                >
                  <Text>Back</Text>
                </Button>
              </SpaceBetweenBox>
            </Grid>

            <Grid item xs={12}>
              <WhiteBgCardContainer>
                <Grid container spacing={isMobile ? 2 : 3}>
                  <Grid item xs={12}>
                    <Text variant="screenLabel">Membership Plan</Text>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={12} md={2}>
                      <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Business</Text>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <BusinessListAutoCompleteSingleSelect
                        name="business"
                        placeholder="Business"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={12} md={2}>
                      <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                        Membership Plan Identifier
                      </Text>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <FormFieldText name="identifier" placeholder="Membership Plan Identifier" />
                    </Grid>
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid item xs={12} md={2}>
                      <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <FormPrice placeholder="00.00" name="priceRM" />
                    </Grid>
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid item xs={12} md={2}>
                      <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Status</Text>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <FormSelect
                        itemList={[
                          { label: "Active", id: "active" },
                          { label: "Inactive", id: "inactive" },
                        ]}
                        name="status"
                        placeholder="Select status"
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={12} md={2}>
                      <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Renewal Days</Text>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <FormFieldText name="renewalDays" label="Renewal Days" type="number" />
                    </Grid>
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid item xs={12} md={2}>
                      <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Image</Text>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <FormImageUpload
                        name="image"
                        filePreview={filePreview}
                        setFilePreview={setFilePreview}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </WhiteBgCardContainer>
            </Grid>
            <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
              <FormSubmitButton
                width="200px"
                isLoading={createMembershipPlanObj.status === "pending"}
              >
                <Text type="WhiteColor">Create</Text>
              </FormSubmitButton>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Grid>
  );
}
