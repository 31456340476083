import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../../api-services";

export const addSession = createAsyncThunk(
  "merchants/fitness_classes/session/add",
  async (payload) => {
    const {
      classId,
      startDate,
      startTime,
      endTime,
      endDate,
      weeklyRepeat,
      occurenceType,
      occurenceNumber,
      maxCapacity,
      staffId,
      status,
      amountCents,
      membershipAmountCents,
      dates,
    } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/sessions`,
      {
        classId,
        startDate,
        startTime,
        endTime,
        endDate,
        weeklyRepeat,
        occurenceType,
        occurenceNumber,
        maxCapacity,
        staffId,
        status,
        amountCents,
        membershipAmountCents,
        dates,
      },
    );
    return response;
  },
);

export const getSessionList = createAsyncThunk(
  "merchants/fitness_classes/session/list",
  async (payload) => {
    const { statuses, businessIds, categoryIds, classIds, startAt, endAt, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/sessions?${
        page ? "page=".concat(page) : "page=1"
      }${businessIds ? "&businessIds=".concat(businessIds) : ""}${
        categoryIds ? "&categoryIds=".concat(categoryIds) : ""
      }${classIds ? "&classIds=".concat(classIds) : ""}${
        startAt ? "&startAt=".concat(startAt) : ""
      }${endAt ? "&endAt=".concat(endAt) : ""}${statuses ? "&statuses=".concat(statuses) : ""}`,
    );
    return response;
  },
);

export const getSession = createAsyncThunk(
  "merchants/fitness_classes/session/get/{session_id}",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/sessions/${payload.sessionId}`,
    );
    return response;
  },
);

export const toggleSessionStatus = createAsyncThunk(
  "merchants/fitness_classes/session/status",
  async (payload) => {
    const { status, sessionIds } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/sessions/status`,
      { status, sessionIds },
    );
    return response;
  },
);

export const updateSession = createAsyncThunk(
  "merchants/fitness_classes/session/update",
  async (payload) => {
    const {
      sessionId,
      startTime,
      startDate,
      endTime,
      endDate,
      amountCents,
      membershipAmountCents,
      maxCapacity,
      staffId,
    } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/sessions/${sessionId}`,
      {
        startTime,
        startDate,
        endTime,
        endDate,
        amountCents,
        membershipAmountCents,
        maxCapacity,
        staffId,
      },
    );
    return response;
  },
);

export const deleteSession = createAsyncThunk(
  "merchants/fitness_classes/session/delete/{session_id}",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/sessions/${payload.sessionId}`,
    );
    return response;
  },
);

const fitnessSessionSlice = createSlice({
  name: "fitnessSession",
  initialState: {
    addSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSessionListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    toggleSessionStatusObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteSessionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [addSession.pending]: (state) => {
      state.addSessionObj.status = "pending";
    },
    [addSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.addSessionObj.status = "succeeded";
      state.addSessionObj.data = data;
      state.addSessionObj.successMessage = message;
    },
    [addSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.addSessionObj.status = "failed";
      state.addSessionObj.errorMessage = message;
    },
    [getSessionList.pending]: (state) => {
      state.getSessionListObj.status = "pending";
    },
    [getSessionList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSessionListObj.status = "succeeded";
      state.getSessionListObj.data = data;
      state.getSessionListObj.successMessage = message;
    },
    [getSessionList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSessionListObj.status = "failed";
      state.getSessionListObj.errorMessage = message;
    },
    [getSession.pending]: (state) => {
      state.getSessionObj.status = "pending";
    },
    [getSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getSessionObj.status = "succeeded";
      state.getSessionObj.data = data;
      state.getSessionObj.successMessage = message;
    },
    [getSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.getSessionObj.status = "failed";
      state.getSessionObj.errorMessage = message;
    },
    [toggleSessionStatus.pending]: (state) => {
      state.toggleSessionStatusObj.status = "pending";
    },
    [toggleSessionStatus.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.toggleSessionStatusObj.status = "succeeded";
      state.toggleSessionStatusObj.data = data;
      state.toggleSessionStatusObj.successMessage = message;
    },
    [toggleSessionStatus.rejected]: (state, action) => {
      const { message } = action.error;

      state.toggleSessionStatusObj.status = "failed";
      state.toggleSessionStatusObj.errorMessage = message;
    },
    [updateSession.pending]: (state) => {
      state.updateSessionObj.status = "pending";
    },
    [updateSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateSessionObj.status = "succeeded";
      state.updateSessionObj.data = data;
      state.updateSessionObj.successMessage = message;
    },
    [updateSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateSessionObj.status = "failed";
      state.updateSessionObj.errorMessage = message;
    },
    [deleteSession.pending]: (state) => {
      state.deleteSessionObj.status = "pending";
    },
    [deleteSession.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteSessionObj.status = "succeeded";
      state.deleteSessionObj.data = data;
      state.deleteSessionObj.successMessage = message;
    },
    [deleteSession.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteSessionObj.status = "failed";
      state.deleteSessionObj.errorMessage = message;
    },
  },
});

export default fitnessSessionSlice.reducer;

// state
export const fitnessSessionSelector = (state) => state.fitnessSession;
