import {
  Box,
  Grid,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import TopTitleBox from "../../../components/top-title-box.component";
import theme from "../../../infrastructure/theme";
import CreateUserBulk from "./create-user-bulk.component";
import CreateUserUpload from "./create-user-upload.component";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderRadius[1],
  boxShadow: "24px",
  paddingTop: "25px",
  paddingBottom: "25px",
  outline: "none",
  maxHeight: "700px",
  overflow: "auto",
});

export default function CreateUserModal({ showModal, title, setShowModal, width, onCreate }) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [createType, setCreateType] = useState("bulk");

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "360px" : width, paddingX: isMobile ? "25px" : "40px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ToggleButtonGroup
              value={createType}
              exclusive
              onChange={(e) => {
                setCreateType(e.target.value);
              }}
            >
              <ToggleButton value="bulk" sx={{ whiteSpace: "nowrap" }}>
                Bulk
              </ToggleButton>
              <ToggleButton value="upload" sx={{ whiteSpace: "nowrap" }}>
                Upload
              </ToggleButton>
            </ToggleButtonGroup>
            <TopTitleBox title={title} requireCloseButton={true} setShowModal={setShowModal} />
          </Grid>

          {createType === "bulk" && (
            <Grid item xs={12}>
              <CreateUserBulk onCreate={onCreate} />
            </Grid>
          )}
          {createType === "upload" && (
            <Grid item xs={12}>
              <CreateUserUpload onCreate={onCreate} />
            </Grid>
          )}
        </Grid>
      </ModalBox>
    </Modal>
  );
}

CreateUserModal.defaultProps = {
  width: "600px",
  title: "",
};

CreateUserModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string,
  setShowModal: PropTypes.func.isRequired,
  width: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
};
