import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../api-services";

export const getMemberships = createAsyncThunk("merchants/memberships/index", async (payload) => {
  const { q, page, businessesId } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/memberships/?${q ? "&q=".concat(q) : ""}${
      businessesId ? "&businessesId=".concat(businessesId) : ""
    }&page=${page}`,
  );
  return response;
});

export const getMembershipCSV = createAsyncThunk(
  "analytics/merchant/packages/csv",
  async (payload) => {
    const { q, statuses, businessesId, startAt, endAt } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/memberships/csv?${
        q ? "&q=".concat(q) : ""
      }${statuses ? "&statuses=".concat(statuses) : ""}${
        businessesId ? "&businessesId=".concat(businessesId) : ""
      }${startAt ? "&startAt=".concat(startAt) : ""}${endAt ? "&endAt=".concat(endAt) : ""}`,
    );
    return response;
  },
);

const membershipSlice = createSlice({
  name: "membership",
  initialState: {
    getMembershipsObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMembershipCSVObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getMemberships.pending]: (state) => {
      state.getMembershipsObj.status = "pending";
    },
    [getMemberships.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMembershipsObj.status = "succeeded";
      state.getMembershipsObj.data = data;
      state.getMembershipsObj.successMessage = message;
    },
    [getMemberships.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMembershipsObj.status = "failed";
      state.getMembershipsObj.errorMessage = message;
    },
    [getMembershipCSV.pending]: (state) => {
      state.getMembershipCSVObj.status = "pending";
    },
    [getMembershipCSV.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMembershipCSVObj.status = "succeeded";
      state.getMembershipCSVObj.data = data;
      state.getMembershipCSVObj.successMessage = message;
    },
    [getMembershipCSV.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMembershipCSVObj.status = "failed";
      state.getMembershipCSVObj.errorMessage = message;
    },
  },
});

export default membershipSlice.reducer;

// state
export const membershipSelector = (state) => state.membership;
