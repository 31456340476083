import React, { useContext, useState } from "react";
import { Avatar, Box, IconButton, Menu, MenuItem, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SnackbarContext } from "../../components/notification/snackbar.context";
import routes from "../../navigation/routes";
import { logout, resetUser } from "../../services/auth/auth.slice.services";
import { profileSelector } from "../../services/profile/profile.slice.services";
import { isMerchantStaff } from "../utils";
import { staffLogout } from "../../services/staff/auth/auth.slice.services";
import { profileStaffSelector } from "../../services/staff/profile/profile.slice.services";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const StyledAvatarButton = styled(IconButton)({
  padding: "0px",
  ":hover": { backgroundColor: "transparent" },
  ":active": { backgroundColor: "transparent" },
});

function DesktopAvatarMenu() {
  const [avatarMenu, setAvatarMenu] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const createSnackBar = useContext(SnackbarContext);
  const { getProfileDetailObj } = useSelector(profileSelector);
  const { getStaffProfileDetailObj } = useSelector(profileStaffSelector);

  const handleOpenUserMenu = (event) => {
    setAvatarMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAvatarMenu(null);
  };

  const handleLogout = () => {
    if (!isMerchantStaff()) {
      dispatch(logout()).then(({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({ message: payload.message, type: "success" });
          history.push(routes.LOGIN);
        }
        if (meta.requestStatus === "rejected") {
          dispatch(resetUser());
          createSnackBar({ message: "Successfully logged out", type: "success" });
          history.push(routes.LOGIN);
        }
      });
    }
    if (isMerchantStaff()) {
      dispatch(staffLogout()).then(({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({ message: payload.message, type: "success" });
          history.push(routes.LOGIN_STAFF);
        }
        if (meta.requestStatus === "rejected") {
          dispatch(resetUser());
          createSnackBar({ message: "Successfully logged out", type: "success" });
          history.push(routes.LOGIN_STAFF);
        }
      });
    }
  };

  return (
    <Box>
      <StyledAvatarButton onClick={handleOpenUserMenu}>
        {isMerchantStaff() ? (
          <Avatar
            sx={{ width: "40px", height: "40px" }}
            src={
              getStaffProfileDetailObj.status === "succeeded"
                ? getStaffProfileDetailObj.data.merchant.logo
                : null
            }
          />
        ) : (
          <Avatar
            sx={{ width: "40px", height: "40px" }}
            src={getProfileDetailObj.status === "succeeded" ? getProfileDetailObj.data.logo : null}
          />
        )}
      </StyledAvatarButton>
      <StyledMenu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={avatarMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(avatarMenu)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          key={1}
          onClick={() => {
            handleCloseUserMenu();
            history.push(routes.PROFILE_DETAIL);
          }}
        >
          <Typography textAlign="center">Account</Typography>
        </MenuItem>
        <MenuItem key={2} onClick={handleLogout}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </StyledMenu>
    </Box>
  );
}

export default DesktopAvatarMenu;
