import {
  Box,
  Button,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  businessSelector,
  getBusinessDetail,
  getStaffBusinessAccess,
  updateStaffBusinessAccess,
} from "../../../services/business/business.slice.services";
import StaffBusinessAccessTableRow from "../components/staff-business-access-table-row.component";
import StaffListAutoComplete from "../components/staff-list-autocomplete.component";
import StaffTableRowLoader from "../loader/staff-table-row-loader.component";
import TableSort from "../../../components/table/table-sort.component";

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const SearchContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.shape.borderRadius[2],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.sessionCardBg,
}));

const validationSchema = Yup.object().shape({
  staff: Yup.array().label("staff").nullable(),
});

export default function StaffBusinessAccessEditScreen() {
  const theme = useTheme();
  const formRef = useRef();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const location = useLocation();
  const history = useHistory();
  const businessId = new URLSearchParams(location.search).get("businessId");
  const { getBusinessDetailObj, getStaffBusinessAccessObj } = useSelector(businessSelector);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const columnMapping = {
    "Full Name": "staff.name",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshStaffBusinessAccessList = (newPage) => {
    setPage(newPage);
    dispatch(getStaffBusinessAccess({ businessId, page: newPage })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          setTotalPages(payload.data.pagination.totalPages);
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const onPageChange = (e, newPage) => {
    onRefreshStaffBusinessAccessList(newPage);
  };

  const onGrantAccess = (values) => {
    const staffsId = values.staff.map((item) => item.id);
    setIsLoading(true);
    dispatch(updateStaffBusinessAccess({ businessId, staffsId, access: true })).then(
      ({ meta, error }) => {
        setIsLoading(false);
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        } else if (meta.requestStatus === "fulfilled") {
          formRef.current.setFieldValue("staff", []);
          onRefreshStaffBusinessAccessList(1);
        }
      },
    );
  };

  const onRemoveAccess = (staffId) => {
    setIsLoading(true);
    dispatch(updateStaffBusinessAccess({ businessId, staffsId: [staffId], access: false })).then(
      ({ meta, error }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          onRefreshStaffBusinessAccessList(page);
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  useEffect(() => {
    onRefreshStaffBusinessAccessList(page);
    dispatch(getBusinessDetail(businessId)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const renderBusinessHeader = () => {
    if (getBusinessDetailObj.status === "succeeded") {
      return (
        <Text
          sx={{ fontSize: theme.fonts.fontSizes.size20, fontWeight: theme.fonts.fontWeights.bold }}
        >
          {getBusinessDetailObj.data.name}
        </Text>
      );
    }
    return null;
  };

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const getProcessedRecord = () => {
    let records = null;

    if (getStaffBusinessAccessObj.data && getStaffBusinessAccessObj.status === "succeeded") {
      records = { ...getStaffBusinessAccessObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = getColumnValue(a, sortColumn);
          const columnB = getColumnValue(b, sortColumn);

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (getStaffBusinessAccessObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (getStaffBusinessAccessObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={4}>
              <TableEmptyBox>
                <Text type="TableText">No staffs</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => (
        <StaffBusinessAccessTableRow
          staffAccess={item}
          key={item.id}
          onRemoveAccess={onRemoveAccess}
        />
      ));
    }
    return <StaffTableRowLoader />;
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        staff: [],
      }}
      onSubmit={onGrantAccess}
      validationSchema={validationSchema}
    >
      <Grid
        container
        spacing={3}
        sx={{
          paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
          paddingY: theme.dimensions.ScreenPaddingY,
        }}
      >
        <BackdropLoading isLoading={isLoading} />
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Text variant="screenLabel">Business Access Staff List</Text>
            <Button
              onClick={() => history.push(routes.STAFF_BUSINESS_ACCESS)}
              sx={{
                textTransform: "none",
                padding: "0px",
                ":hover": { backgroundColor: "transparent" },
              }}
            >
              <Text>Back</Text>
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <StaffListAutoComplete name="staff" placeholder="Enter staff name" isWhiteBg />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                  <Grid item xs={12} sm={2}>
                    <FormSubmitButton>
                      <Text type="WhiteColor">Grant Access</Text>
                    </FormSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SearchContainer>
        </Grid>

        <Grid item xs={12}>
          {renderBusinessHeader()}
        </Grid>

        <Grid item xs={12}>
          <TableWrapper>
            <Table>
              <TableHeader
                headerCells={["Full Name", "Access", "Action"]}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onSortChange={onSortChange}
                columnMapping={columnMapping}
                nonSortableColumns={["Access"]}
              />
              <TableBody>{renderTableBody()}</TableBody>
            </Table>
          </TableWrapper>
        </Grid>

        <Grid item xs={12}>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              shape="rounded"
              onChange={onPageChange}
              count={getStaffBusinessAccessObj?.data?.pagination.totalPages || totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </Grid>
      </Grid>
    </Formik>
  );
}
