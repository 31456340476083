import React from "react";
import PropTypes from "prop-types";
import Text from "../text.component";

function FormLabel({ children, disabled, bold }) {
  return (
    <Text type={disabled ? "Placeholder" : null} sx={{ fontWeight: bold ? "bold" : undefined }}>
      {children}
    </Text>
  );
}

FormLabel.defaultProps = {
  disabled: false,
  bold: false,
};

FormLabel.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  bold: PropTypes.bool,
};

export default FormLabel;
