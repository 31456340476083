import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment, Box, IconButton } from "@mui/material";
import { useFormikContext } from "formik";
import { useTheme } from "@mui/material/styles";
import { DebounceInput } from "react-debounce-input";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Spacer from "../spacer.component";

function FormFieldDebounceText({ placeholder, name, label }) {
  const theme = useTheme();
  const { setFieldValue, values, handleSubmit, resetForm } = useFormikContext();

  useEffect(() => {
    handleSubmit();
  }, [values[name]]);

  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderRadius[0],
          borderTopRightRadius: theme.shape.borderRadius[0],
          borderBottomLeftRadius: theme.shape.borderRadius[0],
          borderBottomRightRadius: theme.shape.borderRadius[0],
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderRadius[0],
            borderTopRightRadius: theme.shape.borderRadius[0],
            borderBottomLeftRadius: theme.shape.borderRadius[0],
            borderBottomRightRadius: theme.shape.borderRadius[0],
            backgroundColor: theme.palette.colors.bg.primary,
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
        },
      }}
    >
      <DebounceInput
        minLength={1}
        debounceTimeout={1000}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        element={TextField}
        value={values[name]}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
              <Spacer position="right" />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {values[name] && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      resetForm();
                      handleSubmit();
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
        placeholder={placeholder}
        label={label}
      />
    </Box>
  );
}

FormFieldDebounceText.defaultProps = {
  label: null,
};

FormFieldDebounceText.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default FormFieldDebounceText;
