import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import TableHeader from "../../../../components/table/table-header.component";
import Spacer from "../../../../components/spacer.component";

function StatementRow({ record }) {
  const [collapse, setCollapse] = useState(false);
  const theme = useTheme();

  const renderStatus = () => {
    if (record.status === "open") {
      return (
        <Typography
          variant="body2"
          sx={{ textTransform: "capitalize", color: theme.palette.colors.text.success }}
        >
          {record.status}
        </Typography>
      );
    }
    if (record.status === "pending") {
      return (
        <Typography
          variant="body2"
          sx={{ textTransform: "capitalize", color: theme.palette.colors.text.link }}
        >
          {record.status}
        </Typography>
      );
    }
    return (
      <Typography
        variant="body2"
        sx={{ textTransform: "capitalize", color: theme.palette.colors.text.purple }}
      >
        {record.status}
      </Typography>
    );
  };

  return (
    <>
      <TableRow
        key={record.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "0px",
          },
        }}
      >
        <TableCell width="150px" sx={{ textAlign: "center" }}>
          <Typography variant="body2">{record.id}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" sx={{ textTransform: "capitalize", textAlign: "center" }}>
            {record.staff.name}
          </Typography>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>{renderStatus()}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <Typography variant="body2">RM {record.amount}</Typography>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <Link
            to={`/staff/statement/detail?statementId=${record.id}`}
            style={{ textDecorationColor: theme.palette.colors.brand.primary }}
          >
            <Typography
              variant="body2"
              noWrap={true}
              sx={{
                color: theme.palette.colors.brand.primary,
              }}
            >
              View Detail
            </Typography>
          </Link>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setCollapse(!collapse)}>
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Spacer size="m" />
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography>Statement Details</Typography>
            </Box>
            <Spacer />
            <Table size="small">
              <TableHeader
                headerCells={[
                  "Transaction ID",
                  "Description / Commission",
                  "Date Created",
                  "Amount (RM)",
                ]}
                nonSortableColumns={[
                  "Transaction ID",
                  "Description / Commission",
                  "Date Created",
                  "Amount (RM)",
                ]}
              />
              <TableBody>
                {record.transactions.map((item, index) => {
                  if (index > 3) {
                    return (
                      <TableRow key={item.id}>
                        <TableCell colSpan={4}>
                          <Link
                            to={`/staff/statements/detail?statementId=${record.id}`}
                            style={{ textDecorationColor: theme.palette.colors.brand.primary }}
                          >
                            <Typography
                              variant="body2"
                              noWrap={true}
                              sx={{
                                textAlign: "center",
                                color: theme.palette.colors.brand.primary,
                              }}
                            >
                              View Detail for more transactions
                            </Typography>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return (
                    <TableRow key={item.id}>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="body2" noWrap={true}>
                          {item.id}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" noWrap={true} sx={{ width: "350px" }}>
                          Merchant Class Id: {item.itemId} #{item.itemTitle}
                          <br />
                          Sales: RM {item.salesAmount}
                          {record.commissionSetting.deductPlatformFee && (
                            <>
                              <br />
                              Platform: RM {item.platformFeeAmount}
                            </>
                          )}
                          <br />
                          Merchant membership: RM {item.membershipAmount}
                          {item.adjustmentAmount !== "0.00" && (
                            <>
                              <br />
                              Adjustment: {item.adjustmentAmount.includes("-") && "-"} RM{" "}
                              {item.adjustmentAmount.replace("-", "")}
                              <br />
                              Adjustment Remark: {item.adjustmentRemark ?? "-"}
                            </>
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="body2" noWrap={true}>
                          {item.createdAt}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography
                          variant="body2"
                          noWrap={true}
                          sx={{
                            color: item.amount.includes("-")
                              ? theme.palette.colors.text.error
                              : theme.palette.colors.text.success,
                          }}
                        >
                          {item.amount.includes("-") ? "-" : "+"}
                          {item.amount.replace("-", "")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Spacer size="m" />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

StatementRow.defaultProps = {
  record: {
    id: null,
    staff: {
      name: "",
    },
    status: "",
    amount: "",
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        id: null,
        itemTitle: "",
        itemId: null,
        amount: "",
        salesAmount: "",
        platformFeeAmount: "",
        membershipAmount: "",
        adjustmentAmount: "",
        adjustmentRemark: "",
        createdAt: "",
      }),
    ),
  },
};

StatementRow.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    staff: PropTypes.shape({
      name: PropTypes.string,
    }),
    commissionSetting: PropTypes.shape({
      deductPlatformFee: PropTypes.bool,
    }),
    status: PropTypes.string,
    amount: PropTypes.string,
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        itemTitle: PropTypes.string,
        itemId: PropTypes.number,
        amount: PropTypes.string,
        salesAmount: PropTypes.string,
        platformFeeAmount: PropTypes.string,
        membershipAmount: PropTypes.string,
        adjustmentAmount: PropTypes.string,
        adjustmentRemark: PropTypes.string,
        createdAt: PropTypes.string,
      }),
    ),
  }),
};

export default StatementRow;
