import { Grid, Typography } from "@mui/material";
import React from "react";

import SkeletonLoader from "../../../../components/notification/skeleton-loader.component";
import Spacer from "../../../../components/spacer.component";

function TransactionRowLoader() {
  return (
    <>
      <Grid item>
        <Typography>
          <SkeletonLoader />
        </Typography>
        <Spacer />
        <Typography>
          <SkeletonLoader width="150px" />
        </Typography>
      </Grid>
      <Grid item textAlign="right">
        <Typography>
          <SkeletonLoader />
        </Typography>
        <Spacer />
        <Typography>
          <SkeletonLoader />
        </Typography>
      </Grid>
    </>
  );
}

export default TransactionRowLoader;
