export const colors = {
  brand: {
    primary: "#138E5B",
    secondary: "#D4EDDA",
    linear: ["#206F68", "#1E8D5C"],
  },
  ui: {
    disabled: "#AAAAAA",
    white: "#FFFFFF",
    border: "#C2C2C2",
    lightBorder: "#E3E7EB",
    errorBox: "#DC3545",
    notification: "#FF2525",
    rejectedBox: "#ECABB1",
    redDot: "#E41313",
    greenDot: "#1CCD1B",
    blue: "#146CC8",
  },
  bg: {
    white: "#FFFFFF",
    primary: "#F4F4F4",
    secondary: "#FFFFFF",
    formContainerBg: "#FFFFFF",
    sessionCardBg: "#E3E7EB",
    grey: "#F0F3F9",
    dark: "#1F1F23",
  },
  text: {
    primary: "#000000",
    secondary: "#868D96",
    placeholder: "#868D96",
    table: "#6B6B6B",
    white: "#FFFFFF",
    disabled: "#AAAAAA",
    error: "#d32f2f",
    rejected: "#DC3545",
    yellow: "#F2BA00",
    success: "#138000",
    link: "#3874cb",
    purple: "#9C6BDE",
  },
  button: {
    idle: "#E3E7EB",
    disabled: "#7B828B",
  },
};
