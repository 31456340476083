import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CtaButton from "../../components/button/cta-button.component";
import RedCtaButton from "../../components/button/red-cta-button.component";
import { SnackbarContext } from "../../components/notification/snackbar.context";
import Spacer from "../../components/spacer.component";
import WarningIcon from "../../images/warning_icon.png";
import routes from "../../navigation/routes";
import {
  cancelTopUpBill,
  checkTopUpBill,
  creditSelector,
} from "../../services/credit/credit-slice.service";

const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "50px",
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fonts.fontWeights.bold,
  color: theme.palette.colors.text.primary,
  textAlign: "center",
}));

const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.secondary,
  textAlign: "center",
}));

export default function BillPaymentFailed() {
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { checkTopUpBillObj, cancelTopUpBillObj } = useSelector(creditSelector);

  const getCheckBillFunction = () => {
    switch (location.pathname) {
      case routes.TOPUPBILLPAYMENTFAILED:
        return checkTopUpBill;
      default:
        return false;
    }
  };

  const getCancelBillFunction = () => {
    switch (location.pathname) {
      case routes.TOPUPBILLPAYMENTFAILED:
        return cancelTopUpBill;
      default:
        return false;
    }
  };

  const getBillObj = () => {
    switch (location.pathname) {
      case routes.TOPUPBILLPAYMENTFAILED:
        return checkTopUpBillObj;
      default:
        return false;
    }
  };

  const getCancelBillObj = () => {
    switch (location.pathname) {
      case routes.TOPUPBILLPAYMENTFAILED:
        return cancelTopUpBillObj;
      default:
        return false;
    }
  };

  useEffect(() => {
    const checkBillFunction = getCheckBillFunction();
    dispatch(
      checkBillFunction({
        ...location.state,
      }),
    );
  }, []);

  const handleCancelBill = () => {
    const cancelBillFunction = getCancelBillFunction();
    const billObj = getBillObj();

    dispatch(
      cancelBillFunction({
        billId: billObj.data.bill.id,
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        history.push(routes.HOME);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <StyledContainer>
      <Spacer size="xxl" />
      <img src={WarningIcon} alt="warningIcon" width={170} />
      <Spacer size="xl" />
      <StyledTitle variant={isMobile ? "h4" : "h3"}>Payment Failed!</StyledTitle>
      <Spacer size="m" />
      <GreyText>
        Opps…Seems like something is wrong while the payment is processing. Please try again later.
      </GreyText>
      <Spacer size="xl" />
      <Grid container justifyContent="center">
        <Grid item>
          <CtaButton
            isLoading={getBillObj().status === "pending"}
            onClickButton={() => window.open(getBillObj().data.bill.remoteUrl, "_self")}
            width="220px"
          >
            Retry
          </CtaButton>
        </Grid>
      </Grid>
      <Spacer size="m" />
      <Grid container justifyContent="center">
        <Grid item>
          <RedCtaButton
            isLoading={getBillObj().status === "pending" || getCancelBillObj().status === "pending"}
            onClickButton={handleCancelBill}
            width="220px"
          >
            Cancel
          </RedCtaButton>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}
