import React, { useContext, useState } from "react";
import { Avatar, Box, Button, styled, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  profileSelector,
  updateProfileDetail,
} from "../../../services/profile/profile.slice.services";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { profileStaffSelector } from "../../../services/staff/profile/profile.slice.services";
import { isMerchantStaff } from "../../../infrastructure/utils";

const AvatarContainer = styled(Box)({
  padding: "30px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledAvatar = styled(Avatar)({
  width: "70px",
  height: "70px",
});

const StyledAddIcon = styled(AddCircleIcon)({
  position: "absolute",
  right: "0px",
  bottom: "0px",
  fontSize: "20px",
});

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const AvatarUploadButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
  "&:hover": {
    background: "none",
  },
}));
export default function ProfileAvatar({ inverse }) {
  const { getProfileDetailObj } = useSelector(profileSelector);
  const { getStaffProfileDetailObj } = useSelector(profileStaffSelector);
  const theme = useTheme();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const uploadImage = (imagePath) => {
    setIsLoading(true);
    dispatch(updateProfileDetail({ updateType: "imagePath", imagePath })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const renderContent = () => {
    if (
      getProfileDetailObj.status === "succeeded" ||
      getStaffProfileDetailObj.status === "succeeded"
    ) {
      return (
        <>
          <AvatarUploadButton component="label">
            <Box sx={{ position: "relative" }}>
              <StyledAvatar
                sx={{
                  backgroundColor: inverse
                    ? theme.palette.colors.brand.primary
                    : theme.palette.colors.ui.white,
                  color: inverse
                    ? theme.palette.colors.ui.white
                    : theme.palette.colors.brand.primary,
                }}
                src={
                  isMerchantStaff()
                    ? getStaffProfileDetailObj.data.imagePath
                    : getProfileDetailObj.data.imagePath
                }
              />

              <StyledAddIcon
                sx={{
                  color: inverse
                    ? theme.palette.colors.brand.primary
                    : theme.palette.colors.brand.secondary,
                }}
              />
            </Box>
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={(e) => {
                const { files } = e.target;
                if (files) {
                  const myFile = files[0]; // single file upload only
                  const allowedFileType = myFile && SUPPORTED_FORMATS.includes(myFile.type);
                  if (myFile) {
                    const fileReader = new FileReader(); // to preview the image
                    fileReader.onload = () => {
                      if (fileReader.readyState === 2) {
                        uploadImage(fileReader.result);
                      }
                    };
                    fileReader.readAsDataURL(myFile);
                  } else if (!allowedFileType) {
                    createSnackBar({
                      message: `Only ${SUPPORTED_FORMATS} are allowed`,
                      type: "error",
                    });
                  }
                }
              }}
            />
          </AvatarUploadButton>
          <Spacer size="m" position="left" />
          <Box>
            <Text
              type={inverse ? null : "WhiteColor"}
              sx={{
                fontWeight: theme.fonts.fontWeights.bold,
                fontSize: theme.fonts.fontSizes.size20,
              }}
            >
              {`Welcome, ${
                isMerchantStaff()
                  ? `${getStaffProfileDetailObj.data.firstName} ${getStaffProfileDetailObj.data.lastName}`
                  : getProfileDetailObj.data.businessName
              }!`}
            </Text>
            {!isMerchantStaff() && (
              <Text type={inverse ? null : "WhiteColor"} sx={{ fontSize: "10px" }}>
                {`Joined in ${getProfileDetailObj.data.createdAt}`}
              </Text>
            )}
          </Box>
        </>
      );
    }
    return (
      <>
        <StyledAvatar
          sx={{
            backgroundColor: inverse
              ? theme.palette.colors.brand.primary
              : theme.palette.colors.ui.white,
            color: inverse ? theme.palette.colors.ui.white : theme.palette.colors.brand.primary,
          }}
        />
        <Spacer size="m" position="left" />
        <Box>
          <Text
            type="WhiteColor"
            sx={{
              fontWeight: theme.fonts.fontWeights.bold,
              fontSize: theme.fonts.fontSizes.size20,
            }}
          >
            Welcome!
          </Text>
        </Box>
      </>
    );
  };

  return (
    <AvatarContainer
      sx={{
        justifyContent: inverse ? "center" : null,
        background: inverse
          ? "transparent"
          : `linear-gradient(to bottom, ${theme.palette.colors.brand.linear[0]}, ${theme.palette.colors.brand.linear[1]})`,
      }}
    >
      <BackdropLoading isLoading={isLoading} />
      {renderContent()}
    </AvatarContainer>
  );
}

ProfileAvatar.defaultProps = {
  inverse: false,
};

ProfileAvatar.propTypes = {
  inverse: PropTypes.bool,
};
