import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, patchReq, postReq } from "../../api-services";

export const getStaffStatementList = createAsyncThunk(
  "merchants/staffs/statements/list",
  async (payload) => {
    const { staffsId, statementId, status, page, paidStartDate, paidEndDate } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/statements?${
        page ? "page=".concat(page) : "page=1"
      }${staffsId.length ? "&staffsId=".concat(staffsId) : ""}${
        statementId ? "&statementId=".concat(statementId) : ""
      }${status ? "&status=".concat(status) : ""}${
        paidStartDate ? "&paidStartDate=".concat(paidStartDate) : ""
      }${paidEndDate ? "&paidEndDate=".concat(paidEndDate) : ""}`,
    );
    return response;
  },
);

export const getStaffStatementDetail = createAsyncThunk(
  "merchants/staffs/statements/detail",
  async (statementId) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/statements/${statementId}`,
    );
    return response;
  },
);

export const closeStaffStatement = createAsyncThunk(
  "merchants/staffs/statements/close",
  async (payload) => {
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/statements/${payload.statementId}/close`,
      payload,
    );
    return response;
  },
);

export const getStaffStatementTransactionList = createAsyncThunk(
  "merchants/staffs/statements/transactions",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/statements/${payload.statementId}/transactions?page=${payload.page}`,
    );
    return response;
  },
);

export const adjustTransaction = createAsyncThunk(
  "merchants/staffs/statements/transactions/adjust",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/statements/${payload.statementId}/transactions/${payload.transactionId}/adjustments`,
      payload,
    );
    return response;
  },
);

export const sendEmail = createAsyncThunk("merchants/staffs/statements/email", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/staffs/statements/${payload.statementId}/email`,
    payload,
  );
  return response;
});

const staffStatementSlice = createSlice({
  name: "staffStatement",
  initialState: {
    reduxStoreStatementDetails: {},
    getStaffStatementListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getStaffStatementDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    closeStaffStatementObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getStaffStatementTransactionListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    adjustTransactionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    sendEmailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {
    setReduxStoreStatementDetails: (state, action) => {
      const { payload } = action;
      state.reduxStoreStatementDetails = payload;
    },
  },
  extraReducers: {
    [getStaffStatementList.pending]: (state) => {
      state.getStaffStatementListObj.status = "pending";
    },
    [getStaffStatementList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStaffStatementListObj.status = "succeeded";
      state.getStaffStatementListObj.data = data;
      state.getStaffStatementListObj.successMessage = message;
    },
    [getStaffStatementList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStaffStatementListObj.status = "failed";
      state.getStaffStatementListObj.errorMessage = message;
    },
    [getStaffStatementDetail.pending]: (state) => {
      state.getStaffStatementDetailObj.status = "pending";
    },
    [getStaffStatementDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStaffStatementDetailObj.status = "succeeded";
      state.getStaffStatementDetailObj.data = data;
      state.getStaffStatementDetailObj.successMessage = message;
    },
    [getStaffStatementDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStaffStatementDetailObj.status = "failed";
      state.getStaffStatementDetailObj.errorMessage = message;
    },
    [closeStaffStatement.pending]: (state) => {
      state.closeStaffStatementObj.status = "pending";
    },
    [closeStaffStatement.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.closeStaffStatementObj.status = "succeeded";
      state.closeStaffStatementObj.data = data;
      state.closeStaffStatementObj.successMessage = message;

      state.getStaffStatementDetailObj.data = data;
    },
    [closeStaffStatement.rejected]: (state, action) => {
      const { message } = action.error;

      state.closeStaffStatementObj.status = "failed";
      state.closeStaffStatementObj.errorMessage = message;
    },
    [getStaffStatementTransactionList.pending]: (state) => {
      state.getStaffStatementTransactionListObj.status = "pending";
    },
    [getStaffStatementTransactionList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStaffStatementTransactionListObj.status = "succeeded";
      state.getStaffStatementTransactionListObj.data = data;
      state.getStaffStatementTransactionListObj.successMessage = message;
    },
    [getStaffStatementTransactionList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStaffStatementTransactionListObj.status = "failed";
      state.getStaffStatementTransactionListObj.errorMessage = message;
    },
    [adjustTransaction.pending]: (state) => {
      state.adjustTransactionObj.status = "pending";
    },
    [adjustTransaction.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.adjustTransactionObj.status = "succeeded";
      state.adjustTransactionObj.data = data.transaction;
      state.getStaffStatementDetailObj.data = data.statement;
      state.adjustTransactionObj.successMessage = message;
    },
    [adjustTransaction.rejected]: (state, action) => {
      const { message } = action.error;

      state.adjustTransactionObj.status = "failed";
      state.adjustTransactionObj.errorMessage = message;
    },
    [sendEmail.pending]: (state) => {
      state.sendEmailObj.status = "pending";
    },
    [sendEmail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.sendEmailObj.status = "succeeded";
      state.sendEmailObj.data = data;
      state.sendEmailObj.successMessage = message;
    },
    [sendEmail.rejected]: (state, action) => {
      const { message } = action.error;

      state.sendEmailObj.status = "failed";
      state.sendEmailObj.errorMessage = message;
    },
  },
});

export default staffStatementSlice.reducer;

// state
export const staffStatementSelector = (state) => state.staffStatement;

export const { setReduxStoreStatementDetails } = staffStatementSlice.actions;
