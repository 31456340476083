import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getCommissionSettingList = createAsyncThunk(
  "merchants/commission_settings/index",
  async (payload) => {
    const { staffIds, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/commission_settings?${
        staffIds ? "&staffIds=".concat(staffIds) : ""
      }&page=${page}`,
    );
    return response;
  },
);

export const createCommissionSetting = createAsyncThunk(
  "merchants/commission_settings/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/commission_settings`,
      payload,
    );
    return response;
  },
);

export const deleteCommissionSetting = createAsyncThunk(
  "merchants/commission_settings/delete/{id}",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/commission_settings/${payload.id}`,
    );
    return response;
  },
);

export const updateCommissionSetting = createAsyncThunk(
  "merchants/commission_settings/update/{id}",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/commission_settings/${payload.id}`,
      payload,
    );
    return response;
  },
);

const commissionSettingSlice = createSlice({
  name: "commissionSetting",
  initialState: {
    getCommissionSettingListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createCommissionSettingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteCommissionSettingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateCommissionSettingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getCommissionSettingList.pending]: (state) => {
      state.getCommissionSettingListObj.status = "pending";
    },
    [getCommissionSettingList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCommissionSettingListObj.status = "succeeded";
      state.getCommissionSettingListObj.data = data;
      state.getCommissionSettingListObj.successMessage = message;
    },
    [getCommissionSettingList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCommissionSettingListObj.status = "failed";
      state.getCommissionSettingListObj.errorMessage = message;
    },
    [createCommissionSetting.pending]: (state) => {
      state.createCommissionSettingObj.status = "pending";
    },
    [createCommissionSetting.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createCommissionSettingObj.status = "succeeded";
      state.createCommissionSettingObj.data = data;
      state.createCommissionSettingObj.successMessage = message;
    },
    [createCommissionSetting.rejected]: (state, action) => {
      const { message } = action.error;

      state.createCommissionSettingObj.status = "failed";
      state.createCommissionSettingObj.errorMessage = message;
    },
    [deleteCommissionSetting.pending]: (state) => {
      state.deleteCommissionSettingObj.status = "pending";
    },
    [deleteCommissionSetting.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteCommissionSettingObj.status = "succeeded";
      state.deleteCommissionSettingObj.data = data;
      state.deleteCommissionSettingObj.successMessage = message;
    },
    [deleteCommissionSetting.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteCommissionSettingObj.status = "failed";
      state.deleteCommissionSettingObj.errorMessage = message;
    },
    [updateCommissionSetting.pending]: (state) => {
      state.updateCommissionSettingObj.status = "pending";
    },
    [updateCommissionSetting.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateCommissionSettingObj.status = "succeeded";
      state.updateCommissionSettingObj.data = data;
      state.updateCommissionSettingObj.successMessage = message;
    },
    [updateCommissionSetting.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateCommissionSettingObj.status = "failed";
      state.updateCommissionSettingObj.errorMessage = message;
    },
  },
});

export default commissionSettingSlice.reducer;

// state
export const commissionSettingSelector = (state) => state.commissionSetting;
