import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import TransactionHistory from "../../components/credit/transaction-history.component";
import MerchantCredits from "../../components/credit/merchant-credits.component";
import Loading from "../../../../components/notification/backdrop-loading.component";
import Spacer from "../../../../components/spacer.component";

export default function CreditScreen() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isLoading, setIsLoading] = useState(false);
  return (
    <Box width="100%" marginTop="20px" paddingX={isMobile ? "20px" : ""}>
      <Loading isLoading={isLoading} />
      <MerchantCredits isLoading={isLoading} setIsLoading={setIsLoading} />
      <Spacer position="top" size="l" />
      <TransactionHistory />
    </Box>
  );
}
