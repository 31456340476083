import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, styled, useTheme } from "@mui/material";
import RuleIcon from "@mui/icons-material/Rule";
import { useHistory } from "react-router-dom";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";

const BusinessContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
  overflow: "hidden",
  backgroundColor: theme.palette.colors.bg.secondary,
  padding: "15px",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.colors.ui.lightBorder,
  color: theme.palette.colors.text.secondary,
}));

const StatusContainer = styled(Box)(({ theme }) => ({
  height: "30px",
  paddingLeft: "10px",
  paddingRight: "10px",
  borderTopLeftRadius: theme.shape.borderRadius[0],
  borderTopRightRadius: theme.shape.borderRadius[0],
  borderBottomLeftRadius: theme.shape.borderRadius[0],
  borderBottomRightRadius: theme.shape.borderRadius[0],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ImageContainer = styled(Box)(() => ({
  height: "180px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function BusinessItem({ business }) {
  const theme = useTheme();
  const history = useHistory();

  const renderStatus = () => {
    if (business.status === "draft") {
      return (
        <StatusContainer sx={{ backgroundColor: theme.palette.colors.ui.lightBorder }}>
          <Text variant="body2" type="GreyColor">
            In Progress
          </Text>
        </StatusContainer>
      );
    }
    if (business.status === "publish") {
      return (
        <StatusContainer sx={{ backgroundColor: theme.palette.colors.brand.secondary }}>
          <Text variant="body2" type="BrandColor">
            Published
          </Text>
        </StatusContainer>
      );
    }
    if (business.status === "rejected_admin") {
      return (
        <StatusContainer sx={{ backgroundColor: theme.palette.colors.ui.rejectedBox }}>
          <Text variant="body2" sx={{ color: theme.palette.colors.text.rejected }}>
            Rejected
          </Text>
        </StatusContainer>
      );
    }

    return null;
  };

  return (
    <BusinessContainer sx={{ boxShadow: 2 }}>
      <ImageContainer>
        <img
          alt="businessImage"
          src={business.images[0].imagePath}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: theme.shape.borderRadius[1],
          }}
        />
      </ImageContainer>

      <Spacer size="xs" position="top" />
      <Text
        sx={{
          fontSize: theme.fonts.fontSizes.size16,
          fontWeight: theme.fonts.fontWeights.bold,
          textAlign: "start",
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          height: "48px",
        }}
      >
        {business.name}
      </Text>
      <Spacer size="s" position="top" />
      <Text
        variant="body2"
        type="GreyColor"
        sx={{
          lineHeight: theme.fonts.fontSizes.size14,
          textAlign: "start",
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
          height: "42px",
        }}
      >
        {business.location}
      </Text>
      <Spacer size="s" position="top" />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
        {renderStatus()}
        <StyledIconButton
          disabled={!business.remotePremiseId}
          onClick={() =>
            history.push({
              pathname: routes.STAFF_BUSINESS_ACCESS_EDIT,
              search: `businessId=${business.id}`,
            })
          }
        >
          <RuleIcon />
        </StyledIconButton>
      </Box>
    </BusinessContainer>
  );
}

BusinessItem.propTypes = {
  business: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    remotePremiseId: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    locationLat: PropTypes.string,
    locationLong: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postcode: PropTypes.string,
    contactNo: PropTypes.string,
    status: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        imagePath: PropTypes.string,
      }),
    ),
  }).isRequired,
};
