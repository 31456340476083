import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, patchReq, postReq, putReq } from "../../api-services";

export const getMembershipPlanList = createAsyncThunk(
  "merchants/memberships/plans/index",
  async (payload) => {
    const { q, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/memberships/plans?${
        q ? "&q=".concat(q) : ""
      }&page=${page}`,
    );
    return response;
  },
);

export const createMembershipPlan = createAsyncThunk(
  "merchants/memberships/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/memberships/plans`,
      payload,
    );
    return response;
  },
);

export const deleteMembershipPlan = createAsyncThunk(
  "merchants/memberships/plans/delete/{plan_id}",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/memberships/plans/${payload.planId}`,
    );
    return response;
  },
);

export const getMembershipPlan = createAsyncThunk(
  "merchants/memberships/plans/get/{plan_id}",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/memberships/plans/${payload.planId}`,
    );
    return response;
  },
);

export const updateMembershipPlan = createAsyncThunk(
  "merchants/memberships/plans/update/{plan_id}",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/memberships/plans/${payload.planId}`,
      payload,
    );
    return response;
  },
);

export const updateMembershipPlanStatus = createAsyncThunk(
  "merchants/memberships/plans/update/{plan_id}/status",
  async (payload) => {
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/memberships/plans/${payload.planId}/status`,
      payload,
    );
    return response;
  },
);

export const updateMembershipPlanImage = createAsyncThunk(
  "merchants/memberships/plans/update/{plan_id}/image",
  async (payload) => {
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/memberships/plans/${payload.planId}/image`,
      payload,
    );
    return response;
  },
);

const membershipPlanSlice = createSlice({
  name: "membershipPlan",
  initialState: {
    getMembershipPlanListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createMembershipPlanObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteMembershipPlanObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getMembershipPlanObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateMembershipPlanObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateMembershipPlanStatusObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateMembershipPlanImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getMembershipPlanList.pending]: (state) => {
      state.getMembershipPlanListObj.status = "pending";
    },
    [getMembershipPlanList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMembershipPlanListObj.status = "succeeded";
      state.getMembershipPlanListObj.data = data;
      state.getMembershipPlanListObj.successMessage = message;
    },
    [getMembershipPlanList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMembershipPlanListObj.status = "failed";
      state.getMembershipPlanListObj.errorMessage = message;
    },
    [createMembershipPlan.pending]: (state) => {
      state.createMembershipPlanObj.status = "pending";
    },
    [createMembershipPlan.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createMembershipPlanObj.status = "succeeded";
      state.createMembershipPlanObj.data = data;
      state.createMembershipPlanObj.successMessage = message;
    },
    [createMembershipPlan.rejected]: (state, action) => {
      const { message } = action.error;

      state.createMembershipPlanObj.status = "failed";
      state.createMembershipPlanObj.errorMessage = message;
    },
    [deleteMembershipPlan.pending]: (state) => {
      state.deleteMembershipPlanObj.status = "pending";
    },
    [deleteMembershipPlan.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteMembershipPlanObj.status = "succeeded";
      state.deleteMembershipPlanObj.data = data;
      state.deleteMembershipPlanObj.successMessage = message;
    },
    [deleteMembershipPlan.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteMembershipPlanObj.status = "failed";
      state.deleteMembershipPlanObj.errorMessage = message;
    },
    [getMembershipPlan.pending]: (state) => {
      state.getMembershipPlanObj.status = "pending";
    },
    [getMembershipPlan.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getMembershipPlanObj.status = "succeeded";
      state.getMembershipPlanObj.data = data;
      state.getMembershipPlanObj.successMessage = message;
    },
    [getMembershipPlan.rejected]: (state, action) => {
      const { message } = action.error;

      state.getMembershipPlanObj.status = "failed";
      state.getMembershipPlanObj.errorMessage = message;
    },
    [updateMembershipPlan.pending]: (state) => {
      state.updateMembershipPlanObj.status = "pending";
    },
    [updateMembershipPlan.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateMembershipPlanObj.status = "succeeded";
      state.updateMembershipPlanObj.data = data;
      state.updateMembershipPlanObj.successMessage = message;
    },
    [updateMembershipPlan.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateMembershipPlanObj.status = "failed";
      state.updateMembershipPlanObj.errorMessage = message;
    },
    [updateMembershipPlanStatus.pending]: (state) => {
      state.updateMembershipPlanStatusObj.status = "pending";
    },
    [updateMembershipPlanStatus.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateMembershipPlanStatusObj.status = "succeeded";
      state.updateMembershipPlanStatusObj.data = data;
      state.updateMembershipPlanStatusObj.successMessage = message;
    },
    [updateMembershipPlanStatus.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateMembershipPlanStatusObj.status = "failed";
      state.updateMembershipPlanStatusObj.errorMessage = message;
    },
    [updateMembershipPlanImage.pending]: (state) => {
      state.updateMembershipPlanImageObj.status = "pending";
    },
    [updateMembershipPlanImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateMembershipPlanImageObj.status = "succeeded";
      state.updateMembershipPlanImageObj.data = data;
      state.updateMembershipPlanImageObj.successMessage = message;
    },
    [updateMembershipPlanImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateMembershipPlanImageObj.status = "failed";
      state.updateMembershipPlanImageObj.errorMessage = message;
    },
  },
});

export default membershipPlanSlice.reducer;

// state
export const membershipPlanSelector = (state) => state.membershipPlan;
