import { Box, Button, Grid } from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  staffSelector,
  updateStaff,
  uploadStaffImage,
} from "../../../services/staff/staff-slice.service";
import StaffDetailLoader from "../loader/staff-detail-loader.component";
import StaffAvatar from "./staff-avatar.component";
import StaffDetailEditForm from "./staff-detail-edit-form.component";
import { updateStaffPermission } from "../../../services/staff/permission/permission.slice.services";

const firstNameValidationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  type: Yup.string().required(),
});

const lastNameValidationSchema = Yup.object().shape({
  lastName: Yup.string().required().label("First Name"),
  type: Yup.string().required(),
});

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email"),
  type: Yup.string().required(),
});

const genderValidationSchema = Yup.object().shape({
  gender: Yup.string().nullable().required().label("Gender"),
  type: Yup.string().required(),
});

const dobValidationSchema = Yup.object().shape({
  dob: Yup.date().nullable().required().label("Date of birth"),
  type: Yup.string().required(),
});

const permissionValidationSchema = Yup.object().shape({
  permissions: Yup.array().nullable().required().label("Permission"),
});

const imageValidationSchema = Yup.object().shape({
  lastName: Yup.string().label("Image"),
});

const phoneValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .required()
    .label("Phone number")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{8}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid.",
    ),
  type: Yup.string().required(),
});

export default function StaffDetail() {
  const { getStaffObj } = useSelector(staffSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const location = useLocation();
  const staffId = new URLSearchParams(location.search).get("staffId");
  const [isLoading, setIsLoading] = useState(false);
  const [firstNameEditing, setFirstNameEditing] = useState(false);
  const [lastNameEditing, setLastNameEditing] = useState(false);
  const [phoneEditing, setPhoneEditing] = useState(false);
  const [emailEditing, setEmailEditing] = useState(false);
  const [genderEditing, setGenderEditing] = useState(false);
  const [dobEditing, setDobEditing] = useState(false);
  const [permissionEditing, setPermissionEditing] = useState(false);
  const imageFormRef = useRef();
  const history = useHistory();

  const onUpdateDetail = (values) => {
    setIsLoading(true);
    dispatch(updateStaff({ staffId, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        if (values.type === "firstName") {
          setFirstNameEditing(false);
        } else if (values.type === "lastName") {
          setLastNameEditing(false);
        } else if (values.type === "phone") {
          setPhoneEditing(false);
        } else if (values.type === "email") {
          setEmailEditing(false);
        } else if (values.type === "gender") {
          setGenderEditing(false);
        } else if (values.type === "dob") {
          setDobEditing(false);
        }
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onUpdatePermission = (values) => {
    setIsLoading(true);
    // console.log({ staffId, ...values });
    dispatch(updateStaffPermission({ staffId, ...values })).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        setPermissionEditing(false);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onUploadImage = (values, { resetForm }) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("photo", values.photo);
    formData.staffId = staffId;

    dispatch(uploadStaffImage(formData)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        resetForm();
        imageFormRef.current.setFieldError("photo", error.message);
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const renderContent = () => {
    if (getStaffObj.status === "succeeded") {
      return (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text variant="screenLabel">Staff Details</Text>
            <Button
              onClick={() => history.push(routes.STAFF_LIST)}
              sx={{
                textTransform: "none",
                padding: "0px",
                ":hover": { backgroundColor: "transparent" },
              }}
            >
              <Text>Back</Text>
            </Button>
          </Box>

          <Grid container sx={{ paddingY: "20px" }} spacing={3}>
            <Grid item xs={12}>
              <Formik
                innerRef={imageFormRef}
                initialValues={{
                  photo: getStaffObj.data.imagePath || "",
                }}
                validationSchema={imageValidationSchema}
                onSubmit={onUploadImage}
              >
                <StaffAvatar inverse name="photo" />
              </Formik>
            </Grid>
            <Grid item xs={12}>
              <Form
                initialValues={{
                  type: "firstName",
                  firstName: getStaffObj.data.firstName,
                }}
                validationSchema={firstNameValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <StaffDetailEditForm
                  name="firstName"
                  placeholder="Enter first name"
                  label="First Name"
                  isEditing={firstNameEditing}
                  setIsEditing={setFirstNameEditing}
                />
              </Form>
            </Grid>

            <Grid item xs={12}>
              <Form
                initialValues={{
                  type: "lastName",
                  lastName: getStaffObj.data.lastName,
                }}
                validationSchema={lastNameValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <StaffDetailEditForm
                  name="lastName"
                  placeholder="Enter last name"
                  label="Last Name"
                  isEditing={lastNameEditing}
                  setIsEditing={setLastNameEditing}
                />
              </Form>
            </Grid>

            <Grid item xs={12}>
              <Form
                initialValues={{
                  type: "phone",
                  phone: getStaffObj.data.phone,
                }}
                validationSchema={phoneValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <StaffDetailEditForm
                  name="phone"
                  placeholder="Enter contact number"
                  label="Contact Number"
                  isEditing={phoneEditing}
                  setIsEditing={setPhoneEditing}
                />
              </Form>
            </Grid>

            <Grid item xs={12}>
              <Form
                initialValues={{
                  type: "email",
                  email: getStaffObj.data.email,
                }}
                validationSchema={emailValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <StaffDetailEditForm
                  name="email"
                  placeholder="Enter email"
                  label="Email"
                  isEditing={emailEditing}
                  setIsEditing={setEmailEditing}
                />
              </Form>
            </Grid>

            <Grid item xs={12}>
              <Form
                initialValues={{
                  type: "gender",
                  gender: getStaffObj.data.gender === null ? "" : getStaffObj.data.gender,
                }}
                validationSchema={genderValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <StaffDetailEditForm
                  name="gender"
                  placeholder="Select gender"
                  label="Gender"
                  isEditing={genderEditing}
                  setIsEditing={setGenderEditing}
                />
              </Form>
            </Grid>

            <Grid item xs={12}>
              <Form
                initialValues={{
                  type: "dob",
                  dob: getStaffObj.data.dob,
                }}
                validationSchema={dobValidationSchema}
                onSubmit={onUpdateDetail}
              >
                <StaffDetailEditForm
                  name="dob"
                  placeholder="Select date"
                  label="Date of birth"
                  isEditing={dobEditing}
                  setIsEditing={setDobEditing}
                />
              </Form>
            </Grid>

            <Grid item xs={12}>
              <Form
                initialValues={{
                  permissions: getStaffObj.data.permissions,
                }}
                validationSchema={permissionValidationSchema}
                onSubmit={onUpdatePermission}
              >
                <StaffDetailEditForm
                  name="permissions"
                  placeholder="Select permissions"
                  label="Permission"
                  isEditing={permissionEditing}
                  setIsEditing={setPermissionEditing}
                />
              </Form>
            </Grid>
          </Grid>
        </>
      );
    }
    return <StaffDetailLoader />;
  };

  return (
    <Grid item xs={12}>
      <BackdropLoading isLoading={isLoading} />
      {renderContent()}
    </Grid>
  );
}
