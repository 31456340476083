import InfoIcon from "@mui/icons-material/Info";
import { Box, Grid, Modal, styled, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import CtaBorderButton from "../../../components/button/cta-border-button.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormPrice from "../../../components/forms/form-price.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTimePicker from "../../../components/forms/form-time-picker.component";
import Spacer from "../../../components/spacer.component";
import Text from "../../../components/text.component";
import { eventSessionCategorySelector } from "../../../services/event/session/category/category.slice.services";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.secondary,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
  maxHeight: "90%",
  overflowY: "scroll",
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  color: theme.palette.colors.brand.primary,
}));

const CenteredRowBox = styled(Box)({ display: "flex", flexDirection: "row", alignItems: "center" });

export default function SessionCategoryModal({
  showModal,
  setShowModal,
  sessionInfo,
  setSessionInfo,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { resetForm } = useFormikContext();
  const { createEventSessionCategoryObj } = useSelector(eventSessionCategorySelector);

  const handleCancel = () => {
    resetForm();
    setSessionInfo(null);
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "50px",
          width: isMobile ? "350px" : "80%",
          maxWidth: "700px",
          overflow: "visible",
        }}
      >
        <Grid container columnSpacing={3} rowSpacing={1}>
          <Grid item xs={12}>
            <Spacer />
            <Text variant="screenLabel">Session Category</Text>
          </Grid>

          <Grid item xs={12}>
            <Spacer />
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Label</Text>
            <Spacer />
            <FormFieldText placeholder="Enter label" name="label" />
          </Grid>

          <Grid item xs={isMobile ? 12 : 6}>
            <Spacer />
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Start Time</Text>
            <Spacer />
            <FormTimePicker
              name="startTime"
              minTime={sessionInfo.startAt}
              maxTime={sessionInfo.endAt}
            />
          </Grid>

          <Grid item xs={isMobile ? 12 : 6}>
            <Spacer />
            <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>End Time</Text>
            <Spacer />
            <FormTimePicker
              name="endTime"
              minTime={sessionInfo.startAt}
              maxTime={sessionInfo.endAt}
            />
          </Grid>

          <Grid item container xs={12} columnSpacing={3} rowSpacing={1}>
            <Grid item xs={isMobile ? 12 : 6}>
              <Spacer />
              <CenteredRowBox>
                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Capacity</Text>
                <Spacer position="left" size="s" />
                <Tooltip title="0 stands for unlimited capacity" arrow>
                  <StyledInfoIcon />
                </Tooltip>
              </CenteredRowBox>
              <Spacer />
              <FormFieldText placeholder="Enter capacity" name="maxCapacity" type="number" />
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <Spacer />
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
              <Spacer />
              <FormPrice placeholder="00.00" name="price" />
            </Grid>

            <Grid item xs={isMobile ? 12 : 6}>
              <Spacer />
              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Membership Price</Text>
              <Spacer />
              <FormPrice placeholder="00.00" name="membershipPrice" />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Spacer />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <CtaBorderButton
                onClickButton={handleCancel}
                width="100px"
                isLoading={createEventSessionCategoryObj.status === "pending"}
              >
                <Text type="BrandColor">Cancel</Text>
              </CtaBorderButton>
              <Spacer size="m" position="left" />
              <FormSubmitButton
                width="100px"
                isLoading={createEventSessionCategoryObj.status === "pending"}
              >
                <Text type="WhiteColor">Save</Text>
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

SessionCategoryModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  sessionInfo: PropTypes.shape({
    id: PropTypes.number,
    price: PropTypes.string,
    title: PropTypes.string,
    currentCapacity: PropTypes.number,
    maxCapacity: PropTypes.number,
    status: PropTypes.string,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    event: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    attendees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
        user: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          phone: PropTypes.string,
        }),
      }),
    ),
    multipleCategories: PropTypes.bool,
    sessionCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        currentCapacity: PropTypes.number,
        maxCapacity: PropTypes.number,
        unlimited: PropTypes.number,
        price: PropTypes.string,
        startAt: PropTypes.string,
        endAt: PropTypes.string,
        createdAt: PropTypes.string,
      }),
    ),
  }).isRequired,
  setSessionInfo: PropTypes.func.isRequired,
};
