import { Box, Grid, Modal, styled, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import FormFieldTextWithCustomisedAdornments from "../../../../components/forms/form-field-text-with-customised-adornments.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Spacer from "../../../../components/spacer.component";
import TopTitleBox from "../../../../components/top-title-box.component";
import UserListCreatePhoneNumberSingleSelect from "../../../booking/components/user-list-create-phone-number-single-select.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: theme.shape.borderRadius[2],
  boxShadow: 24,
  padding: "20px",
  maxWidth: "550px",
  width: "90%",
  outline: "none",
}));

function TransferModal({ showModal, setShowModal }) {
  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox>
        <TopTitleBox title="Transfer" requireCloseButton={true} setShowModal={setShowModal} />
        <Spacer position="top" size="l" />
        <UserListCreatePhoneNumberSingleSelect
          name="userPhoneNumber"
          placeholder="Search user by phone number (e.g. 60123456789)"
        />
        <Spacer position="top" size="l" />
        <Grid item>
          <Typography>Transfer Amount (RM)</Typography>
          <Spacer position="top" size="s" />
          <FormFieldTextWithCustomisedAdornments
            name="amount"
            placeholder="Amount"
            icon={<Typography>RM</Typography>}
          />
        </Grid>
        <Grid item margin="30px" xs={12} textAlign="center">
          <FormSubmitButton width="60%" borderRadius={1}>
            <Typography>Confirm</Typography>
          </FormSubmitButton>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

TransferModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default TransferModal;
