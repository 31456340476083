import React, { useState } from "react";
import { Avatar, Box, Button, FormHelperText, styled } from "@mui/material";
import PropTypes from "prop-types";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { staffSelector } from "../../../services/staff/staff-slice.service";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: "90px",
  height: "90px",
  backgroundColor: theme.palette.colors.brand.primary,
  color: theme.palette.colors.ui.white,
}));

const StyledAddIcon = styled(AddCircleIcon)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
  position: "absolute",
  right: "0px",
  bottom: "0px",
  fontSize: "25px",
}));

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const AvatarUploadButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  borderTopLeftRadius: theme.shape.borderRadius[1],
  borderTopRightRadius: theme.shape.borderRadius[1],
  borderBottomLeftRadius: theme.shape.borderRadius[1],
  borderBottomRightRadius: theme.shape.borderRadius[1],
  "&:hover": {
    background: "none",
  },
}));
export default function StaffAvatar({ name }) {
  const { values, setFieldValue, submitForm, errors, setFieldError } = useFormikContext();
  const [previewImage, setPreviewImage] = useState(values[name]);
  const { getStaffObj } = useSelector(staffSelector);

  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AvatarUploadButton component="label">
        <Box sx={{ position: "relative" }}>
          <StyledAvatar src={previewImage} />
          <StyledAddIcon />
        </Box>
        <input
          onClick={(event) => {
            event.target.value = null;
          }}
          type="file"
          hidden
          accept="image/*"
          onChange={(e) => {
            const { files } = e.target;
            if (files) {
              const myFile = files[0]; // single file upload only
              setFieldValue(name, myFile);
              const allowedFileType = myFile && SUPPORTED_FORMATS.includes(myFile.type);
              if (myFile) {
                const fileReader = new FileReader(); // to preview the image
                fileReader.onload = () => {
                  if (fileReader.readyState === 2) {
                    setFieldError(name, "");
                    setPreviewImage(fileReader.result);
                    // setFieldValue(name, fileReader.result);
                    setTimeout(() => {
                      submitForm();
                    }, 50);
                  }
                };
                fileReader.readAsDataURL(myFile);
              } else if (!allowedFileType) {
                setFieldError(name, `Only ${SUPPORTED_FORMATS} are allowed`);
              }
            }
          }}
        />
      </AvatarUploadButton>
      {errors[name] && (
        <FormHelperText error sx={{ textAlign: "center" }}>
          {errors[name]}
        </FormHelperText>
      )}
      {getStaffObj.status === "succeeded" && getStaffObj.data.rejectReason && (
        <FormHelperText error sx={{ textAlign: "center" }}>
          {getStaffObj.data.rejectReason}
        </FormHelperText>
      )}
    </Box>
  );
}

StaffAvatar.propTypes = {
  name: PropTypes.string.isRequired,
};
