import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api-services";

export const getUsers = createAsyncThunk("merchants/users/index", async (payload) => {
  const { q, page } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/users?${
      q ? "&q=".concat(q) : ""
    }&page=${page}`,
  );
  return response;
});

export const createUser = createAsyncThunk(
  "merchants/users/create",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postReq(
        `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/users`,
        payload,
      );
      return response;
    } catch (err) {
      // Return the error response from the backend if available
      if (err.errors) {
        return rejectWithValue(err.errors);
      }
      throw err; // Throw other errors
    }
  },
);

export const searchUserPhoneNumber = createAsyncThunk(
  "merchants/users/search_with_phone_number",
  async (payload) => {
    const { phone } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/users/search_with_phone_number?${
        phone ? "phone=".concat(phone) : ""
      }`,
    );
    return response;
  },
);

export const sendUserList = createAsyncThunk("merchants/users/sendUserList", async () => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/users/sendUserList`,
  );
  return response;
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    getUsersObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createUserObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchUserPhoneNumberObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    sendUserListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.getUsersObj.status = "pending";
    },
    [getUsers.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUsersObj.status = "succeeded";
      state.getUsersObj.data = data;
      state.getUsersObj.successMessage = message;
    },
    [getUsers.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUsersObj.status = "failed";
      state.getUsersObj.errorMessage = message;
    },
    [createUser.pending]: (state) => {
      state.createUserObj.status = "pending";
    },
    [createUser.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createUserObj.status = "succeeded";
      state.createUserObj.data = data;
      state.createUserObj.successMessage = message;
    },
    [createUser.rejected]: (state, action) => {
      const { message } = action.error;

      state.createUserObj.status = "failed";
      state.createUserObj.errorMessage = message;
    },
    [searchUserPhoneNumber.pending]: (state) => {
      state.searchUserPhoneNumberObj.status = "pending";
    },
    [searchUserPhoneNumber.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchUserPhoneNumberObj.status = "succeeded";
      state.searchUserPhoneNumberObj.data = data;
      state.searchUserPhoneNumberObj.successMessage = message;
    },
    [searchUserPhoneNumber.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchUserPhoneNumberObj.status = "failed";
      state.searchUserPhoneNumberObj.errorMessage = message;
    },
    [sendUserList.pending]: (state) => {
      state.sendUserListObj.status = "pending";
    },
    [sendUserList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.sendUserListObj.status = "succeeded";
      state.sendUserListObj.data = data;
      state.sendUserListObj.successMessage = message;
    },
    [sendUserList.rejected]: (state, action) => {
      const { message } = action.error;

      state.sendUserListObj.status = "failed";
      state.sendUserListObj.errorMessage = message;
    },
  },
});

export default userSlice.reducer;

// state
export const userSelector = (state) => state.user;
