import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, postReq } from "../api-services";

export const getCurrentStatement = createAsyncThunk("/merchants/current_statement", async () => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/statements/attention`,
  );
  return response;
});

export const getClosedStatement = createAsyncThunk(
  "/merchants/closed_statement",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/statements/closed?page=${payload.page}`,
    );
    return response;
  },
);

export const getStatementDetail = createAsyncThunk(
  "/merchants/statement_detail",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/statements/${payload.statementId}?page=${
        payload.page
      }${payload.businessId ? "&businessId=".concat(payload.businessId) : ""}`,
    );
    return response;
  },
);

export const sendStatementEmail = createAsyncThunk(
  "/merchants/send_statement_email",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/statements/email`,
      payload,
    );
    return response;
  },
);

const statementSlice = createSlice({
  name: "statement",
  initialState: {
    getCurrentStatementObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getClosedStatementObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getStatementDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    sendStatementEmailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getCurrentStatement.pending]: (state) => {
      state.getCurrentStatementObj.status = "pending";
    },
    [getCurrentStatement.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getCurrentStatementObj.status = "succeeded";
      state.getCurrentStatementObj.data = data;
      state.getCurrentStatementObj.successMessage = message;
    },
    [getCurrentStatement.rejected]: (state, action) => {
      const { message } = action.error;

      state.getCurrentStatementObj.status = "failed";
      state.getCurrentStatementObj.errorMessage = message;
    },
    [getClosedStatement.pending]: (state) => {
      state.getClosedStatementObj.status = "pending";
    },
    [getClosedStatement.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getClosedStatementObj.status = "succeeded";
      state.getClosedStatementObj.data = data;
      state.getClosedStatementObj.successMessage = message;
    },
    [getClosedStatement.rejected]: (state, action) => {
      const { message } = action.error;

      state.getClosedStatementObj.status = "failed";
      state.getClosedStatementObj.errorMessage = message;
    },
    [getStatementDetail.pending]: (state) => {
      state.getStatementDetailObj.status = "pending";
    },
    [getStatementDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStatementDetailObj.status = "succeeded";
      state.getStatementDetailObj.data = data;
      state.getStatementDetailObj.successMessage = message;
    },
    [getStatementDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStatementDetailObj.status = "failed";
      state.getStatementDetailObj.errorMessage = message;
    },
    [sendStatementEmail.pending]: (state) => {
      state.sendStatementEmailObj.status = "pending";
    },
    [sendStatementEmail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.sendStatementEmailObj.status = "succeeded";
      state.sendStatementEmailObj.data = data;
      state.sendStatementEmailObj.successMessage = message;
    },
    [sendStatementEmail.rejected]: (state, action) => {
      const { message } = action.error;

      state.sendStatementEmailObj.status = "failed";
      state.sendStatementEmailObj.errorMessage = message;
    },
  },
});

export default statementSlice.reducer;

// state
export const statementSelector = (state) => state.statement;
