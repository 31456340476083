import { TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Text from "../../../components/text.component";

export default function MembershipTableRow({ membership }) {
  return (
    <>
      <TableRow
        key={membership.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            type="TableText"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
          >
            {membership.user.name}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {membership.user.phone}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {membership.plan.identifier}
          </Text>
        </TableCell>
        <TableCell sx={{ width: "150px" }}>
          <Text variant="body2" type="TableText" sx={{ textAlign: "center" }}>
            {`RM ${membership.price}`}
          </Text>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Text
            variant="body2"
            sx={{ textAlign: "center", textTransform: "capitalize" }}
            type={membership.status === "active" ? "BrandColor" : "YellowColor"}
          >
            {membership.status.replace("_", " ")}
          </Text>
        </TableCell>
      </TableRow>
    </>
  );
}

MembershipTableRow.propTypes = {
  membership: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    user: PropTypes.shape({
      name: PropTypes.string,
      phone: PropTypes.string,
    }),
    plan: PropTypes.shape({
      identifier: PropTypes.string,
    }),
    price: PropTypes.string,
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
};
