import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Spacer from "../../../../components/spacer.component";
import CtaButton from "../../../../components/button/cta-button.component";

const ModalBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.ui.white,
  borderRadius: theme.shape.borderRadius[0],
  boxShadow: 24,
  outline: "none",
}));

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.colors.brand.secondary,
}));

export default function ClosePendingConfirmationModal({
  showModal,
  setShowModal,
  onConfirmClicked,
  isLoading,
  type,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox
        sx={{
          padding: isMobile ? "30px" : "40px",
          width: isMobile ? "350px" : "500px",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
              <CloseIconButton onClick={handleCancel}>
                <CloseIcon sx={{ stroke: "black", strokeWidth: 2 }} />
              </CloseIconButton>
            </Box>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {capitalize(type)} Statement Confirmation
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Spacer size="xl" position="top" />
            {type === "closed" ? (
              <Typography sx={{ textAlign: "center" }}>
                Are you sure to close this statement?
              </Typography>
            ) : (
              <Typography sx={{ textAlign: "center" }}>
                Are you sure to pend this statement?
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Spacer size="l" position="top" />
          </Grid>
          <Grid item xs={12}>
            <Spacer size="xxl" position="top" />
            <CtaButton disabled={isLoading} onClickButton={onConfirmClicked}>
              Confirm
            </CtaButton>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

ClosePendingConfirmationModal.propTypes = {
  onConfirmClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
