import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Grid,
  IconButton,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import DeleteConfirmationModal from "../../../components/notification/delete-confirmation-modal.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/table/table-header.component";
import TableSort from "../../../components/table/table-sort.component";
import TableWrapper from "../../../components/table/table-wrapper.component";
import Text from "../../../components/text.component";
import {
  commissionSettingSelector,
  createCommissionSetting,
  deleteCommissionSetting,
  getCommissionSettingList,
  updateCommissionSetting,
} from "../../../services/commission-setting/commission-setting-slice.service";
import CommissionSettingFormModal from "../components/commission-setting-form-modal.component";
import RecordTableRow from "../components/record-table-row.component";
import StaffListAutoComplete from "../components/staff-list-autocomplete.component";
import RecordTableRowLoader from "../loader/record-table-row-loader.component";

const createValidationSchema = Yup.object().shape({
  staff: Yup.mixed().required().label("Staff"),
  type: Yup.string().required().label("Commission Type"),
  deductPlatformFee: Yup.boolean().required().label("Deduct Platform Fee"),
  rate: Yup.number()
    .min(1)
    .required()
    .label("Rate")
    .when("type", {
      is: "profit_share",
      then: Yup.number().min(1).max(100).required().label("Rate"),
    }),
});

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

export default function CommissionSettingListScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const [isLoading, setIsLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [page, setPage] = useState(1);
  const { getCommissionSettingListObj, deleteCommissionSettingObj } =
    useSelector(commissionSettingSelector);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCommissionSetting, setSelectedCommissionSetting] = useState(null);
  const columnMapping = {
    "Full Name": "firstName",
    Email: "email",
    Phone: "phone",
    "Fitness Classes Conducted": "summary.totalFitnessClassesConducted",
    "Fitness Sessions Conducted": "summary.totalFitnessSessionConducted",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);
  const listFormRef = useRef();

  const onRefreshList = (newPage, staffs) => {
    setPage(newPage);
    dispatch(
      getCommissionSettingList({
        page: newPage,
        staffIds: staffs.map((item) => item.id).join(","),
      }),
    ).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        setTotalPages(payload.data.pagination.totalPages);
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onCreateCommissionSetting = (values, { resetForm }) => {
    setIsLoading(true);
    values.staffId = values.staff.id;
    dispatch(createCommissionSetting(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        setShowAddModal(false);
        onRefreshList(1, []);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onUpdateCommissionSetting = (values, { resetForm }) => {
    setIsLoading(true);
    dispatch(updateCommissionSetting(values)).then(({ meta, error, payload }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        resetForm();
        setShowEditModal(false);
        onRefreshList(1, []);
        createSnackBar({
          message: payload.message,
          type: "success",
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  const onPageChange = (e, newPage) => {
    onRefreshList(newPage, listFormRef.current.values.staffs);
  };

  const onHandleDelete = (item) => {
    setSelectedCommissionSetting(item);
    setShowDeleteModal(true);
  };

  const onHandleEdit = (item) => {
    setSelectedCommissionSetting(item);
    setShowEditModal(true);
  };

  const onConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteCommissionSetting({ id: selectedCommissionSetting.id })).then(
      ({ meta, error, payload }) => {
        setIsLoading(false);
        if (meta.requestStatus === "fulfilled") {
          setShowDeleteModal(false);
          onRefreshList(page, listFormRef.current.values.staffs);
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        } else if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
          });
        }
      },
    );
  };

  const clearFilter = () => {
    listFormRef.current.setFieldValue("staffs", []);
    onRefreshList(1, []);
  };

  useEffect(() => {
    onRefreshList(page, []);
  }, []);

  useEffect(() => {
    if (!showEditModal && !showDeleteModal) {
      setSelectedCommissionSetting(null);
    }
  }, [showEditModal]);

  const getColumnValue = (item, column) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };
  const getProcessedRecord = () => {
    let records = null;

    if (getCommissionSettingListObj.data && getCommissionSettingListObj.status === "succeeded") {
      records = { ...getCommissionSettingListObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = getColumnValue(a, sortColumn);
          const columnB = getColumnValue(b, sortColumn);

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    if (getCommissionSettingListObj.status === "succeeded") {
      const records = getProcessedRecord();
      if (getCommissionSettingListObj.data.items.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={12}>
              <TableEmptyBox>
                <Text type="TableText">No items</Text>
              </TableEmptyBox>
            </TableCell>
          </TableRow>
        );
      }
      return records?.items.map((item) => (
        <RecordTableRow
          record={item}
          key={item.id}
          onHandleDelete={onHandleDelete}
          onHandleEdit={onHandleEdit}
        />
      ));
    }
    return <RecordTableRowLoader />;
  };

  return (
    <Box width="100%">
      <Grid
        item
        xs={12}
        sx={{
          paddingX: isSmallScreen ? theme.dimensions.MobilePadding : theme.dimensions.PCPadding,
          paddingY: theme.dimensions.ScreenPaddingY,
        }}
      >
        <DeleteConfirmationModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          title="Remove Commission Setting"
          label={`Are you sure you wish to remove ${selectedCommissionSetting?.staff.email}'s commission setting?`}
          isLoading={deleteCommissionSettingObj.status === "pending"}
          onConfirmClicked={onConfirmDelete}
        />
        <Form
          validationSchema={createValidationSchema}
          onSubmit={onCreateCommissionSetting}
          initialValues={{
            staff: null,
            type: "",
            deductPlatformFee: true,
            rate: 0,
          }}
        >
          <CommissionSettingFormModal
            title="Add Commission Setting"
            showModal={showAddModal}
            setShowModal={setShowAddModal}
          />
        </Form>
        {selectedCommissionSetting && (
          <Form
            validationSchema={createValidationSchema}
            onSubmit={onUpdateCommissionSetting}
            initialValues={{
              id: selectedCommissionSetting.id,
              staff: selectedCommissionSetting.staff,
              type: selectedCommissionSetting.type,
              deductPlatformFee: selectedCommissionSetting.deductPlatformFee,
              rate: selectedCommissionSetting.rate,
            }}
          >
            <CommissionSettingFormModal
              title="Update Commission Setting"
              showModal={showEditModal}
              setShowModal={setShowEditModal}
            />
          </Form>
        )}

        <BackdropLoading isLoading={isLoading} />
        <Grid item xs={12} sx={{ marginBottom: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Text variant="screenLabel">Staff Commission List</Text>
            <IconButton onClick={() => setShowAddModal(true)}>
              <AddCircleIcon sx={{ color: theme.palette.colors.brand.primary }} />
            </IconButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: theme.palette.colors.bg.sessionCardBg,
            padding: "20px",
            borderRadius: `${theme.shape.borderRadius[2]}px`,
            marginBottom: "20px",
          }}
        >
          <Form
            innerRef={listFormRef}
            onSubmit={(values) => onRefreshList(1, values.staffs)}
            validationSchema={Yup.object().shape({
              staffs: Yup.mixed().nullable(),
            })}
            initialValues={{
              staffs: [],
            }}
          >
            <Grid item container spacing={3}>
              <Grid item xs={12}>
                <StaffListAutoComplete name="staffs" placeholder="Search Staff" />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                  <Grid item xs={12} sm={2}>
                    <FormSubmitButton>
                      <Text type="WhiteColor">Search</Text>
                    </FormSubmitButton>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <CtaButton whiteBg onClickButton={clearFilter}>
                      <Text type="BrandColor">Clear All</Text>
                    </CtaButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
        <Grid item xs={12}>
          <TableWrapper>
            <Table>
              <TableHeader
                headerCells={[
                  "Staff Name",
                  "Staff Email",
                  "Type",
                  "Deduct Platform Fee",
                  "Rate",
                  "Future Settings",
                  "Scheduled Delete At",
                  "Action",
                ]}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onSortChange={onSortChange}
                columnMapping={columnMapping}
              />
              <TableBody>{renderTableBody()}</TableBody>
            </Table>
          </TableWrapper>
        </Grid>
        <Grid item xs={12}>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: theme.palette.colors.brand.primary,
                  color: theme.palette.colors.text.white,
                },
              }}
              page={page}
              shape="rounded"
              onChange={onPageChange}
              count={getCommissionSettingListObj?.data?.pagination.totalPages || totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </Grid>
      </Grid>
    </Box>
  );
}
