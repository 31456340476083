import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../../../api-services";

export const createEventSessionCategory = createAsyncThunk(
  "merchants/events/session/category/create",
  async (payload) => {
    const { sessionId, startAt, endAt, maxCapacity, amountCents, membershipAmountCents, label } =
      payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/sessions/categories`,
      { sessionId, startAt, endAt, maxCapacity, amountCents, membershipAmountCents, label },
    );
    return response;
  },
);

export const updateEventSessionCategory = createAsyncThunk(
  "merchants/events/sessions/categories/update/{session_id}",
  async (payload) => {
    const {
      sessionCategoryId,
      startAt,
      endAt,
      maxCapacity,
      amountCents,
      membershipAmountCents,
      label,
    } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/sessions/categories/${sessionCategoryId}`,
      { startAt, endAt, maxCapacity, amountCents, membershipAmountCents, label },
    );
    return response;
  },
);

export const deleteEventSessionCategory = createAsyncThunk(
  "merchants/events/sessions/categories/delete/{session_id}",
  async (payload) => {
    const { sessionCategoryId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/sessions/categories/${sessionCategoryId}`,
    );
    return response;
  },
);

export const getEventSessionCategory = createAsyncThunk(
  "merchants/events/session/category/index",
  async (payload) => {
    const { q, page } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/events/sessions/categories?page=${page}${
        q ? "&q=".concat(q) : ""
      }`,
    );
    return response;
  },
);

const eventSessionCategorySlice = createSlice({
  name: "eventSessionCategory",
  initialState: {
    createEventSessionCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateEventSessionCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteEventSessionCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getEventSessionCategoryObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [createEventSessionCategory.pending]: (state) => {
      state.createEventSessionCategoryObj.status = "pending";
    },
    [createEventSessionCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createEventSessionCategoryObj.status = "succeeded";
      state.createEventSessionCategoryObj.data = data;
      state.createEventSessionCategoryObj.successMessage = message;
    },
    [createEventSessionCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.createEventSessionCategoryObj.status = "failed";
      state.createEventSessionCategoryObj.errorMessage = message;
    },
    [updateEventSessionCategory.pending]: (state) => {
      state.updateEventSessionCategoryObj.status = "pending";
    },
    [updateEventSessionCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateEventSessionCategoryObj.status = "succeeded";
      state.updateEventSessionCategoryObj.data = data;
      state.updateEventSessionCategoryObj.successMessage = message;
    },
    [updateEventSessionCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateEventSessionCategoryObj.status = "failed";
      state.updateEventSessionCategoryObj.errorMessage = message;
    },
    [deleteEventSessionCategory.pending]: (state) => {
      state.deleteEventSessionCategoryObj.status = "pending";
    },
    [deleteEventSessionCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteEventSessionCategoryObj.status = "succeeded";
      state.deleteEventSessionCategoryObj.data = data;
      state.deleteEventSessionCategoryObj.successMessage = message;
    },
    [deleteEventSessionCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteEventSessionCategoryObj.status = "failed";
      state.deleteEventSessionCategoryObj.errorMessage = message;
    },
    [getEventSessionCategory.pending]: (state) => {
      state.getEventSessionCategoryObj.status = "pending";
    },
    [getEventSessionCategory.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getEventSessionCategoryObj.status = "succeeded";
      state.getEventSessionCategoryObj.data = data;
      state.getEventSessionCategoryObj.successMessage = message;
    },
    [getEventSessionCategory.rejected]: (state, action) => {
      const { message } = action.error;

      state.getEventSessionCategoryObj.status = "failed";
      state.getEventSessionCategoryObj.errorMessage = message;
    },
  },
});

export default eventSessionCategorySlice.reducer;

// state
export const eventSessionCategorySelector = (state) => state.eventSessionCategory;
