import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getBusinessFullList = createAsyncThunk("merchants/business/full_list", async (q) => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/auto_complete?q=${q}`,
  );
  return response;
});

export const createBusiness = createAsyncThunk("merchants/business/create", async (payload) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses`,
    payload,
  );
  return response;
});

export const searchBusiness = createAsyncThunk("merchants/business/search", async (payload) => {
  const { search, states, statuses, page } = payload;

  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses?${
      search ? "&q=".concat(search) : ""
    }${states ? "&states=".concat(states) : ""}${statuses ? "&statuses=".concat(statuses) : ""}${
      page ? "&page=".concat(page) : ""
    }
    `,
  );
  return response;
});

export const getBusinessAutoCompleteSuggestion = createAsyncThunk(
  "merchants/business/autocomplete",
  async (payload) => {
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/auto_complete?q=${
        payload.keyword
      }&page=${payload.page}${
        payload.withoutMembership ? "&withoutMembership=".concat(payload.withoutMembership) : ""
      }`,
    );
    return response;
  },
);

export const getBusinessDetail = createAsyncThunk(
  "merchants/business/detail",
  async (businessId) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/${businessId}`,
    );
    return response;
  },
);

export const updateBusinessDetail = createAsyncThunk(
  "merchants/business/detail/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/${payload.businessId}`,
      payload,
    );
    return response;
  },
);

export const uploadBusinessImage = createAsyncThunk(
  "merchants/business/image/upload",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/${payload.businessId}/images`,
      payload,
    );
    return response;
  },
);

export const removeBusinessImage = createAsyncThunk(
  "merchants/business/image/remove",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/${payload.businessId}/images/${payload.imageId}`,
      payload,
    );
    return response;
  },
);

export const getBusinessAmenities = createAsyncThunk(
  "merchants/business/amenity/index",
  async () => {
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/amenities`,
    );
    return response;
  },
);

export const updateStaffBusinessAccess = createAsyncThunk(
  "merchants/business/staff_accesses/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/${payload.businessId}/staff_accesses`,
      payload,
    );
    return response;
  },
);

export const getStaffBusinessAccess = createAsyncThunk(
  "merchants/business/staff_accesses/index",
  async (payload) => {
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/${payload.businessId}/staff_accesses?page=${payload.page}`,
    );
    return response;
  },
);

export const getUserBusinessAccess = createAsyncThunk(
  "merchants/business/user_accesses/index",
  async (payload) => {
    const response = getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/${payload.businessId}/user_accesses?page=${payload.page}`,
    );
    return response;
  },
);

export const updateUserBusinessAccess = createAsyncThunk(
  "merchants/business/user_accesses/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/businesses/${payload.businessId}/user_accesses`,
      payload,
    );
    return response;
  },
);

const businessSlice = createSlice({
  name: "business",
  initialState: {
    getBusinessFullListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createBusinessObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    searchBusinessObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBusinessAutoCompleteSuggestionObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },

    getBusinessDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateBusinessDetailObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    uploadBusinessImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    removeBusinessImageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getBusinessAmenitiesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateStaffBusinessAccessObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getStaffBusinessAccessObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getUserBusinessAccessObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateUserBusinessAccessObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [createBusiness.pending]: (state) => {
      state.createBusinessObj.status = "pending";
    },
    [createBusiness.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createBusinessObj.status = "succeeded";
      state.createBusinessObj.data = data;
      state.createBusinessObj.successMessage = message;
    },
    [createBusiness.rejected]: (state, action) => {
      const { message } = action.error;

      state.createBusinessObj.status = "failed";
      state.createBusinessObj.errorMessage = message;
    },
    [searchBusiness.pending]: (state) => {
      state.searchBusinessObj.status = "pending";
    },
    [searchBusiness.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.searchBusinessObj.status = "succeeded";
      state.searchBusinessObj.data = data;
      state.searchBusinessObj.successMessage = message;
    },
    [searchBusiness.rejected]: (state, action) => {
      const { message } = action.error;

      state.searchBusinessObj.status = "failed";
      state.searchBusinessObj.errorMessage = message;
    },
    [getBusinessAutoCompleteSuggestion.pending]: (state) => {
      state.getBusinessAutoCompleteSuggestionObj.status = "pending";
    },
    [getBusinessAutoCompleteSuggestion.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBusinessAutoCompleteSuggestionObj.status = "succeeded";
      state.getBusinessAutoCompleteSuggestionObj.data = data;
      state.getBusinessAutoCompleteSuggestionObj.successMessage = message;
    },
    [getBusinessAutoCompleteSuggestion.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBusinessAutoCompleteSuggestionObj.status = "failed";
      state.getBusinessAutoCompleteSuggestionObj.errorMessage = message;
    },
    [getBusinessDetail.pending]: (state) => {
      state.getBusinessDetailObj.status = "pending";
    },
    [getBusinessDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBusinessDetailObj.status = "succeeded";
      state.getBusinessDetailObj.data = data;
      state.getBusinessDetailObj.successMessage = message;
    },
    [getBusinessDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBusinessDetailObj.status = "failed";
      state.getBusinessDetailObj.errorMessage = message;
    },
    [updateBusinessDetail.pending]: (state) => {
      state.updateBusinessDetailObj.status = "pending";
    },
    [updateBusinessDetail.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateBusinessDetailObj.status = "succeeded";
      state.updateBusinessDetailObj.data = data;
      state.updateBusinessDetailObj.successMessage = message;

      state.getBusinessDetailObj.data = data;
    },
    [updateBusinessDetail.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateBusinessDetailObj.status = "failed";
      state.updateBusinessDetailObj.errorMessage = message;
    },
    [uploadBusinessImage.pending]: (state) => {
      state.uploadBusinessImageObj.status = "pending";
    },
    [uploadBusinessImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.uploadBusinessImageObj.status = "succeeded";
      state.uploadBusinessImageObj.data = data;
      state.uploadBusinessImageObj.successMessage = message;

      state.getBusinessDetailObj.data = data;
    },
    [uploadBusinessImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.uploadBusinessImageObj.status = "failed";
      state.uploadBusinessImageObj.errorMessage = message;
    },
    [removeBusinessImage.pending]: (state) => {
      state.removeBusinessImageObj.status = "pending";
    },
    [removeBusinessImage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.removeBusinessImageObj.status = "succeeded";
      state.removeBusinessImageObj.data = data;
      state.removeBusinessImageObj.successMessage = message;

      state.getBusinessDetailObj.data = data;
    },
    [removeBusinessImage.rejected]: (state, action) => {
      const { message } = action.error;

      state.removeBusinessImageObj.status = "failed";
      state.removeBusinessImageObj.errorMessage = message;
    },
    [getBusinessFullList.pending]: (state) => {
      state.getBusinessFullListObj.status = "pending";
    },
    [getBusinessFullList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBusinessFullListObj.status = "succeeded";
      state.getBusinessFullListObj.data = data;
      state.getBusinessFullListObj.successMessage = message;
    },
    [getBusinessFullList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBusinessFullListObj.status = "failed";
      state.getBusinessFullListObj.errorMessage = message;
    },
    [getBusinessAmenities.pending]: (state) => {
      state.getBusinessAmenitiesObj.status = "pending";
    },
    [getBusinessAmenities.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getBusinessAmenitiesObj.status = "succeeded";
      state.getBusinessAmenitiesObj.data = data;
      state.getBusinessAmenitiesObj.successMessage = message;
    },
    [getBusinessAmenities.rejected]: (state, action) => {
      const { message } = action.error;

      state.getBusinessAmenitiesObj.status = "failed";
      state.getBusinessAmenitiesObj.errorMessage = message;
    },
    [updateStaffBusinessAccess.pending]: (state) => {
      state.updateStaffBusinessAccessObj.status = "pending";
    },
    [updateStaffBusinessAccess.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateStaffBusinessAccessObj.status = "succeeded";
      state.updateStaffBusinessAccessObj.data = data;
      state.updateStaffBusinessAccessObj.successMessage = message;
    },
    [updateStaffBusinessAccess.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateStaffBusinessAccessObj.status = "failed";
      state.updateStaffBusinessAccessObj.errorMessage = message;
    },
    [getStaffBusinessAccess.pending]: (state) => {
      state.getStaffBusinessAccessObj.status = "pending";
    },
    [getStaffBusinessAccess.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getStaffBusinessAccessObj.status = "succeeded";
      state.getStaffBusinessAccessObj.data = data;
      state.getStaffBusinessAccessObj.successMessage = message;
    },
    [getStaffBusinessAccess.rejected]: (state, action) => {
      const { message } = action.error;

      state.getStaffBusinessAccessObj.status = "failed";
      state.getStaffBusinessAccessObj.errorMessage = message;
    },
    [getUserBusinessAccess.pending]: (state) => {
      state.getUserBusinessAccessObj.status = "pending";
    },
    [getUserBusinessAccess.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getUserBusinessAccessObj.status = "succeeded";
      state.getUserBusinessAccessObj.data = data;
      state.getUserBusinessAccessObj.successMessage = message;
    },
    [getUserBusinessAccess.rejected]: (state, action) => {
      const { message } = action.error;

      state.getUserBusinessAccessObj.status = "failed";
      state.getUserBusinessAccessObj.errorMessage = message;
    },
    [updateUserBusinessAccess.pending]: (state) => {
      state.updateUserBusinessAccessObj.status = "pending";
    },
    [updateUserBusinessAccess.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateUserBusinessAccessObj.status = "succeeded";
      state.updateUserBusinessAccessObj.data = data;
      state.updateUserBusinessAccessObj.successMessage = message;
    },
    [updateUserBusinessAccess.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateUserBusinessAccessObj.status = "failed";
      state.updateUserBusinessAccessObj.errorMessage = message;
    },
  },
});

export default businessSlice.reducer;

// state
export const businessSelector = (state) => state.business;
