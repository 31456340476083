import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, FormHelperText, Grid, IconButton, Typography, styled } from "@mui/material";
import { FieldArray, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import CtaButton from "../../../components/button/cta-button.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import theme from "../../../infrastructure/theme";
import { userSelector } from "../../../services/user/user-slice.service";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  items: Yup.array(
    Yup.object()
      .shape({
        firstName: Yup.string().required().label("First Name"),
        lastName: Yup.string().required().label("Last Name"),
        email: Yup.string().required().nullable().email().label("Email"),
        phone: Yup.number().required().label("Phone"),
      })
      .required("Must have at least 1"),
  )
    .min(1)
    .label("Users"),
});

export default function CreateUserBulk({ onCreate }) {
  const { createUserObj } = useSelector(userSelector);

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onCreate}
      initialValues={{
        items: [
          {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          },
        ],
      }}
    >
      {({ values, errors }) => (
        <FieldArray name="items">
          {({ push, remove }) => (
            <>
              <Grid item xs={12} marginY="20px">
                <SpaceBetweenBox>
                  <Typography variant="h5" fontWeight="bold">
                    Users
                  </Typography>
                  <Box>
                    <CtaButton
                      onClickButton={() =>
                        push({
                          firstName: "",
                          lastName: "",
                          email: "",
                          phone: "",
                        })
                      }
                    >
                      Add
                    </CtaButton>
                  </Box>
                </SpaceBetweenBox>
              </Grid>

              {values.items.map((user, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid container item key={index} marginBottom="10px">
                  <Grid
                    item
                    container
                    xs={11}
                    rowSpacing={0.8}
                    bgcolor="colors.bg.primary"
                    margin="1px"
                    padding="15px 10px"
                    borderRadius={`${theme.shape.borderRadius[2]}px`}
                  >
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <FormFieldText
                          name={`items[${index}].firstName`}
                          placeholder="First Name"
                        />

                        {errors.items && (
                          <FormHelperText error sx={{ marginLeft: "10px" }}>
                            {errors?.items[index]?.firstName}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <FormFieldText name={`items[${index}].lastName`} placeholder="Last Name" />

                        {errors.items && (
                          <FormHelperText error sx={{ marginLeft: "10px" }}>
                            {errors?.items[index]?.lastName}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <FormFieldText name={`items[${index}].email`} placeholder="Email" />

                        {errors.items && (
                          <FormHelperText error sx={{ marginLeft: "10px" }}>
                            {errors?.items[index]?.email}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                        <FormFieldText name={`items[${index}].phone`} placeholder="Phone" />

                        {errors.items && (
                          <FormHelperText error sx={{ marginLeft: "10px" }}>
                            {errors?.items[index]?.phone}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item xs={0.1} sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <RemoveCircleIcon sx={{ color: theme.palette.colors.text.error }} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}

              <Grid item xs={12} paddingTop="50px">
                <FormSubmitButton isLoading={createUserObj.status === "pending"}>
                  Create
                </FormSubmitButton>
              </Grid>
            </>
          )}
        </FieldArray>
      )}
    </Formik>
  );
}

CreateUserBulk.propTypes = {
  onCreate: PropTypes.func.isRequired,
};
