import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import InputIcon from "@mui/icons-material/Input";
import { Grid, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Form from "../../../../components/forms/form.component";
import PaddedView from "../../../../components/padded-view.component";
import {
  createTopUpBill,
  getCreditTransactions,
  transferToUser,
} from "../../../../services/credit/credit-slice.service";
import TopUpModal from "./top-up-modal.component";
import { SnackbarContext } from "../../../../components/notification/snackbar.context";
import CtaButton from "../../../../components/button/cta-button.component";
import {
  getProfileDetail,
  profileSelector,
} from "../../../../services/profile/profile.slice.services";
import TransferModal from "./transfer-modal.component";
import { isMerchantStaff } from "../../../../infrastructure/utils";
import {
  getStaffProfileDetail,
  profileStaffSelector,
} from "../../../../services/staff/profile/profile.slice.services";

export default function MerchantCredits({ setIsLoading }) {
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getProfileDetailObj } = useSelector(profileSelector);
  const { getStaffProfileDetailObj } = useSelector(profileStaffSelector);

  const handleTopUp = (values) => {
    setShowModal(false);
    setIsLoading(true);
    const amountCents = values.amount * 100;
    const paymentMethod = "revenue_monster";
    dispatch(createTopUpBill({ amountCents, paymentMethod, platform: "web" })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
          setIsLoading(false);
        }
        if (meta.requestStatus === "fulfilled") {
          window.open(payload.data.bill.remoteUrl, "_self");
        }
      },
    );
  };

  const handleTransfer = (values) => {
    setShowTransferModal(false);
    setIsLoading(true);
    const amountCents = values.amount * 100;
    dispatch(transferToUser({ userPhoneNumber: values.userPhoneNumber?.phone, amountCents })).then(
      ({ meta, error, payload }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
          setIsLoading(false);
        }
        if (meta.requestStatus === "fulfilled") {
          dispatch(getCreditTransactions({ type: "all", page: 1 }));
          if (!isMerchantStaff()) {
            dispatch(getProfileDetail());
          }

          if (isMerchantStaff()) {
            dispatch(getStaffProfileDetail());
          }
          createSnackBar({
            message: payload.message,
            type: "success",
            open: true,
          });
          setIsLoading(false);
        }
      },
    );
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.colors.bg.dark,
        borderRadius: `${theme.shape.borderRadius[1]}px`,
      }}
    >
      <PaddedView multiples={2}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Typography
              sx={{ color: theme.palette.colors.text.white }}
              fontSize={theme.fonts.fontSizes.size13}
            >
              Your Credits
            </Typography>
            {!isMerchantStaff() && (
              <Typography
                fontWeight="bold"
                sx={{ color: theme.palette.colors.text.white }}
                fontSize={theme.fonts.fontSizes.size20}
              >
                {getProfileDetailObj.status === "succeeded"
                  ? `RM ${getProfileDetailObj.data.creditBalance}`
                  : "RM 0.00"}
              </Typography>
            )}
            {isMerchantStaff() && (
              <Typography
                fontWeight="bold"
                sx={{ color: theme.palette.colors.text.white }}
                fontSize={theme.fonts.fontSizes.size20}
              >
                {getStaffProfileDetailObj.status === "succeeded"
                  ? `RM ${getStaffProfileDetailObj.data.creditBalance}`
                  : "RM 0.00"}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            container
            xs={6}
            columnSpacing={2}
            rowSpacing={2}
            justifyContent="end"
            alignSelf="center"
            display="flex"
          >
            {((isMerchantStaff() && getStaffProfileDetailObj.status === "succeeded") ||
              (!isMerchantStaff() && getProfileDetailObj.status === "succeeded")) && (
              <Grid item justifyContent="end" alignSelf="center" display="flex">
                <Form
                  initialValues={{
                    amount: 1,
                    userPhoneNumber: null,
                  }}
                  validationSchema={Yup.object().shape({
                    amount: Yup.number()
                      .required()
                      .min(1, "Minimum transfer amount is RM 1")
                      .max(
                        isMerchantStaff()
                          ? getStaffProfileDetailObj.data.creditBalance
                          : getProfileDetailObj.data.creditBalance,
                        "Maximum transfer amount cannot be more than your credit balance",
                      )
                      .label("Amount"),
                    userPhoneNumber: Yup.object().nullable().required().label("User Phone Number"),
                  })}
                  onSubmit={handleTransfer}
                >
                  <TransferModal
                    showModal={showTransferModal}
                    setShowModal={setShowTransferModal}
                  />
                </Form>
                <CtaButton
                  startIcon={<InputIcon color="white" />}
                  onClickButton={() => setShowTransferModal(true)}
                  width="120px"
                >
                  Transfer
                </CtaButton>
              </Grid>
            )}

            <Grid item justifyContent="end" alignSelf="center" display="flex">
              <Form
                initialValues={{
                  amount: 50,
                }}
                validationSchema={Yup.object().shape({
                  amount: Yup.number()
                    .required()
                    .min(50, "Minimum top-up amount is RM 50")
                    .label("Amount"),
                })}
                onSubmit={handleTopUp}
              >
                <TopUpModal showModal={showModal} setShowModal={setShowModal} />
              </Form>
              <CtaButton
                startIcon={<AddCircleOutlinedIcon color="white" />}
                onClickButton={() => setShowModal(true)}
                width="120px"
              >
                Top-up
              </CtaButton>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </Grid>
  );
}

MerchantCredits.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
};
