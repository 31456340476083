import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api-services";

export const getHourlyTimeslotList = createAsyncThunk(
  "merchants/hourly_timeslot/list",
  async (payload) => {
    const { page } = payload;

    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/hourly_time_slots?${
        page ? "page=".concat(page) : ""
      }`,
    );
    return response;
  },
);

export const showHourlyTimeslot = createAsyncThunk(
  "merchants/hourly_timeslot/{timeslotId}",
  async (payload) => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/hourly_time_slots/${payload.timeslotId}`,
    );
    return response;
  },
);

export const getTakenHourlyTimeslotList = createAsyncThunk(
  "merchants/hourly_time_slots/taken_start_time",
  async () => {
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/hourly_time_slots/taken_start_time`,
    );
    return response;
  },
);

export const createHourlyTimeslot = createAsyncThunk(
  "merchants/hourly_timeslot/create",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/hourly_time_slots`,
      payload,
    );
    return response;
  },
);

export const setMembershipPriceForAllHourlyTimeslot = createAsyncThunk(
  "merchants/hourly_timeslot/set_membership_price",
  async (payload) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/hourly_time_slots/set_membership_price`,
      payload,
    );
    return response;
  },
);

export const updateHourlyTimeslot = createAsyncThunk(
  "merchants/hourly_timeslot/update",
  async (payload) => {
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/hourly_time_slots/${payload.timeslotId}`,
      payload,
    );
    return response;
  },
);

export const deleteHourlyTimeslot = createAsyncThunk(
  "merchants/hourly_timeslot/delete/{hourly_timeslot_id}",
  async (payload) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/hourly_time_slots/${payload.timeslotId}`,
    );
    return response;
  },
);

const hourlyTimeslotSlice = createSlice({
  name: "hourlyTimeslot",
  initialState: {
    getHourlyTimeslotListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    showHourlyTimeslotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createHourlyTimeslotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    setMembershipPriceForAllHourlyTimeslotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    updateHourlyTimeslotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    getTakenHourlyTimeslotListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    deleteHourlyTimeslotObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getHourlyTimeslotList.pending]: (state) => {
      state.getHourlyTimeslotListObj.status = "pending";
    },
    [getHourlyTimeslotList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getHourlyTimeslotListObj.status = "succeeded";
      state.getHourlyTimeslotListObj.data = data;
      state.getHourlyTimeslotListObj.successMessage = message;
    },
    [getHourlyTimeslotList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getHourlyTimeslotListObj.status = "failed";
      state.getHourlyTimeslotListObj.errorMessage = message;
    },
    [showHourlyTimeslot.pending]: (state) => {
      state.showHourlyTimeslotObj.status = "pending";
    },
    [showHourlyTimeslot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.showHourlyTimeslotObj.status = "succeeded";
      state.showHourlyTimeslotObj.data = data;
      state.showHourlyTimeslotObj.successMessage = message;
    },
    [showHourlyTimeslot.rejected]: (state, action) => {
      const { message } = action.error;

      state.showHourlyTimeslotObj.status = "failed";
      state.showHourlyTimeslotObj.errorMessage = message;
    },
    [createHourlyTimeslot.pending]: (state) => {
      state.createHourlyTimeslotObj.status = "pending";
    },
    [createHourlyTimeslot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createHourlyTimeslotObj.status = "succeeded";
      state.createHourlyTimeslotObj.data = data;
      state.createHourlyTimeslotObj.successMessage = message;
    },
    [createHourlyTimeslot.rejected]: (state, action) => {
      const { message } = action.error;

      state.createHourlyTimeslotObj.status = "failed";
      state.createHourlyTimeslotObj.errorMessage = message;
    },
    [setMembershipPriceForAllHourlyTimeslot.pending]: (state) => {
      state.setMembershipPriceForAllHourlyTimeslotObj.status = "pending";
    },
    [setMembershipPriceForAllHourlyTimeslot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.setMembershipPriceForAllHourlyTimeslotObj.status = "succeeded";
      state.setMembershipPriceForAllHourlyTimeslotObj.data = data;
      state.setMembershipPriceForAllHourlyTimeslotObj.successMessage = message;
    },
    [setMembershipPriceForAllHourlyTimeslot.rejected]: (state, action) => {
      const { message } = action.error;

      state.setMembershipPriceForAllHourlyTimeslotObj.status = "failed";
      state.setMembershipPriceForAllHourlyTimeslotObj.errorMessage = message;
    },
    [updateHourlyTimeslot.pending]: (state) => {
      state.updateHourlyTimeslotObj.status = "pending";
    },
    [updateHourlyTimeslot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.updateHourlyTimeslotObj.status = "succeeded";
      state.updateHourlyTimeslotObj.data = data;
      state.updateHourlyTimeslotObj.successMessage = message;
    },
    [updateHourlyTimeslot.rejected]: (state, action) => {
      const { message } = action.error;

      state.updateHourlyTimeslotObj.status = "failed";
      state.updateHourlyTimeslotObj.errorMessage = message;
    },
    [getTakenHourlyTimeslotList.pending]: (state) => {
      state.getTakenHourlyTimeslotListObj.status = "pending";
    },
    [getTakenHourlyTimeslotList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getTakenHourlyTimeslotListObj.status = "succeeded";
      state.getTakenHourlyTimeslotListObj.data = data;
      state.getTakenHourlyTimeslotListObj.successMessage = message;
    },
    [getTakenHourlyTimeslotList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getTakenHourlyTimeslotListObj.status = "failed";
      state.getTakenHourlyTimeslotListObj.errorMessage = message;
    },
    [deleteHourlyTimeslot.pending]: (state) => {
      state.deleteHourlyTimeslotObj.status = "pending";
    },
    [deleteHourlyTimeslot.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.deleteHourlyTimeslotObj.status = "succeeded";
      state.deleteHourlyTimeslotObj.data = data;
      state.deleteHourlyTimeslotObj.successMessage = message;
    },
    [deleteHourlyTimeslot.rejected]: (state, action) => {
      const { message } = action.error;

      state.deleteHourlyTimeslotObj.status = "failed";
      state.deleteHourlyTimeslotObj.errorMessage = message;
    },
  },
});

export default hourlyTimeslotSlice.reducer;

// state
export const hourlyTimeslotSelector = (state) => state.hourlyTimeslot;
